import React from "react";
import { NavLink } from "react-router-dom";
import HotmRepoInfo from "./HotmRepoInfo";
import HotmCards from "./HotmCards";
import Therapistinfo from "./Therapistinfo";
import Searchrepo from "./Searchrepo";
import Modalrepo from "./Modalrepo";
import { Helmet } from "react-helmet-async";
const HotmRepository = () => {
  return (
    <>
      <Helmet>
        <title>
          HOTM REPOSITORY-The best mental health professionals | Huesofthemind{" "}
        </title>
        <meta
          name="description"
          content="Contact best mental health professionals at Huesofthemind. With our expertise and your efforts we can together help you help yourself"
        />
        <link
          rel="canonical"
          href="https://huesofthemind.live/helpyourself/hotmrepository"
        />
        <meta
          name="keywords"
          content="counsellers,therpists, anger issues, depression, anxiety,mental health professionals, specialized therapy, trauma, psychology, mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown"
        />
      </Helmet>
      <div className="repo_div">
        <div className="row">
          <div className="col-11 col-sm-11 col-md-10 col-lg-10 mx-auto text-center">
            <h1 className="repo_title">HOTM REPOSITORY</h1>
            <p className="repo_txt">
              Thank you for reaching out to us and taking a step toward
              improving your mental health. Contact the therapist you wish to
              proceed with and refer HuesoftheMind to avail a discount. All the
              Mental Health Professionals here are available for virtual therapy
              and are queer affirmative. The work experience and charges have
              been updated till 2021. We hope we can help you help yourself.
            </p>
            <p className="repo_txt1">
              Note: If you are a therapist and want to be listed here, kindly
              drop a mail. We will contact you.
            </p>
            <Modalrepo />
            <Searchrepo details={Therapistinfo} />

            <div
              className="col-10 col-sm-8 col-md-8 col-lg-6 mx-auto btn-group"
              role="group"
            >
              <NavLink
                to="/helpyourself/hotmrepository"
                activeClassName="menu_active"
                className="btn active btn-hotmrepo"
              >
                Counselling
              </NavLink>
              <NavLink
                to="/helpyourself/hotmrepository/specialized"
                activeClassName="menu_active"
                className="btn btn-hotmrepo"
              >
                Specialized Therapy and more
              </NavLink>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-10 col-sm-9 col-md-10 mx-auto">
              <div className="row gy-5">
                {HotmRepoInfo.map((val, ind) => {
                  return (
                    <HotmCards
                      key={ind}
                      hotmcardimgsrc={val.hotmcardimgsrc}
                      dname={val.dname}
                      age={val.age}
                      location={val.location}
                      qualification={val.qualification}
                      experience={val.experience}
                      expertise={val.expertise}
                      charges={val.charges}
                      contact={val.contact}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HotmRepository;
