import React from "react";

const AboutPara = () => {
  return (
    <>
      <div className="p-2">
        <div className="row text-center">
          <div className="col-11 col-sm-10 col-md-10 col-lg-10 mx-auto">
            <p className="abt_para2">
              Huesofthemind is a non-profit organization run by progressive
              youth, where we try to have more conversations related to mental
              health to spread awareness and reduce the stigma around it.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutPara;
