import aarohi from "../../images/aarohi.png";
import anupama from "../../images/anupama.png";
import depika from "../../images/depika.png";
import kritika from "../../images/kritika.png";
import noor from "../../images/noor.png";
import juvena from "../../images/juvena.png";
import maruee from "../../images/maruee.png";
import niharika from "../../images/niharika.png";
import yati from "../../images/yati.png";
import sahitya from "../../images/sahitya.png";
import sahiti from "../../images/sahiti.png";
import ila from "../../images/ila.png";
import lalitha from "../../images/lalitha.png";
import sakshi from "../../images/sakshi.png";
import suhasni from "../../images/suhasni.png";
import priyam from "../../images/priyam.png";
import saral from "../../images/saral.png";
const Specializedinfo = [
  {
    hotmcardimgsrc: aarohi,
    dname: "Aarohi Doshi",
    location: "Pune, Maharashtra, India",
    qualification: "M.A Clinical Psychology, Post Graduate Diploma in Counseling Psychology, Certifications in Dance Movement therapy, Visual Art Therapy, Reiki",
    experience: "5 years",
    expertise: "Counseling through the medium of Art and Movement- Relationships, (Couple, Family, Work), Adolescents with Special Needs, Individuals suffering with Anxiety",
    charges: "INR 1,000 for one personal session for an hour. Groups of 6 - INR 3,000 for one workshop per hour",
    contact: "info@aarohidoshi.com, Website- www.aarohidoshi.com, Phone: 9823519725 (WhatsApp only for now) (15min free consultation)"
  },
  {
    hotmcardimgsrc: anupama,
    dname: "Anupama Das",
    location: "Kolkata, West Bengal, India",
    qualification: "BA Honours in Psychology, MA in Psychology, MBA in HR, Graphologist",
    experience: "1 year (approximately)",
    expertise: "Relationship counselling, Industrial Counselling, Teenage therapy, Grapho-therapy, Grapho-Analysis",
    charges: "INR 600",
    contact: "+91 8697367143; anupama143das@gmail.com"
  },
  {
    hotmcardimgsrc: depika,
    dname: "Deepika Oberoi",
    location: "Mumbai, Maharashtra, India",
    qualification: "Msc Organic Chemistry; MA Counseling Psychology; SEN Diploma",
    experience: "1 year (approximately)",
    expertise: "REBT Practitioner",
    charges: "INR 500",
    contact: "oberoi.deepika@gmail.com"
  },

  {
    hotmcardimgsrc: kritika,
    dname: "Dr Krithika Umananth",
    location: "Chennai, Tamil Nadu, India",
    qualification: "Bachelors in Homeopathic Medicine and Surgery (BHMS - Qualified as a Medical Doctor), Certified Emotional Intelligence Coach, Certified Cognitive Behavioural Coach, Certified Storytelling Coach, Certified Social Emotional Skills Consultant",
    experience: "1 year",
    expertise: "Coaching and Mentoring using Emotional Intelligence, Positive Psychology, Expressive Arts, CBT",
    charges: "INR 500 per hour",
    contact: "krithikaumananth@gmail.com; instagram.com/krithika_umananth; https://www.facebook.com/KrithikaUmananth"
  },

  {
    hotmcardimgsrc: noor,
    dname: "Dr. Noor Gill",
    location: "Jalandhar, Punjab, India",
    qualification: "MBBS, Diploma in Psychotherapy, I also sometimes write (no qualification, just creative)",
    experience: "1 year",
    expertise: "Doctor (Allopathy); Psychotherapy; Writing/Journaling",
    charges: "For a therapy session - INR 200 per 45 mins; For a session to guide you on writing as a form of expression/ journaling - INR 150 per 30 mins",
    contact: "noorgill169@gmail.com"
  },
  {
    hotmcardimgsrc: juvena,
    dname: "Juvena Ajwani",
    location: "Pune, Maharashtra, India",
    qualification: "BA is Psychology, PG Diploma in Dance/Movement Therapy",
    experience: "9 months",
    expertise: "Body-based approach on underlying issues of Self-Perception, Body Image, and Awareness through an Art-and-Movement-based Lens",
    charges: "INR 200 to 300 per person in a group setting",
    contact: "+91 9604266826; juvena.mentalhealth@gmail.com"
  },

  {
    hotmcardimgsrc: maruee,
    dname: "Mauree Pahuja",
    location: "Pune, Maharashtra, India",
    qualification: "Expressive Arts Practitioner, Masters of Optometry",
    experience: "Less than 1 year",
    expertise: "Expressive Arts",
    charges: "INR 1,500",
    contact: "9890540404"
  },

  {
    hotmcardimgsrc: niharika,
    dname: "Neeharika Jaiswal",
    location: "New Delhi, India",
    qualification: "MBBS, MD Psychiatry",
    experience: "4 years",
    expertise: "All Psychiatric Disorders",
    charges: "INR 1,000",
    contact: "sincerely.neha@gmail.com; instagram.com/_brainandmind_"
  },

  {
    hotmcardimgsrc: yati,
    dname: "Yati Parikh",
    location: " Mumbai, Maharashtra, India",
    qualification: "Post Grad in Behavioral Science and Internationally certified Intermodal Expressive Arts Practitioner",
    experience: "More than 6 years",
    expertise: "Healing through the use of Expressive Arts",
    charges: "INR 1,500 per 1 hour session",
    contact: "instagram.com/spaceforexpressivearts; contact.ekasva@gmail.com"
  },

  {
    hotmcardimgsrc: sahitya,
    dname: "Sahitya Shrivastava",
    location: "Online",
    qualification: "Bachelors Degree in Music, Psychology and English Literature (from Christ University, Bangalore); PG Diploma Certificate in Expressive Arts Therapy (qualifies as an Expressive Arts Therapy Practitioner - from St Xavier's, Bombay)",
    experience: "Internship under PG Diploma Certificate",
    expertise: "Expressive Arts Therapy",
    charges: "INR 500 per 45 minute session",
    contact: "+91 8296487673; shrivastava.sahitya8993@gmail.com"
  },
  {
    hotmcardimgsrc: sahiti,
    dname: "Sahiti Gavarikar",
    location: "Bangalore, Karnataka, India",
    qualification: "M.Sc in Counselling Psychology",
    experience: "2 years",
    expertise: "Cognitive Behaviour Therapy",
    charges: "INR 500 to 1,000",
    contact: "therestoryproject@protonmail.com"
  },

  {
    hotmcardimgsrc: ila,
    dname: "Ila Kulshrestha",
    location: "Delhi NCR, New Delhi, India",
    qualification:" M.Phil. Clinical Psychology",
    experience: "7 years",
    expertise: "Trauma informed psychotherapy, Expressive arts therapy, Queer affirmative Psychotherapy, Clinical conditions, couples therapy",
    charges: "INR 2,000",
    contact: "+91 9599886775; ila.kulshrestha@gmail.com"
  },
  {
    hotmcardimgsrc: lalitha,
    dname: "Lalitha Pooja",
    location: "Hyderabad, Andhra Pradesh, India",
    qualification: "M.Sc in Applied Psychology with Specialisation in Counselling ",
    experience: "2 years",
    expertise: "Certified in Queer Affirmative and Narrative therapy, and is a Mindfulness practitioner.",
    charges: "1000 (sliding scale available)",
    contact: "+91 9600134225; ishliving@gmail.com"
  },
  {
    hotmcardimgsrc: sakshi,
    dname: "Sakshi Jain",
    location: "Mumbai, Maharashtra, India",
    qualification: "Masters in Clinical Psychology, Certified RECBT therapist",
    experience: "3 years",
    expertise: "Therapy & Testing",
    charges: "INR 1,000",
    contact: "+91 9029069694"
  },
  {
    hotmcardimgsrc: suhasni,
    dname: "Suhasini Subramanian",
    location: "New Delhi, India",
    qualification: "MSc Psychology and UNESCO-CID Certified Expressive Arts Based Therapist",
    experience: "3 years",
    expertise: "Expressive arts therapy, stress management, multi-cultural/third-culture challenges, anxiety, depression, interpersonal relationship concerns, personal growth, body image",
    charges: "INR 3,000",
    contact: "suhasini@karmacare.in"
  },
  {
    hotmcardimgsrc: priyam,
    dname: "Priyam Kumar",
    location: "New Delhi, India",
    qualification: "Masters in Psychology, Post Graduate Diploma in Rehabilitation Psychology, UNESCO certified (Expressive Art Therapist), RCI-Certified",
    experience: "5 years",
    expertise: "Cognitive Behaviour Therapy and Expressive Art Therapy",
    charges: "INR 950",
    contact: "priyamkumar.mh@gmail.com"
  },
  {
    hotmcardimgsrc: saral,
    dname: "Sarla Totla",
    location: "Kolkata, India",
    qualification: "Advanced 3 Year Diploma in Counselling & Stress Management",
    experience: "6+ years",
    expertise: "Cognitive Behaviour Therapy based counselling- stress, depression, anxiety, personality disorder, relationship conflicts, parental guidance, addiction & substance abuse" ,
    charges: "INR 1200",
    contact: "+91 9836616000; sarlatotla@gmail.com"
  },
];
export default Specializedinfo;
