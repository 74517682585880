import React from "react";
import { NavLink } from "react-router-dom";
import breathing from "../../images/breathing.png"
const Breathing = () => {
  return (
    <>
      <div className="bm p-3">
        <h1 className="bm_title">Breathing Method</h1>
        <div className="row bm_row">
          <div className="col-12 col-lg-11 mx-auto d-flex flex-dir-bm">
            <div className="col-10 col-lg-5 col-md-5 col-sm-10 mx-auto text-center">
              <img src={breathing} className="bm_img img-fluid" alt="breathing methods" />
            </div>
            <div className="col-10 col-lg-5 col-md-5 col-sm-8 mx-auto bm_desc_div">
              <p className="bm_desc">
              Feeling stressed, restless, anxious or even unable to relax or sleep?⁣ Try this Breathing Method called 4-7-8, it’s a helpful exercise to relax.⁣ Try repeating it at least 4-6 times.
              </p>
              <NavLink to="/helpyourself/cope/breathingmethod" className="btn btn-bm-getstarted">
                Get Started
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Breathing;
