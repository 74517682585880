import saanchi from "../../../src/images/Saanchi.png";
import krithika from "../../../src/images/krithika.png";
import rishi from "../../../src/images/rishi.png";
import goutam from "../../../src/images/Goutam.png";
import rohit from "../../../src/images/rohit.png";
import vrinda from "../../../src/images/vrinda.png";
import kalika from "../../../src/images/kalika.png";
import raashi from "../../../src/images/raashi1.png";
import secret from "../../../src/images/secret.png";
import janani from "../../../src/images/janani1.png";
import noor from "../../../src/images/noor1.png";
import devanshi from "../../../src/images/devanshi.png";
import anushka from "../../../src/images/anushka.png";
import hrushita from "../../../src/images/hrushita.png";
import shubhi from "../../../src/images/shubhi.png";
const Sahaarainfo = [
  {
    simg: kalika,
    sname: "Kalika Aloni",
    ssub: "Director of Design",
    stext:
      "The time is Now - yet in this Now is a sense of timelessness. The place is Here - yet in this Here is a hint of everywhere",
    salink: "https://www.instagram.com/a.foxwithsocks/",
  },
  {
    simg: vrinda,
    sname: "Vrinda Gupta",
    ssub: "Design Coach",
    stext: "Embrace your flaws, imperfection is beautiful.",
    salink: "https://www.linkedin.com/in/vrindaguptaa/",
  },
  {
    simg: hrushita,
    sname: "Hrushitaa Murali",
    ssub: "Vice Executive Director",
    stext:
      " Give yourself the power to choose to be vulnerable only in front of those you like, not those who like you.",
    salink: "https://www.linkedin.com/in/hrushitaa/",
  },
  {
    simg: anushka,
    sname: "Anushka Gupta",
    ssub: "Director of Human Resources",
    stext:
      "Let everything happen to you. Beauty and terror. Just keep going. No feeling is final. -Rainer Maria Rilke",
    salink: "http://instagram.com/_anushkagupta__",
  },
  {
    simg: noor,
    sname: "Dr Noor Gill",
    ssub: "Advisor",
    stext: "Working on making caring cool again.",
    salink: "https://www.linkedin.com/in/noor-gill-757214191/",
  },
  {
    simg: janani,
    sname: "Janani Renganathan",
    ssub: "Advisor",
    stext: "Grow through what you go through.",
    salink: "https://www.linkedin.com/in/janani-renganathan-402519164/",
  },

  {
    simg: krithika,
    sname: "Dr. Krithika Ananth",
    ssub: "Advisor",
    stext:
      "I have always considered stories and poetry have phenomenal power to change the narrative and our perspectives of life, channeling us towards a space of healing, growth and development.",
    salink: "https://www.linkedin.com/in/krithikaumananth/",
  },
  {
    simg: raashi,
    sname: "Raashi Thakran",
    ssub: "Advisor",
    stext:
      "“If you can see your path laid out in front of you step by step, you know it’s not your path. Your own path you make with every step you take. That’s why it’s your path.” - Joseph Campbell",
    salink: "https://www.linkedin.com/in/raashi-thakran-348241168",
  },
  {
    simg: saanchi,
    sname: "Saanchi Saraff",
    ssub: "Advisor",
    stext:
      "The beautiful thing about learning is nobody can take it away from you. Even a small kid teaches you patience, be open to knowledge and you'll always grow.",
    salink: "https://www.linkedin.com/in/saanchi-saraff-3bba4797/",
  },
  {
    simg: devanshi,
    sname: "Devanshi Chaubey",
    ssub: "Psychology coach",
    stext: "Your gracious and daring heart doesn't go unappreciated.",
    salink: "https://www.linkedin.com/in/devanshi-chaubey-a1b9a71aa/",
  },
  {
    simg: goutam,
    sname: "Goutam Sree Govind",
    ssub: "Content Coach",
    stext:
      "Passion is that one thing that makes a person challenge everything.",
    salink: "https://www.linkedin.com/in/goutamsreegovind/",
  },
  {
    simg: rohit,
    sname: "Rohit Chakraborty",
    ssub: "Content Coach",
    stext: "Walk slowly, but never walk backwards.",
    salink: "https://www.linkedin.com/in/rohit-chakraborty-387694179/",
  },
  {
    simg: secret,
    sname: "The Xenial Secret",
    ssub: "Content Coach",
    stext: "Kindness, this is what is missing!",
    salink: "https://www.instagram.com/thexenialsecret/",
  },
  {
    simg: rishi,
    sname: "Rishi Raj Singh",
    ssub: "Operations Coach",
    stext: "Heal yourself before you help heal others.",
    salink: "https://www.linkedin.com/in/rishirajsinghchhabra/",
  },
  {
    simg: shubhi,
    sname: "Shubhi",
    ssub: "Technology Coach",
    stext: "Lead from the heart, not the head.",
    salink: "https://www.linkedin.com/in/shubhi-dwivedi-66984b189/",
  },
];
export default Sahaarainfo;
