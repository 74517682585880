import osh from "../../images/osh.png";
import sanjana1 from "../../images/sanjana1.png";
import gaythri1 from "../../images/gaythri1.png";
const Editorsinfo =[
    {   
        eimg: osh,
        ename: "Oishika Sah",
        etext: "There's so much to learn, love, and most importantly, to live.",
        elink: "https://www.linkedin.com/in/oishika-sah-827ba51ba/"
    },
    {
        eimg: gaythri1,
        ename: "Gayathri Vijay",
        etext: "You know you’re healing when one of two things happens— you don’t feel the urge to look back, or you look back at your past with fondness rather than regret.",
        elink: "https://www.linkedin.com/in/gayathri-vijay-3a6766a4/"
    },
    {
        eimg: sanjana1,
        ename: "Sanjana Srinivasan",
        etext: "I live by only one belief: Sometimes it is the people no one imagines anything of who do the things that no one can imagine. ",
        elink: "https://www.instagram.com/s_sanjana246/"
    }
]
export default Editorsinfo;