import React from "react";
const Faqs = () => {
  return (
    <>
      <div className="faqs_div">
        <div className="row">
          <div className="col-10 col-lg-8 mx-auto text-center">
            <h1 className="faqs_title">FAQS</h1>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item acc_items">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button accordion-toggle"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    What causes depression?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="answer_faq">
                      We all have days when we feel down, but those feelings
                      usually pass without having too much impact on our lives.
                      But if they last beyond a couple of weeks or you feel as
                      though things are getting worse, it could be a sign that
                      you’re experiencing depression. Symptoms of depression
                      vary from person to person, but often include feeling low,
                      numb, worthless or without hope. You may find you sleep
                      too much or too little, don’t eat properly, and withdraw
                      from social contact with friends and family. Some people
                      can also have thoughts about self-harming or suicide.
                      There are lots of reasons why someone might feel
                      depressed, but sometimes there isn’t any obvious reason.
                      It can be caused by a difficult life event – losing a job,
                      relationship difficulties, or physical health problems
                      like illness and disability, or low self-esteem.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item acc_items">
                <h2 className="accordion-header" id="headingEight">
                  <button
                    className="accordion-button collapsed accordion-toggle"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    How can I tell if someone has a mental health problem?
                  </button>
                </h2>
                <div
                  id="collapseEight"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEight"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="answer_faq">
                      Common mental health problems such as depression and
                      anxiety affect one in four people. But it’s still
                      something that lots of people find hard to talk about,
                      which can mean that many people keep their feelings about
                      having a mental health problem a secret, even from close
                      family and friends. You can’t always tell if someone has a
                      mental illness – people might look as though they’re fine
                      and doing well when they’re silently struggling. If
                      someone has bipolar disorder, they may show significant
                      mood swings, or appear more withdrawn if they are dealing
                      with self-harm or negative thoughts, but everything is
                      dependent on the individual and there is no one way to
                      know whether someone is unwell. The key is to keep an open
                      mind, be an active listener, and be empathetic to others.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item acc_items">
                <h2 className="accordion-header" id="headingNine">
                  <button
                    className="accordion-button collapsed accordion-toggle"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    How can I speak to and help someone with a mental health
                    problem like depression or anxiety?
                  </button>
                </h2>
                <div
                  id="collapseNine"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingNine"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="answer_faq">
                      Here are some suggestions for how you can help people
                      living with anxiety and depression:
                      <br />
                      ● Encourage them to seek professional help.
                      <br />
                      ● Don’t be afraid to bring it up, putting it out there
                      might help.
                      <br />
                      ● Don’t blame them for the way they are feeling.
                      <br />
                      ● Be patient. It can be very challenging to live with
                      mental health issues.
                      <br />
                      ● Look after yourself. Your mental health is important
                      too!
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item acc_items">
                <h2 className="accordion-header" id="headingTen">
                  <button
                    className="accordion-button collapsed accordion-toggle"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTen"
                    aria-expanded="false"
                    aria-controls="collapseTen"
                  >
                    What do I do if it is an emergency?
                  </button>
                </h2>
                <div
                  id="collapseTen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTen"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="answer_faq">
                      Suicides are not inevitable; they can be prevented with
                      the right support in place. Services must be able to
                      respond when people reach out; from early treatment to
                      help people manage their mental health problems as soon as
                      possible, to crisis care services that can step in when
                      someone becomes acutely unwell. If you’re feeling suicidal
                      or are concerned about someone else, confide in someone
                      you know, contact your GP. Suicide helplines in India -
                      https://indianhelpline.com/ 108 is a toll-free emergency
                      ambulance number in India. Every city or state has this
                      number reserved for ambulance services only to make
                      medical emergency care more accessible for everyone in
                      India regardless of the income &amp; location of the
                      patient.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item acc_items">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed accordion-toggle"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Do children have mental health problems?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="answer_faq">
                      Even very young children may show early warning signs of
                      mental health concerns. These mental health problems are
                      often clinically diagnosable and can be a product of the
                      interaction of biological, psychological, and social
                      factors. Half of all mental health disorders show first
                      signs before a person turns 14 years old, and
                      three-quarters of mental health disorders begin before age
                      24. Unfortunately, less than 20% of children and
                      adolescents with diagnosable mental health problems
                      receive the treatment they need. Early mental health
                      support can help a child before problems interfere with
                      other developmental needs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item acc_items">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed accordion-toggle"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    What is a personality disorder?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="answer_faq">
                      If you have been diagnosed with a personality disorder it
                      doesn’t mean that you’re fundamentally different from
                      anyone else, but at times you might need extra support.
                      The word ‘personality’ refers to the pattern of thoughts,
                      feelings and behaviour that makes each of us the
                      individuals that we are. These affect the way we think,
                      feel and behave towards others and ourselves. We don’t
                      always think, feel and behave the same way – it depends on
                      the situation we are in, the people with us and many other
                      things. But we mostly tend to behave in fairly predictable
                      ways. Personality disorders are a type of mental health
                      problem where your attitudes, beliefs and behaviours cause
                      you longstanding problems in your life. Your experience of
                      personality disorder is unique to you. However, you may
                      often experience difficulties in how you think about
                      yourself and others. You may find it difficult to change
                      these unwanted patterns.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item acc_items">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed accordion-toggle"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    What is post-traumatic stress disorder (PTSD)?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="answer_faq">
                      The term PTSD is used to describe a range of psychological
                      symptoms, which can follow traumatic events. PTSD can be
                      triggered by anything that consciously, or unconsciously,
                      reminds an individual of a specific trauma in their lives.
                      For some people, this is a single, major, significant
                      event – such as a car accident – and for others an ongoing
                      series of events, such as being in conflict zones, or
                      experiencing abuse. Symptoms of PTSD don’t necessarily
                      emerge straight away, and in some, don’t develop until
                      many years after the event. They can include vivid
                      flashbacks, nightmares, lack of sleep and feeling
                      emotionally cut-off. PTSD is surprisingly common – as many
                      as 10% of the population will experience it at some time
                      in their lives. If someone has been living with
                      distressing symptoms for over a month after a traumatic
                      event they should see their GP, who can refer them for
                      specialist help. Effective treatment does exist, and you
                      can recover from PTSD.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item acc_items">
                <h2 className="accordion-header" id="headingSix">
                  <button
                    className="accordion-button collapsed accordion-toggle"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Are drugs or therapy better for mental health conditions?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="answer_faq">
                      Different people will find that different treatments help
                      to manage their mental health – whether this is
                      medication, or alternatives such as talking therapies,
                      exercise or a mixture. While antidepressants can be
                      effective for some, they are not the solution for everyone
                      and are not generally recommended as a first-line
                      treatment for mild to moderate depression. Anyone taking
                      antidepressants should be made aware of the possible pros
                      and cons for them, and they should have their treatment
                      reviewed regularly. Talking therapies such as cognitive
                      behavioural therapy (CBT) and counselling are becoming
                      more widely available as part of the Improving Access to
                      Psychological Therapies programme. Giving people a choice
                      of treatments is key, whether that’s drugs, talking
                      therapies, or alternatives such as art therapy or
                      exercise.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item acc_items">
                <h2 className="accordion-header" id="headingSeven">
                  <button
                    className="accordion-button collapsed accordion-toggle"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    What is the best way to improve my mental wellbeing?
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="answer_faq">
                      We all have mental health to look after, as well as
                      physical health, and the way we live our lives has a
                      direct influence on them both. There are lots of small
                      things we can change to improve or maintain good mental
                      health and wellbeing without spending lots of money to do
                      it.
                      <br />
                      ● Balanced diet ● Adequate sleep ● Being outdoors ●
                      Exercise
                      <br />
                      Being sociable and connecting with other people is
                      rewarding in its own right and can significantly improve
                      mental wellbeing. Group yoga or Pilates classes can help
                      you learn breathing techniques, which can encourage you to
                      relax and switch off from external stresses.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item acc_items">
                <h2 className="accordion-header" id="headingEleven">
                  <button
                    className="accordion-button collapsed accordion-toggle"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEleven"
                    aria-expanded="false"
                    aria-controls="collapseEleven"
                  >
                    I feel quite low sometimes. Should I see a therapist?
                  </button>
                </h2>
                <div
                  id="collapseEleven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEleven"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="answer_faq">
                      One of the most important things to do if you’re feeling
                      down is to open up. Most people feel more comfortable
                      confiding in a close friend or family member in the first
                      instance. Often simply talking about it helps. If the
                      problem continues and begins to interfere with everyday
                      life, you can speak to your GP, who can talk you through
                      the available support.
                    </p>
                  </div>
                </div>
              </div>
              <br /> <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Faqs;
