import React from "react";
const Sharestory = () => {
  return (
    <>
      <div className="sharestory_div">
        <div className="col-lg-6 col-md-8 col-10 mx-auto text-center">
        <h1 className="sharestory_title">Share your story</h1>
          <p className="sharestory_desc">
          We’d love to share your story, journey, experiences of your own journey of mental wellness. 
          </p>
          <a href="https://forms.gle/mprZW7LCA8BvGkmr8" target="_blank" rel="noopener noreferrer" className="btn btn-share text-center">
            Share your Story
          </a>
        </div>
      </div>
    </>
  );
};
export default Sharestory;
