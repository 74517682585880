import React from "react";
import { NavLink } from "react-router-dom";
import learnwithhotm from "../../images/learnwithhotm.png";
import lgbtq from "../../images/lgbtq.png";
import ocd from "../../images/ocd.png";
import anxiety from "../../images/anxiety.png";
import adhd from "../../images/adhd.png";
import { Helmet } from "react-helmet-async";
const Learn = () => {
  return (
    <>
      <Helmet>
        <title>Learn With HOTM | Huesofthemind </title>
        <meta name="description" content="We have the power to break the stigma. Learn about commonly used terms and their complicated histories with mental health. Some things are not plain to see!" />
        <link rel="canonical" href="https://huesofthemind.live/helpyourself/learnwithhotm" />
        <meta name="keywords" content="learn with hotm, ocd, lgbtq, anxiety, adhd, mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown" />
      </Helmet>
      <div className="learn-div">
        <div className="row">
          <div className="col-12 col-sm-8 col-md-6 col-lg-6 text-center mx-auto">
            <img
              src={learnwithhotm}
              className="learn_img img-fluid"
              alt="..."
            />
          </div>
        </div>
        <div className="row">
          <div className="learn_rows col-12 col-lg-11 mx-auto d-flex flex-dir-learn1">
            <div className="col-10 col-lg-5 col-md-5 col-sm-10 mx-auto text-center">
              <img src={lgbtq} className="image_learn" alt="..." />
            </div>
            <div className="col-10 col-lg-5 col-md-5 col-sm-10 mx-auto text-center" style={{ marginTop: "auto", marginBottom: "auto" }}>
              <p className="learn_title">LGBTQ+</p>
              <p className="learn_subtitle">
                lesbian, gay, bisexual, transgender and queer
              </p>
              <NavLink to="/helpyourself/learnwithhotm/lgbtq" className="learn_knowmore">
                Know More
              </NavLink>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="learn_rows col-12 col-lg-11 mx-auto d-flex flex-dir-learn2">
            <div className="col-10 col-lg-5 col-md-5 col-sm-10 mx-auto text-center" style={{ marginTop: "auto", marginBottom: "auto" }}>
              <p className="learn_title">Anxiety</p>
              <p className="learn_subtitle">Response To Stress</p>
              <NavLink to="/helpyourself/learnwithhotm/anxiety" className="learn_knowmore">
                Know More
              </NavLink>
            </div>
            <div className="col-10 col-lg-5 col-md-5 col-sm-10 mx-auto text-center" style={{ marginTop: "auto", marginBottom: "auto" }}>
              <img src={anxiety} className="image_learn" alt="..." />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="learn_rows col-12 col-lg-11 mx-auto d-flex flex-dir-learn1">
            <div className="col-10 col-lg-5 col-md-5 col-sm-10 mx-auto text-center" style={{ marginTop: "auto", marginBottom: "auto" }}>
              <img src={ocd} className="image_learn" alt="..." />
            </div>
            <div className="col-10 col-lg-5 col-md-5 col-sm-10 mx-auto text-center" style={{ marginTop: "auto", marginBottom: "auto" }}>
              <p className="learn_title">OCD</p>
              <p className="learn_subtitle">Obsessive - compulsive disorder</p>
              <NavLink to="/helpyourself/learnwithhotm/ocd" className="learn_knowmore">
                Know More
              </NavLink>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="learn_rows col-12 col-lg-11 mx-auto d-flex flex-dir-learn2">
            <div className="col-10 col-lg-5 col-md-5 col-sm-10 mx-auto text-center" style={{ marginTop: "auto", marginBottom: "auto" }}>
              <p className="learn_title">ADHD</p>
              <p className="learn_subtitle">
                Attention deficit hyperactivity disorder
              </p>
              <NavLink to="/helpyourself/learnwithhotm/adhd" className="learn_knowmore">
                Know More
              </NavLink>
            </div>
            <div className="col-10 col-lg-5 col-md-5 col-sm-10 mx-auto text-center">
              <img src={adhd} className="image_learn" alt="..." />
            </div>
          </div>
        </div>
        <div className="reference">
          <a href="https://linktr.ee/huesofthemind" target="_blank" rel="noopener noreferrer" className="referenceslink">View References</a>
        </div>
        <div className="breakingstig_div_outer">
          <div className="breakingstig_div">
            <div className="breakingstig_card_div">
              <div className="card breakingstig_card">
                <div className="card-body">
                  <h5 className="card-title breakingstig_title_card">BREAKING STIGMA</h5>
                  <hr />
                  <p className="card-text breakingstig_card_desc">
                    Although the Indian masses are well aware, like never before of the fact that mental illnesses exist. But it is worth noting that most of us only limit our knowledge to mental illnesses like anxiety and depression. While the matter of fact is that there are many types and levels of mental illnesses that still persist and bother 10% of the Indian masses which is 13.392 crore Indians to be exact.

                  </p>
                  <NavLink to="/helpyourself/learnwithhotm/breakingstigma" className="btn btn-breakingstig">
                    Get Started
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Learn;
