import React from "react";
import Huesradiocards from "./Huesradiocards";
import huesradio from "../../images/huesradio.png";
import waves from "../../images/waves.png";
import Huesradioinfo from "./Huesradioinfo";
import Huesradiobts from "./Huesradiobts";
import Feedbackradio from "./Feedbackradio";
import { Helmet } from "react-helmet-async";
const Huesradiopage = () => {
  return (
    <>
      <Helmet>
        <title>HuesRadio: Spreading Mental Health Awareness Through Podcast | Huesofthemind </title>
        <meta name="description" content="An initiative where we explore the spectrum of mental health to spread awareness, creating a space to have more conversations through the medium of podcasts. With each episode, we dive into stand alone tangents of mental health that are ultimately connected to the well being of a person." />
        <link rel="canonical" href="https://huesofthemind.live/huesradio" />
        <meta name="keywords" content="huesradio, mental health podcast, radio, new episode, mental health talk, Anxiety, Overwhelming Emotions,mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown" />
      </Helmet>
      <div className="huesradio_outer">
        <div className="row huesradio_div">
          <div className="col-12 col-lg-12 mx-auto d-flex flex-dir-bottom">
            <div
              className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto "
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <h1 className="huesradio_title">Huesradio</h1>
              <br />
              <p className="huesradio_desc">
                Mental Health may seem like it has become the talk of the town but
                it is essential to know the inner workings of the subject. From
                the basics of mental health to nearing the serious intensity when
                one goes talking about the subject, we take you on a journey with
                an open and accepting mind. With each episode, we dive into stand
                alone tangents of mental health that are ultimately connected to
                the well being of a person.
              </p>
              <div className="huesradio_btns">
                <div>
                  <a href="https://anchor.fm/huesofthemind" target="_blank" rel="noopener noreferrer" className="btn btn-subscriberadio ">
                    Listen Now
                  </a>
                </div>
                <div>
                  <a href="https://www.instagram.com/huesofthemind/guide/with-huesradio/17923891123753091/?utm_source=ig_web_copy_link&utm_campaign=&utm_medium=" target="_blank" rel="noopener noreferrer" className="btn btn-resources ">
                    Resources
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto text-center"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <h1 className="huesradio_title1">HUESRADIO</h1>
              <img
                src={huesradio}
                className="huesradio_img img-fluid"
                alt="..."
              />
            </div>
          </div>
        </div>
        <div>
          <h1 className="episode_title">EPISODES</h1>
          <p className="episode_para">
            Lets explore the spectra of Mental Health
          </p>
          <img src={waves} className="waves img-fluid" alt="..." />
        </div>
        <div className="eventslist_div">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 ">
                <nav>
                  <div
                    className="nav nav-tabs nav-fill"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      className="nav-item nav-link events_buttons active"
                      id="nav-events-tab"
                      data-toggle="tab"
                      href="#nav-events"
                      role="tab"
                      aria-controls="nav-events"
                      aria-selected="true"
                    >
                      Season 1
                    </a>
                    <a
                      className="nav-item nav-link events_buttons disabled"
                      id="nav-workshops-tab"
                      data-toggle="tab"
                      href="#nav-workshops"
                      role="tab"
                      aria-controls="nav-workshops"
                      aria-selected="false"
                    >
                      Season 2
                    </a>
                  </div>
                </nav>
                <div
                  className="tab-content py-3 px-3 px-sm-0"
                  id="nav-tabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="nav-events"
                    role="tabpanel"
                    aria-labelledby="nav-events-tab"
                  >
                    <div className="row gy-4">
                      {Huesradioinfo.map((val, ind) => {
                        return (
                          <Huesradiocards
                            key={ind}
                            radiocardimgsrc={val.radiocardimgsrc}
                            radiocardtitle={val.radiocardtitle}
                            radiocardsubtitle={val.radiocardsubtitle}
                            radiocarddesc={val.radiocarddesc}
                            radiolinks={val.radiolinks}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-workshops"
                    role="tabpanel"
                    aria-labelledby="nav-workshops-tab"
                  >
                    {/* <div className="row gy-4">
                    {Huesradioinfo.map((val, ind) => {
                      return (
                        <Huesradiocards
                          key={ind}
                          eventcardimgsrc={val.eventcardimgsrc}
                          eventcardtitle={val.eventcardtitle}
                          eventcardsubtitle={val.eventcardsubtitle}
                          eventcarddesc={val.eventcarddesc}
                          eventlinks={val.eventlinks}
                        />
                      );
                    })}
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Huesradiobts />
        <Feedbackradio />
      </div>
    </>
  );

};

export default Huesradiopage;
