import React from "react";
import Eventsinfo from "./Eventsinfo";
import EventsCards from "./EventsCards";
import WorkshopsEventsinfo from "./WorkshopsEventsinfo";
import Campaignsinfo from "./Campaignsinfo";
import Moreinfo from "./Moreinfo";
const EventsList = () => {
  return (
    <>
      <div className="eventslist_div">
        <div className="row">
          <div className="col-10 col-lg-10 mx-auto">
            <h1 className="eventslist_heading">EVENTS</h1>
            <h6 className="eventslist_subheading">
              Follow <a href="https://www.instagram.com/explore/tags/hotmevents/" className="hotmeve">#hotmevents</a> on Instagram to stay updated!
            </h6>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 ">
              <nav>
                <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                  <a
                    className="nav-item nav-link events_buttons active"
                    id="nav-events-tab"
                    data-toggle="tab"
                    href="#nav-events"
                    role="tab"
                    aria-controls="nav-events"
                    aria-selected="true"
                  >
                    Recents
                  </a>
                  <a
                    className="nav-item nav-link events_buttons"
                    id="nav-workshops-tab"
                    data-toggle="tab"
                    href="#nav-workshops"
                    role="tab"
                    aria-controls="nav-workshops"
                    aria-selected="false"
                  >
                    Workshops
                  </a>
                  <a
                    className="nav-item nav-link events_buttons"
                    id="nav-campaigns-tab"
                    data-toggle="tab"
                    href="#nav-campaigns"
                    role="tab"
                    aria-controls="nav-campaigns"
                    aria-selected="false"
                  >
                    Campaigns
                  </a>
                  <a
                    className="nav-item nav-link events_buttons"
                    id="nav-more-tab"
                    data-toggle="tab"
                    href="#nav-more"
                    role="tab"
                    aria-controls="nav-more"
                    aria-selected="false"
                  >
                    More
                  </a>
                </div>
              </nav>
              <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-events"
                  role="tabpanel"
                  aria-labelledby="nav-events-tab"
                >
                  <div className="row gy-4">
                    {Eventsinfo.map((val, ind) => {
                      return (
                        <EventsCards
                          key={ind}
                          eventcardimgsrc={val.eventcardimgsrc}
                          eventcardtitle={val.eventcardtitle}
                          eventcardsubtitle={val.eventcardsubtitle}
                          eventcarddesc={val.eventcarddesc}
                          eventlinks={val.eventlinks}
                        />
                      );
                    })}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-workshops"
                  role="tabpanel"
                  aria-labelledby="nav-workshops-tab"
                >
                  <div className="row gy-4">
                    {WorkshopsEventsinfo.map((val, ind) => {
                      return (
                        <EventsCards
                          key={ind}
                          eventcardimgsrc={val.eventcardimgsrc}
                          eventcardtitle={val.eventcardtitle}
                          eventcardsubtitle={val.eventcardsubtitle}
                          eventcarddesc={val.eventcarddesc}
                          eventlinks={val.eventlinks}
                        />
                      );
                    })}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-campaigns"
                  role="tabpanel"
                  aria-labelledby="nav-campaigns-tab"
                >
                  <div className="row gy-4">
                    {Campaignsinfo.map((val, ind) => {
                      return (
                        <EventsCards
                          key={ind}
                          eventcardimgsrc={val.eventcardimgsrc}
                          eventcardtitle={val.eventcardtitle}
                          eventcardsubtitle={val.eventcardsubtitle}
                          eventcarddesc={val.eventcarddesc}
                          eventlinks={val.eventlinks}
                        />
                      );
                    })}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-more"
                  role="tabpanel"
                  aria-labelledby="nav-more-tab"
                >
                  <div className="row gy-4">
                    {Moreinfo.map((val, ind) => {
                      return (
                        <EventsCards
                          key={ind}
                          eventcardimgsrc={val.eventcardimgsrc}
                          eventcardtitle={val.eventcardtitle}
                          eventcardsubtitle={val.eventcardsubtitle}
                          eventcarddesc={val.eventcarddesc}
                          eventlinks={val.eventlinks}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EventsList;
