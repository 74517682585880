import pratibha from "../../../src/images/Pratibha.png";
import nakul from "../../../src/images/Nakul.jpeg";
import raashi from "../../../src/images/Raashi.png";
import gill from "../../../src/images/gill.png";
import renuka from "../../../src/images/Renuka.jpeg";
import rita from "../../../src/images/rita.jpeg";
const Huesinfo = [
    {
        hueimg: pratibha,
        huetext: "I still remember the day Manasi reached out to me to talk about HOTM. I was so happy to know a group of students working on something so much bigger than themselves. For the course of time that we’ve worked on different projects for, I’ve always felt a very authentic connection to the community and I’m just so glad that something like this exists and you people have been selflessly working towards it.",
        huename: "Pratibha Motwani",
        huedesc: "In pursuit of creative living"
    },
    {
        hueimg: nakul,
        huetext: "It has been incredible and truly a wholesome experience working with & interacting with HOTM. I’ve had the honour of being part of the journey as a spectator, participant and a speaker as well and I can say this with utmost confidence that big things are in store for HOTM with the kind of quality they’ve managed to maintain throughout the course of every single event or interaction. I can see the impact they are creating thanks to every single topic they cover, the speakers they bring in and the content they put across their channel. I hope they only grow bigger with time.",
        huename: "Nakul Jain",
        huedesc: "Aspiring entrepreneur | Social media manager | Leadership development trainer |"
    },
    {
        hueimg: raashi,
        huetext: "I had the honour of being a part of two of their events and it was an amazing experience. The community is absolutely heartwarming and the members make you feel like you’re part of the family. So glad to know them and to have worked with them. To many more collaborations.",
        huename: "Raashi Thakran",
        huedesc: "Mental Health Advocate | Marketing Executive, YourDost"
    },
    {
        hueimg: gill,
        huetext: "I have seen you guys grow and bloom into the wonderful hues of happiness, joy and success right from the start. And what a journey it has been, right from the start. Such a dedicated bunch with such big dreams to take everyone along with them on this joyful ride of theirs. This is one support group you know will support you, no matter what you’re going through.",
        huename: "Noor Gill",
        huedesc: "Working on making caring cool again"
    },
    {
        hueimg: renuka,
        huetext: "Prevention is better than cure! Huesofthemind is committed to making a difference through their well designed and age appropriate modules on mental health. Kudos to the dedicated team who work with diligence and sincerity to raise awareness on mental health issues....the need of the hour. God bless you in your endeavours!",
        huename: "Renuka Fernandes",
        huedesc: "Counsellor & HOD Psychology & Counselling Dept, DPS Gurgaon"
    },
    {
        hueimg: rita,
        huetext: " Pleased to be attached with a caring and passionate group who empower themselves to bring an awareness of mental health well being among all- the need of the hour in our present society",
        huename: "Dr . Rita Bhattacharjee",
        huedesc: "Head of Counselling Divison, VIT Vellore"
    }
]
export default Huesinfo;