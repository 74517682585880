import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../Home/huesradioarrow.css";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import "./css/Reco.css";
import reco1 from "../../images/reco1.png";
import reco2 from "../../images/reco2.png";
import reco3 from "../../../src/images/reco3.png";
import reco4 from "../../../src/images/reco4.png";

import dailyreco from "../../images/dailyreco.png";
import smilyreco from "../../images/smilyreco.png";
import updeedreco from "../../../src/images/updeedreco.png";
import ieeereco from "../../../src/images/ieeereco.png";
import youngworldreco from "../../../src/images/youngworldreco.png";
import ydc from "../../../src/images/ydc.png";
import glc from "../../../src/images/glc.png";
import usaid from "../../../src/images/usaid.png";
import gc from "../../../src/images/grandcanada.png";
import moderndip from "../../../src/images/moderndip.png";
import linkedin from "../../../src/images/linkedin.png";
class Recognitions extends Component {
  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 992 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 992, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    const arrowStyle = {
      background: "transparent",
      border: 0,
      color: "white",
      opacity: 0.5,
      fontSize: "80px",
    };
    const CustomRight = ({ onClick }) => (
      <button className="arrow right" onClick={onClick} style={arrowStyle}>
        <KeyboardArrowRightIcon style={{ fontSize: "50px" }} />
      </button>
    );
    const CustomLeft = ({ onClick }) => (
      <button className="arrow left" onClick={onClick} style={arrowStyle}>
        <KeyboardArrowLeftIcon style={{ fontSize: "50px" }} />
      </button>
    );
    return (
      <>
        <div className="reco_bg">
          <div className="container">
            <h1 className="reco_title">RECOGNITIONS</h1>
          </div>
          <div className="">
            <div
              id="main-slide"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner container">
                <Carousel
                  responsive={responsive}
                  additionalTransfrom={0}
                  arrows
                  autoPlaySpeed={3000}
                  centerMode={false}
                  className=""
                  dotListClass=""
                  draggable
                  focusOnSelect={false}
                  renderDotsOutside
                  infinite
                  itemClass=""
                  keyBoardControl
                  showDots={false}
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable
                  customRightArrow={<CustomRight />}
                  customLeftArrow={<CustomLeft />}
                >
                  <div className="container d-flex align-items-stretch">
                    <div className="card reco_card">
                      <img className="img-fluid" alt="..." src={reco1} />
                      <div className="card-body">
                        <p className="card-text reco_text">
                          Our efforts were recognised and applauded by the{" "}
                          <a
                            className="reco_links"
                            href="https://www.linkedin.com/feed/update/urn:li:activity:6779249380978384896/"
                          >
                            Times of India
                          </a>{" "}
                          and the
                          <a
                            className="reco_links"
                            href="https://www.linkedin.com/posts/huesofthemind_we-got-featured-in-times-of-india-navbharat-activity-6779310408881713152-oxMm/"
                          >
                            {" "}
                            Navbharat Times
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="container d-flex align-items-stretch">
                    <div className="card reco_card">
                      <img className="img-fluid" alt="..." src={reco2} />
                      <div className="card-body">
                        <p className="card-text reco_text">
                          We were featured and interviewed in the{" "}
                          <a
                            className="reco_links"
                            href="https://www.instagram.com/p/CQqcVGjLK3z/?utm_source=ig_web_copy_link"
                          >
                            iOWN
                          </a>{" "}
                          magazine under the Social Service Sector.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="container d-flex align-items-stretch">
                    <div className="card reco_card">
                      <img className="img-fluid" alt="..." src={reco3} />
                      <div className="card-body">
                        <p className="card-text reco_text">
                          We have been featured in multiple social media
                          platforms, some of which include &nbsp;
                          <a
                            className="reco_links"
                            href="https://www.instagram.com/p/CHuMFRLJcfR/"
                          >
                            @officialpeopleofindia
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="container d-flex align-items-stretch">
                    <div className="card reco_card">
                      <img className="img-fluid" alt="..." src={reco4} />
                      <div className="card-body">
                        <p className="card-text reco_text">
                          We were recognised by{" "}
                          <a
                            className="reco_links"
                            href="https://www.instagram.com/p/CMgX6bnnvPq/?utm_source=ig_web_copy_link"
                          >
                            VIT Vellore
                          </a>{" "}
                          and{" "}
                          <a
                            className="reco_links"
                            href="https://www.instagram.com/p/CGIJ3yHHaXp/"
                          >
                            AIESEC India
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="container d-flex align-items-stretch">
                    <div className="card reco_card">
                      <img className="img-fluid" alt="..." src={dailyreco} />
                      <div className="card-body">
                        <p className="card-text reco_text">
                          Our team's work was featured by online news
                          publications{" "}
                          <a
                            className="reco_links"
                            href="https://m.dailyhunt.in/news/india/english/online+waale+media-epaper-dh6ae7922f205548bc994645e1b7a6d8d9/huesofthemind+where+we+help+you+help+yourself-newsid-dh6ae7922f205548bc994645e1b7a6d8d9_083f7e40932311ecbe7d005d6ad9dc1b"
                          >
                            Daily Hunt
                          </a>{" "}
                          ,{" "}
                          <a
                            className="reco_links"
                            href="https://foxinterviewer.com/lifestyle/social-media/huesofthemind-where-we-help-you-help-yourself/"
                          >
                            Fox Interviewer
                          </a>{" "}
                          and{" "}
                          <a
                            className="reco_links"
                            href="https://www.entrepenuerstories.com/latest/huesofthemind-where-we-help-you-help-yourself/"
                          >
                            Entrepreneur Stories
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="container d-flex align-items-stretch">
                    <div className="card reco_card">
                      <img className="img-fluid" alt="..." src={smilyreco} />
                      <div className="card-body">
                        <p className="card-text reco_text">
                          <a
                            className="reco_links"
                            href="https://smileymovement.org/news-list/Hues-of-the-mind"
                          >
                            Smiley News
                          </a>{" "}
                          , a global platform to encourage positive news,
                          interviewed us and shared our journey so far.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="container d-flex align-items-stretch">
                    <div className="card reco_card">
                      <img className="img-fluid" alt="..." src={updeedreco} />
                      <div className="card-body">
                        <p className="card-text reco_text">
                          <a
                            className="reco_links"
                            href="https://app.updeed.co/post/1817"
                          >
                            Updeed
                          </a>{" "}
                          , an app to celebrate the positive efforts and
                          changemakers of the community, interviewed us and
                          featured our work on their platform.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="container d-flex align-items-stretch">
                    <div className="card reco_card">
                      <img className="img-fluid" alt="..." src={ieeereco} />
                      <div className="card-body">
                        <p className="card-text reco_text">
                          IEEE's monthly newsletter recognised and appreciated
                          our work.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="container d-flex align-items-stretch">
                    <div className="card reco_card">
                      <img
                        className="img-fluid"
                        alt="..."
                        src={youngworldreco}
                      />
                      <div className="card-body">
                        <p className="card-text reco_text">
                          One Young World Summit, the Global Annual Summit to
                          convene the brightest young talent to accelerate
                          Social Impact, invited our Founder on a full
                          scholarship to share our work.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* 1 */}
                  <div className="container d-flex align-items-stretch">
                    <div className="card reco_card">
                      <img className="img-fluid" alt="..." src={ydc} />
                      <div className="card-body">
                        <p className="card-text reco_text">
                          One of the two Champions awarded globally for Digital
                          initiatives in social impact.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* 2 */}
                  <div className="container d-flex align-items-stretch">
                    <div className="card reco_card">
                      <img className="img-fluid" alt="..." src={glc} />
                      <div className="card-body">
                        <p className="card-text reco_text">
                          One of the 100 leaders chosen globally by Oxford
                          University and St Gallen Univeris from 2000+
                          applications in 50 countries to advance action for
                          SDGs.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* 3 */}
                  <div className="container d-flex align-items-stretch">
                    <div className="card reco_card">
                      <img className="img-fluid" alt="..." src={usaid} />
                      <div className="card-body">
                        <p className="card-text reco_text">
                          Chosen as a member of the 12 member Digital Youth
                          Council by USAID, amongst 2600 applicants to prevent
                          digital harm.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* 4 */}
                  <div className="container d-flex align-items-stretch">
                    <div className="card reco_card">
                      <img className="img-fluid" alt="..." src={gc} />
                      <div className="card-body">
                        <p className="card-text reco_text">
                          Chosen as one of the 8 members of the People with
                          lived experienced Council from 100+ applications
                          globally.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* 5 */}
                  <div className="container d-flex align-items-stretch">
                    <div className="card reco_card">
                      <img className="img-fluid" alt="..." src={moderndip} />
                      <div className="card-body">
                        <p className="card-text reco_text">
                          Interviewed by Modern Diplomacy for our work in mental
                          well-being and advocacy.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* 6 */}
                  <div className="container d-flex align-items-stretch">
                    <div className="card reco_card">
                      <img className="img-fluid" alt="..." src={linkedin} />
                      <div className="card-body">
                        <p className="card-text reco_text">
                          Featured by Linkedin for Non-profits for our content
                          on non-profit management.
                        </p>
                      </div>
                    </div>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Recognitions;
