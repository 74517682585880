const HowHueslettersinfo = [
    {
        desc: "Hi Team Sahara, Thanks for this amazing newsletter and for bringing out great content. I genuinely appreciate how incredible you all are and your work! The amount of effort you guys put in each work deserves every bit of appreciation.Your level of quality work remains unprecedented and continues to exceed every expectation that we all have. Great job. I hope you all continue to make a difference in the lives of more and more people, always.",
        hname: "Niranjana.PR"
    },
    {
        desc: "Everything is so beautifully explained !! I love how everything made so much sense and honestly there was a positivity I could feel.Probably the colours used but simply loved it!",
        hname: "Saanchi Saraff"
    },
    {
        desc: "The newsletter looks so beautiful. It was recently that I took up to practice intuitive art and mandalas too! 🙌 Thank you So much for sending the mail 🥰Have a nice day! ✨",
        hname: "Artika Nagpal"
    },
    {
        desc: "Just read the complete letter, you went beyond my expectations! I have read a lot of newsletters, some are boring. But you have made this soo interesting with all the images and of course the content, great one. Keep going!🤟",
        hname: "AVS Sridhar"
    }
]
export default HowHueslettersinfo;