import React from "react";
import { NavLink } from "react-router-dom";
const Collaboratewithus = () => {
  return (
    <>
      <div className="collab_us">
        <div className="col-lg-8 col-md-10 col-10 mx-auto text-center">
        <h1 className="collabus_title">START COLLABORATING WITH US</h1>
          <p className="collabus_para">
          If your organisation is committed to eliminating the stigma surrounding mental illness through awareness and education, we would be more than happy to collaborate with your organisation.
          </p>
          <NavLink to="/collaborate" className="btn btn-collaborate text-center">
            Collaborate
          </NavLink>
        </div>
      </div>
    </>
  );
};
export default Collaboratewithus;
