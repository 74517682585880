import ImageGallery from 'react-image-gallery';
import React from 'react'
import "react-image-gallery/styles/css/image-gallery.css";
import beleaf1 from '../../images/beleaf1.jpeg';
import beleaf2 from '../../images/beleaf2.jpeg';
import beleaf3 from '../../images/beleaf3.jpeg';
import beleaf4 from '../../images/beleaf4.jpeg';

const images = [
  {
    original: beleaf1,
    thumbnail: beleaf1,
  },
  {
    original: beleaf2,
    thumbnail: beleaf2,
  },
  {
    original: beleaf3,
    thumbnail: beleaf3,
  },
  {
    original: beleaf4,
    thumbnail: beleaf4,
  },
];
const Beleaf = () => {
  return (
    <div>
      <ImageGallery showPlayButton={false} items={images} showNav={false} autoPlay={true}/>
    </div>
  )
}

export default Beleaf;