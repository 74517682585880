import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import maher from "../../../src/images/Maher.png";
import toi from "../../../src/images/toi.png";
import dps from "../../../src/images/dpslogo.png";
import uvit from "../../../src/images/uvit.png";
import vit from "../../../src/images/vitlogo.png";
import lonepack from "../../../src/images/Lonepack.png";
import ecosoc from "../../../src/images/ecosoc.jpg";
import aiesec from "../../../src/images/aiesec.png";
import india from "../../../src/images/india.png";
import iwh from "../../../src/images/iwh.png";
import stletter from "../../../src/images/stletter.PNG";
import girlup from "../../../src/images/girlup.png";
import dailyhunt from "../../../src/images/dailyhunt.png";
import one from "../../../src/images/one.png";
import ieee from "../../../src/images/ieee.png";
import sdsn from "../../../src/images/sdsn.png";
import unite from "../../../src/images/unite.png";
import swasti from "../../../src/images/swasti.png";
import zurich from "../../../src/images/zurich.png";

import niftlogo from "../../../src/images/niftlogo.png";
const Collaborators = () => {
  return (
    <>
      <div className="collab_div">
        <p className="heading_collab">OUR COLLABORATORS</p>
        <div className="container-fluid col-12 col-lg-12 text-center">
          <div className="container collaborators">
            <Carousel
              infiniteLoop
              autoPlay={true}
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
            >
              <div className="row">
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid" src={toi} alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid collogo" src={uvit} alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid" src={india} alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid collogo" src={dps} alt="..." />
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid" src={ecosoc} alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid collogo" src={vit} alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid collogo" src={lonepack} alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid" src={iwh} alt="..." />
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid" src={aiesec} alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid collogo" src={maher} alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid" src={stletter} alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid collogo" src={girlup} alt="..." />
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid zurich" src={sdsn} alt="sdsn" />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid zurich" src={ieee} alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img
                    className="img-fluid collogo"
                    src={dailyhunt}
                    alt="..."
                  />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid zurich" src={one} alt="..." />
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid zurich" src={zurich} alt="sdsn" />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid" src={swasti} alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid collogo" src={unite} alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img src={niftlogo} className="logodw" alt="..." />
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};
export default Collaborators;
