import React, { useState } from "react";
import { SocialIcon } from "react-social-icons";
import dinky1 from "../../../src/images/dinky1.png";
import divyac from "../../../src/images/divyac.png";
import gaythri from "../../../src/images/gaythri1.png";
import Authorcards from "./Authorcards";
import Authorinfo from "./Authorinfo";
const AuthorReadmore = () => {
  const [show, setShow] = useState(false);
  const linkName = show ? "" : "View More";
  return (
    <>
      <div className="authorviewmore">
              <div className="col-sm-8 col-md-6 mb-3 d-flex align-items-stretch mx-auto">
                <div className="card card_author">
                  <img className="img-fluid" alt="..." src={dinky1} />
                  <div className="card-body d-flex flex-column">
                    <h4 className="card-title card_author_title">
                      Dinky Tejwani
                    </h4>
                    <hr />
                    <p className="card-text card_atext">
                      I always try to remind myself that even though flowers and
                      fairy lights are nothing alike, they are still uniquely
                      beautiful.
                    </p>
                    <div className="mt-auto">
                      <SocialIcon
                        url="https://www.instagram.com/inkyydinky/"
                        className="social_media"
                        style={{
                          height: 35,
                          width: 35,
                          marginRight: 8,
                        }}
                        bgColor="#12356b"
                        fgColor="white"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-8 col-md-6 mb-3 d-flex align-items-stretch mx-auto">
                <div className="card card_author">
                  <img className="img-fluid" alt="..." src={divyac} />
                  <div className="card-body d-flex flex-column">
                    <h4 className="card-title card_author_title">
                      Divya Chaturvedi
                    </h4>
                    <hr />
                    <p className="card-text card_atext">
                      "The future belongs to those who believe in the beauty of
                      their dreams." -Eleanor Roosevelt
                    </p>
                    <div className="mt-auto">
                      <SocialIcon
                        url="https://www.linkedin.com/in/divya-chaturvedi-841634203/"
                        className="social_media"
                        style={{
                          height: 35,
                          width: 35,
                          marginRight: 8,
                        }}
                        bgColor="#12356b"
                        fgColor="white"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-8 col-md-6 mb-3 d-flex align-items-stretch mx-auto">
                <div className="card card_author">
                  <img className="img-fluid" alt="..." src={gaythri} />
                  <div className="card-body d-flex flex-column">
                    <h4 className="card-title card_author_title">
                      Gayathri Vijay
                    </h4>
                    <hr />
                    <p className="card-text card_atext">
                    You know you’re healing when one of two things happens— you don’t feel the urge to look back, or you look back at your past with fondness rather than regret.
                    </p>
                    <div className="mt-auto">
                      <SocialIcon
                        url="https://www.linkedin.com/in/gayathri-vijay-3a6766a4/"
                        className="social_media"
                        style={{
                          height: 35,
                          width: 35,
                          marginRight: 8,
                        }}
                        bgColor="#12356b"
                        fgColor="white"
                      />
                    </div>
                  </div>
                </div>
              </div>
         
        <div className="AuthorReadmore">
          {show ? (
                  <div className="gy-4">
                    {Authorinfo.map((val, ind) => {
                      return (
                        <Authorcards
                          key={ind}
                          simg={val.simg}
                          sname={val.sname}
                          ssub={val.ssub}
                          stext={val.stext}
                          slink={val.slink}
                        />
                      );
                    })}
                  </div>
            
          ) : null}
          <button className="authorlinkmore" onClick={() => setShow(!show)}>
            {linkName}
          </button>
        </div>
      </div>
    </>
  );
};
export default AuthorReadmore;
