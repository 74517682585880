import ImageGallery from 'react-image-gallery';
import React from 'react'
import "react-image-gallery/styles/css/image-gallery.css";
import crochet1 from '../../images/crochet1.jpeg';
import crochet2 from '../../images/crochet2.jpeg';
import crochet3 from '../../images/crochet3.jpeg';
import crochet4 from '../../images/crochet4.jpeg';
import crochet5 from '../../images/crochet5.jpeg';
import crochet6 from '../../images/crochet6.jpeg';
const images = [
  {
    original: crochet1,
    thumbnail: crochet1,
  },
  {
    original: crochet2,
    thumbnail: crochet2,
  },
  {
    original: crochet3,
    thumbnail: crochet3,
  },
  {
    original: crochet4,
    thumbnail: crochet4,
  },
  {
    original: crochet5,
    thumbnail: crochet5,
  },
  {
    original: crochet6,
    thumbnail: crochet6,
  },
];
const Talkingyarns = () => {
  return (
    <div>
      <ImageGallery showPlayButton={false} items={images} showNav={false} autoPlay={true} />
    </div>
  )
}

export default Talkingyarns;