import sanjana1 from "../../../src/images/sanjana1.png";
import nikitha from "../../../src/images/nikitha.png";
import osh from "../../../src/images/osh.png";
import pakhi from "../../../src/images/pakhi.png";
import manasi1 from "../../../src/images/manasi1.png";
import kalika1 from "../../../src/images/kalika1.png";
import hrushita from "../../../src/images/hrushita.png";
import rhea from "../../../src/images/rhea.png";
import lakshita from "../../../src/images/lakshita.png";
import shristihota from "../../../src/images/shristihota.png";
import shristi from "../../../src/images/shristi.png";

const Authorinfo = [
  {
    simg: hrushita,
    sname: "Hrushitaa Murali",
    stext: "Give yourself the power to choose to be vulnerable only in front of those you like, not those who like you.",
    slink: "https://www.linkedin.com/in/hrushitaa/"
  },
  {
    simg: kalika1,
    sname: "Kalika Aloni",
    stext: "“Life is more than just saying goodbye. Its the encounters that make up human life” -Tamako Market",
    slink: "https://www.instagram.com/a.foxwithsocks/"
  },
  {
    simg: lakshita,
    sname: "Lakshita Gupta",
    stext: "The sun will rise, and we will try again",
    slink: "https://www.linkedin.com/in/lakshita-gupta-1b5a701a0/"
  },
  {
    simg: manasi1,
    sname: "Manasi Gupta",
    stext: "Empathy, consistency and courage are the biggest instruments of change.",
    slink: "https://www.linkedin.com/in/manasichangemaker/"
  },
  {
    simg: nikitha,
    sname: "Nikhitha Yeturu",
    stext: "There's only one way I wanna live : To completely embrace who I am and fly with pride. Let myself be free, unapologetic and wild.",
    slink: "https://www.linkedin.com/in/nikhitha-yeturu-8689251aa/"
  },
  {
    simg: osh,
    sname: "Oishika Sah",
    stext: "There's so much to learn, love, and most importantly, to live.",
    slink: "https://www.linkedin.com/in/oishika-sah-827ba51ba/"
  },
  {
    simg: pakhi,
    sname: "Pakhi Dubey",
    stext: "Where your focus goes, energy flows.",
    slink: "https://www.instagram.com/dubeypakhi_11"
  },
  {
    simg: rhea,
    sname: "Rhea Mathews",
    stext: "In all chaos there is a cosmos, in all disorder a secret order.  - Carl Jung",
    slink: "https://www.linkedin.com/in/rhea-mathews-aa7698112/"
  },
  {
    simg: shristihota,
    sname: "Srishti Hota",
    stext: "I believe in Manifestation and the universe. I also believe in being self-aware of my emotions and beliefs, they help me tackle my mental health issues. ",
    slink: "https://www.linkedin.com/in/srishti-hota-461981199/"
  },
  {
    simg: shristi,
    sname: "Shristi Pandey",
    stext: "We’re all constellations but changing. We find better fits, and on rare occasions we face obstacles, but we’ll still align.",
    slink: "https://www.linkedin.com/in/shristi-pandey-0387a3193/"
  },
  {
    simg: sanjana1,
    sname: "Sanjana Srinivasan",
    stext: "Sometimes it is the people no one imagines anything of who do the things that no one can imagine.",
    slink: "https://www.instagram.com/s_sanjana246/"
  },
];
export default Authorinfo;
