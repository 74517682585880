import React, { useState } from "react";
import SearchedEventslist from "./SearchedEventslist";
const Head = ({ details }) => {
  const [searchField, setSearchField] = useState("");
  const [searchShow, setSearchShow] = useState(false);
  const filteredPersons = details.filter((person) => {
    return (
      person.eventcardtitle.toLowerCase().includes(searchField.toLowerCase()) ||
      person.eventcardsubtitle.toLowerCase().includes(searchField.toLowerCase())
    );
  });
  const handleChange = (e) => {
    setSearchField(e.target.value);
    if (e.target.value === "") {
      setSearchShow(false);
    } else {
      setSearchShow(true);
    }
  };
  function searchList() {
    if (searchShow) {
      return <SearchedEventslist filteredPersons={filteredPersons} />;
    }
  }
  return (
    <>
      <div className="head_outer">
        <div className="head_div">
          <div className="row">
            <div className="col-11 col-lg-10 mx-auto d-flex flex-dir-top">
              <div className="col-10 col-lg-6 col-md-8 col-sm-8 mx-auto text-center">
                <h1 className="events_heading text-center">HUES OF THE EVENTS</h1>
                <p className="events_para">
                  These workshops, camps, events & conferences are a celebration of our journey.
                </p>
                <div className="input-group pb-4">
                  <input
                    type="search"
                    className="form-control search_events"
                    placeholder="Search for events.."
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid pb-3">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row gy-4">{searchList()}</div>
            </div>
          </div>
        </div>
      </div>
      <br /><br />
    </>
  );
};
export default Head;
