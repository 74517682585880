import React from "react";
import { Helmet } from "react-helmet-async";
const BreakingStigma = () => {
  return (
    <>
      <Helmet>
        <title>Breaking Stigma | Huesofthemind </title>
        <meta name="description" content="Know more about the disorders like Schizophrenia, Bipolar disorder, Personality Disorders" />
        <link rel="canonical" href="https://huesofthemind.live/helpyourself/learnwithhotm/breakingstigma" />
        <meta name="keywords" content="Schizophrenia,  Bipolar disorder , Personality Disorders, mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown" />
      </Helmet>
      <div className="breaking_stigma_div">
        <div className="row">
          <div className="col-11 col-sm-11 col-md-10 col-lg-10 mx-auto">
            <h1 className="breaking_stigma_heading">Breaking Stigma</h1>
            <p className="breaking_stigma_sub">Know well about the things you don’t !</p>
            <p className="breaking_stigma_desc">
              1. Schizophrenia – It is a disorder that affects a person's ability to think, feel and behave clearly. The person suffers from hallucinations (both auditory and visual) which hinder his/her ability to function and make clear decisions.<br /><br />
              2. Bipolar disorder – The individual goes through extreme mood swings, ranging from depressive lows to manic highs.<br /><br />
              3. Personality Disorders - A mental disorder characterised by unstable moods, behaviour and relationships. Herein an individual becomes delusional enough to perceive himself/herself as another individual with a different personality. Each of these disorders stated above have about 1 crore cases annually in India.<br /><br />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default BreakingStigma;
