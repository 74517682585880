import React, { useState } from "react";
import SearchedRepo from "./SearchedRepo";

function Searchrepo({ details }) {
  const [searchField, setSearchField] = useState("");
  const [searchShow, setSearchShow] = useState(false);
  const filteredPersons = details.filter((person) => {
    return (
      person.dname.toLowerCase().includes(searchField.toLowerCase()) ||
      person.expertise.toLowerCase().includes(searchField.toLowerCase()) ||
      person.location.toLowerCase().includes(searchField.toLowerCase())
      
    );
  });

  const handleChange = (e) => {
    setSearchField(e.target.value);
    if(e.target.value===""){
      setSearchShow(false);
    }
    else {
      setSearchShow(true);
    }
  };

  function searchList() {
    if (searchShow) {
      return <SearchedRepo filteredPersons={filteredPersons} /> ;
    }
  }

  return (
    <section className="">
      <div className="">
        <h2 className="search_therapist">Search for your issue or a therapist by name or location</h2>
      </div>
      <div className="col-10 col-sm-8 col-md-8 col-lg-6 mx-auto">
        <input
          className="form-control searchbar_therapist"
          type="search"
          placeholder="Search..."
          onChange={handleChange}
        />
      </div>
      {searchList()}
    </section>
  );
}

export default Searchrepo;
