import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import HowHueslettersinfo from "./HowHueslettersinfo";
import HoeHuescards from "./HoeHuescards";
const HowHuesletters = () => {
  return (
    <>
      <div className="howhuelet">
        <div className="container">
          <h1 className="people_heading">how people find our huesletters</h1>
          <Carousel
            infiniteLoop
            autoPlay
            interval="18000"
            showThumbs={false}
            showStatus={false}
          >
            {HowHueslettersinfo.map((val, ind) => {
              return (
                <HoeHuescards key={ind} desc={val.desc} hname={val.hname} />
              );
            })}
          </Carousel>
        </div>
        <br />
        <br />
        <br />
      </div>
    </>
  );
};
export default HowHuesletters;
