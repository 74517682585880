import React from "react";
import { SocialIcon } from "react-social-icons";
const Authorcards = (props) => {
  return (
    <>
      <div className="col-sm-8 col-md-6 mb-3 d-flex align-items-stretch mx-auto">
        <div className="card card_author">
          <img className="img-fluid" alt="..." src={props.simg} />
          <div className="card-body d-flex flex-column">
            <h4 className="card-title card_author_title">{props.sname}</h4>
            <hr />
            <p className="card-text card_atext">
              {props.stext}
            </p>
            <div className="mt-auto">
              <SocialIcon
                url={props.slink}
                className="social_media"
                style={{
                  height: 35,
                  width: 35,
                  marginRight: 8,
                }}
                bgColor="#12356b"
                fgColor="white"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Authorcards;
