import React from "react";
const HotmCards = (props) => {
  return (
    <>
      <div className="card repo_card">
        <div className="row">
          <div
            className="col-md-5 text-center"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <img
              src={props.hotmcardimgsrc}
              className="repo_img img-fluid p-3"
              alt={props.hotmimgsrc}
            />
            <br />
          </div>
          <div
            className="col-md-7"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <div className="card-body">
              <h1 className="repo_names">
                {props.dname}
                <br />
              </h1>
    <p className="repo_desc"><span className="labels">Location: </span> {props.location}</p>
    <p className="repo_desc"> <span className="labels">Qualification: </span > {props.qualification}</p>
    <p className="repo_desc"> <span className="labels">Experience: </span>{props.experience}</p>
    <p className="repo_desc">  <span className="labels">Expertise: </span>{props.expertise}</p>
    <p className="repo_desc"> <span className="labels">Charges: </span> {props.charges}</p>
    <p className="repo_desc"> <span className="labels">Contact: </span> {props.contact}</p>          
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HotmCards;
