import React from "react";
import image6 from "../../../src/images/6.png";
import workshops from "../../../src/images/Workshops1.png";
import volunteer from "../../../src/images/Volunteer2.png";
import collaborate from "../../../src/images/Collaborate.png";
import sharing from "../../../src/images/Sharing.png";
import image7 from "../../../src/images/7.png";
import { NavLink } from "react-router-dom";
const Yourhelp = () => {
  return (
    <>
    <div className="yourbg">
      <div className="yourhelp_div p-3">
        <h1 className="yourhelp_title">How can you help?</h1>
        <div className="row">
          <div className="col-12 col-lg-12 mx-auto d-flex flex-dir-top">
            <div className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto text-center">
              <img src={image6} className="yourhelp1" alt="..." />
            </div>
            <div className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto titles">
              <div style={{ marginBottom: 50 }}>
                <img className="img-left1" src={workshops} width="40px"height="40px" alt="..." />
                  <h2 className="yourheadings1">Organize a Workshop</h2>
                    <p className="yourpara">
                    There is still a huge stigma around mental illness and we need to banish it. We can only do this through the use of structured experiences, group discussions and interactions.

                    </p>
                <NavLink to='/organizeworkshop' className="yourknow_more"> Know more </NavLink> 
              </div>

              <div style={{ marginBottom: 50 }}>
                <img className="img-left1" src={volunteer} width="40px" height="40px" alt="..." />
                 <h2 className="yourheadings1">Volunteer</h2>
                  <p className="yourpara">
                  You can be an advocate for people with mental illness by fulfilling a volunteer role, or simply when collaborating with our community. If not us, then who?

                </p>
                <a href="https://forms.gle/exqaYhnuc11NyJer5" target="_blank" rel="noopener noreferrer" className="yourknow_more"> Know more </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="yourhelp_div p-3">
        <div className="row">
          <div className="col-12 col-lg-12 mx-auto d-flex flex-dir-bottom">
            <div className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto titles">
              <div style={{ marginBottom: 50 }}>
                <img className="img-left" src={collaborate} width="40px" height="40px" alt="..." />
                <h2 className="yourheadings1">Collaborate</h2>
                <p className="yourpara1">
                If you are committed to eliminating the stigma surrounding mental illnesses and want to encourage mental wellbeing, we would be more than happy to collaborate with your organisation.
                </p>
                <NavLink to="/collaborate" className="yourknow_more1"> Know more </NavLink>
              </div>
              <div style={{ marginBottom: 50 }}>
                <img className="img-left" src={sharing} width="40px" height="40px" alt="..." />
                <h2 className="yourheadings1">Share your Story</h2>
                <p className="yourpara1">
                We’d love to share your story, journey, experiences of your own journey of mental wellness. 
                </p>
                <a href="https://forms.gle/mprZW7LCA8BvGkmr8" target="_blank" rel="noopener noreferrer" className="yourknow_more1"> Know more </a>
              </div>
            </div>
            <div className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto text-center img-size">
              <img src={image7} className="yourhelp2" alt="..."/>
            </div>
          </div>

          <div className="row donate">
            <div className="col-lg-8 col-md-10 col-10 mx-auto text-center">
            <h1 className="donate_title">DONATE</h1>
            <p className="done_desc">
            Since we’re a non-profit organization, we require your financial support to make a difference. We will use these resources to make mental health resources more available, affordable and accessible and also develop technologically.
            </p>
            <a href="https://rzp.io/l/HOTMDonate" target="_blank" rel="noopener noreferrer" className="btn btn-donate text-center" role="button">Donate Now</a>
            </div>
         </div>
       </div>
      </div>
      </div>
    </>
  );
};
export default Yourhelp;
