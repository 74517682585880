import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../Home/huesradioarrow.css";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { SocialIcon } from "react-social-icons";
import gaythri1 from "../../images/gaythri1.png";
import sanjana1 from "../../images/sanjana1.png";
import vrinda from "../../../src/images/vrinda.png";
import secret from "../../../src/images/secret.png";
import nikitha from "../../../src/images/nikitha.png";
import hrushita from "../../../src/images/hrushita.png";
import gurleen from "../../../src/images/gurleen.jpeg";
class Huesradiobts extends Component {
  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 992 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 992, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    const arrowStyle = {
      background: "transparent",
      border: 0,
      color: "#002242",
      opacity: 0.3,
      fontSize: "80px",
    };
    const CustomRight = ({ onClick }) => (
      <button className="arrow right" onClick={onClick} style={arrowStyle}>
        <KeyboardArrowRightIcon style={{ fontSize: "50px" }} />
      </button>
    );
    const CustomLeft = ({ onClick }) => (
      <button className="arrow left" onClick={onClick} style={arrowStyle}>
        <KeyboardArrowLeftIcon style={{ fontSize: "50px" }} />
      </button>
    );
    return (
      <>
        <div className="bts_bg">
          <div className="container">
            <h1 className="bts_title">Behind The Scenes</h1>
            <p className="bts_desc">
              Meet the hues who collectively worked on the podcast
            </p>
          </div>
          <div className="bts_cont">
            <div
              id="main-slide"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner container">
                <Carousel
                  autoPlay={true}
                  responsive={responsive}
                  additionalTransfrom={0}
                  arrows
                  autoPlaySpeed={3000}
                  centerMode={false}
                  className=""
                  dotListClass=""
                  draggable
                  focusOnSelect={false}
                  renderDotsOutside
                  infinite
                  itemClass=""
                  keyBoardControl
                  showDots={false}
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable
                  customRightArrow={<CustomRight />}
                  customLeftArrow={<CustomLeft />}
                >
                  {/* <div>
              
                {Huesradioinfo.map((val, ind) => {
                  return (
                    <Huesradiobts
                      key={ind}
                      simg={val.simg}
                      sname={val.sname}
                      ssub={val.ssub}
                      stext={val.stext}
                      salink={val.salink}
                    />
                  );
                })}
              
            </div> */}
                  <div className="container d-flex align-items-stretch">
                    <div className="card card_bts">
                      <img className="img-fluid" alt="..." src={gaythri1} />
                      <div className="card-body d-flex flex-column">
                        <h4 className="card-title hr_title">Gayathri Vijay</h4>
                        <h6 className="hr_sub">PROJECT MANAGER</h6>

                        <div className="mt-auto text-center">
                          <SocialIcon
                            url="https://www.linkedin.com/in/gayathri-vijay-3a6766a4/"
                            className="social_media"
                            style={{
                              height: 35,
                              width: 35,
                              marginRight: 8,
                            }}
                            bgColor="#12356b"
                            fgColor="white"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container d-flex align-items-stretch">
                    <div className="card card_bts">
                      <img className="img-fluid" alt="..." src={sanjana1} />
                      <div className="card-body d-flex flex-column">
                        <h4 className="card-title hr_title">
                          Sanjana Srinivasan
                        </h4>
                        <h6 className="hr_sub">PROJECT MANAGER</h6>

                        <div className="mt-auto text-center">
                          <SocialIcon
                            url="https://www.instagram.com/s_sanjana246/"
                            className="social_media"
                            style={{
                              height: 35,
                              width: 35,
                              marginRight: 8,
                            }}
                            bgColor="#12356b"
                            fgColor="white"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container">
                    <div className="card card_bts">
                      <img className="img-fluid" alt="..." src={vrinda} />
                      <div className="card-body d-flex flex-column">
                        <h4 className="card-title hr_title">Vrinda Gupta</h4>
                        <h6 className="hr_sub">DESIGNER</h6>

                        <div className="mt-auto text-center">
                          <SocialIcon
                            url="https://www.linkedin.com/in/vrindaguptaa/"
                            className="social_media"
                            style={{
                              height: 35,
                              width: 35,
                              marginRight: 8,
                            }}
                            bgColor="#12356b"
                            fgColor="white"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container">
                    <div className="card card_bts">
                      <img className="img-fluid" alt="..." src={secret} />
                      <div className="card-body d-flex flex-column">
                        <h4 className="card-title hr_title">
                          The Xenial Secret
                        </h4>
                        <h6 className="hr_sub">PODCAST HOST</h6>

                        <div className="mt-auto text-center">
                          <SocialIcon
                            url="https://www.instagram.com/thexenialsecret/"
                            className="social_media"
                            style={{
                              height: 35,
                              width: 35,
                              marginRight: 8,
                            }}
                            bgColor="#12356b"
                            fgColor="white"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container">
                    <div className="card card_bts">
                      <img className="img-fluid" alt="..." src={hrushita} />
                      <div className="card-body d-flex flex-column">
                        <h4 className="card-title hr_title">
                          Hrushitaa Murali
                        </h4>

                        <p className="card-text hr_desc">
                          Content Writer For Episode 3
                        </p>
                        <div className="mt-auto text-center">
                          <SocialIcon
                            url="https://www.linkedin.com/in/hrushitaa/"
                            className="social_media"
                            style={{
                              height: 35,
                              width: 35,
                              marginRight: 8,
                            }}
                            bgColor="#12356b"
                            fgColor="white"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container">
                    <div className="card card_bts">
                      <img className="img-fluid" alt="..." src={nikitha} />
                      <div className="card-body d-flex flex-column">
                        <h4 className="card-title hr_title">Nikhitha Yeturu</h4>
                        <h6 className="hr_sub">WRITER</h6>

                        <div className="mt-auto text-center">
                          <SocialIcon
                            url="https://www.linkedin.com/in/nikhitha-yeturu-8689251aa/"
                            className="social_media"
                            style={{
                              height: 35,
                              width: 35,
                              marginRight: 8,
                            }}
                            bgColor="#12356b"
                            fgColor="white"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="card card_bts">
                      <img className="img-fluid" alt="..." src={gurleen} />
                      <div className="card-body d-flex flex-column">
                        <h4 className="card-title hr_title">Gurleen Khokhar</h4>
                        <h6 className="hr_sub">WRITER</h6>

                        <div className="mt-auto text-center">
                          <SocialIcon
                            url="http://linkedin.com/in/gurleen-khokhar-111840216"
                            className="social_media"
                            style={{
                              height: 35,
                              width: 35,
                              marginRight: 8,
                            }}
                            bgColor="#12356b"
                            fgColor="white"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Huesradiobts;
