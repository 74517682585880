import React from "react";
const ReadHueslettersCard = (props) => {
  return (
    <>
      <div className="col-lg-4 col-md-6 col-12 mx-auto">
        <div className="card hl_cards h-100 text-center">
          <img src={props.hlimg} className="card-img-hl" alt={props.hlimg} />
          <div className="card-body">
            <h5 className="card-title-hl">{props.hltitle}</h5>
            <hr />
            <p className="card-text-hl">
              {props.hldesc}
            </p>
            <a href={props.hllink} target="_blank" rel="noopener noreferrer" className="hl_link">Read More</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReadHueslettersCard;
