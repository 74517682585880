import gayathri from "../../../src/images/gayathri.png";
import artika from "../../../src/images/Artika.jpeg";
import namit from "../../../src/images/Namit.jpg";
import oishika from "../../../src/images/Oishika.jpg";
import dinky from "../../../src/images/Dinky.png";
import  dayoung from "../../../src/images/dayoung.jpg";
const Huesofmembersinfo = [
    {
        huememimg: gayathri,
        huememtext: "Getting to impact people’s lives with writing is something that fills my soul with happiness, and a kind of satisfaction that nothing else gives me. I’m glad I get to do it with this amazing bunch of people who value mental health and all that it encompasses.",
        huememname: "Gayathri Vijay",
        huememdesc: "Research and content writer, HOTM "
    },
    {
        huememimg: artika,
        huememtext: " I am grateful for this wholesome community  I had an opportunity to be a part of. Interacting and learning from so many creative and talented people was an amazing experience. I congratulate Huesofthemind on their beautiful initiative and the positivity and love that they spread with their organisation! I am forever grateful! All the Best to Huesofthemind wishing them endless success.",
        huememname: "Artika Nagpal",
        huememdesc: "Member, HOTM | Founder, Artify with Artika"
    },
    {
        huememimg: namit,
        huememtext: "My journey with HuesoftheMind has been one of so much learning and growth. I have learnt a lot about not just personal but also professional growth. I am fortunate enough to work with a team of such kind, caring and understanding people that make you feel right at home. Team Sahaara is not just a team, it's like a family and I feel honoured to have been a part of it.",
        huememname: "Namit Pandey",
        huememdesc: "Marketing and PR Team Member, HOTM"
    },
    {
        huememimg: oishika,
        huememtext: "My journey with Huesofthemind has been so peaceful, empowering and soothing. I can express myself much more freely now. I've finally found a family to turn upto for sharing precious moments.",
        huememname: "Oishika Sah",
        huememdesc: "Research and content writer, HOTM"
    },
    {
        huememimg: dayoung,
        huememtext: "I'm grateful to be part of the team. It means a lot to work with a real team and see how an organisation works. Also it is very rewarding to make design works for Huesofthemind. The people in the team are so sweet and kind. I feel so welcomed and safe here.",
        huememname: "Dayoung Min",
        huememdesc: "I'm from South Korea and love talking about mental health"
    },
    {
        huememimg: dinky,
        huememtext: "At Huesofthemind, I found a place where I can grow and at every step I had people who believed in me more than myself.  I have loved being at Huesofthemind. Every idea I share and every task I complete builds my self-confidence. This has always been my place to learn and try new things and grow.",
        huememname: "Dinky",
        huememdesc: "Psychology student | Content Writer, HOTM"
    }
]
export default Huesofmembersinfo;