import React from "react";
import { Helmet } from "react-helmet-async";
const SelfCareinfo = () => {
  return (
    <>
      <Helmet>
        <title>What does self-care mean? How to tackle intrusive thoughts? | Huesofthemind</title>
        <meta name="description" content="Self-care means taking conscious efforts to reinvigorate ourselves emotionally, physically, spiritually or socially. Find out more about self care and intrusive thoughts." />
        <link rel="canonical" href="https://huesofthemind.live/helpyourself/cope/selfcare" />
        <meta name="keywords" content="mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown" />
      </Helmet>
      <div className="selfcareinfo_div">
        <h1 className="selfcareinfo_title text-center">SELF CARE</h1>
        <div className="row">
          <div className="col-12 col-md-10 col-lg-10 mx-auto">
            <div className="selfcareinfo_carddiv1">
              <div className="card selfcareinfo_card">
                <div className="card-body">
                  <h5 className="card-title selfcareinfo_card_title">
                    What does self care mean to me⁣?
                  </h5>
                  <p className="card-text selfcareinfo_card_desc">
                    Self care means taking conscious efforts to reinvigorate
                    ourselves emotionally, physically, spiritually or socially.
                    <br />
                    It can also mean being in a happy or feel good place. ⁣For
                    the short run, I like to indulge in skin care routines like
                    applying sheet masks, relaxing with a good book, movie or my
                    favourite music playlist.⁣ But, I try to do activities which
                    would make me feel good in the long run. That is how I can
                    care for my future self!
                    <br />
                    ⁣For example, learning new skills like cooking a different
                    cuisine, learning some technical skills, developing good
                    habits and finding a balance between productivity and
                    chilling. Discipline and routine are also important if you
                    want to achieve your goals and that is also a form of self
                    care for me!⁣ It means different things to different people,
                    so what does it mean to you?
                  </p>
                </div>
              </div>
            </div>
            <div className="selfcareinfo_carddiv2">
              <div className="card selfcareinfo_card">
                <div className="card-body">
                  <h5 className="card-title selfcareinfo_card_title">
                    What are intrusive thoughts?
                  </h5>
                  <p className="card-text selfcareinfo_card_desc">
                    They are unwanted thoughts which come out of nowhere and
                    cause significant stress or anxiety.
                    <br />
                    As we cannot control how they come or when they come, we can
                    only change the way we react to them! Here are some ways to
                    deal with intrusive thoughts,
                    <br />
                    1. Mindful meditation. Instead of fighting them try to
                    observe them, like clouds in a sky, leaves falling off a
                    tree.
                    <br />
                    2. If not, then fight it with a positive thought, or an
                    inner, stronger thought. Replace them with affirmations.
                    Practice self love & compassion.
                    <br />
                    3. Challenge the thought. Ask yourself, “Is this really
                    true?”
                    <br />
                    4. Principle of distraction! Watch something funny, play a
                    sport, talk to your friends. <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SelfCareinfo;
