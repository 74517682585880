import React, { useState } from "react";
//import pranavi1 from "../../../src/images/pranavi1.png";
import { SocialIcon } from "react-social-icons";
import Sahaaracards from "./Sahaaracards";
import Sahaarainfo from "./Sahaarainfo";
import manasi from "../../../src/images/manasi.png";
import shristi from "../../../src/images/shristi.png";
import ali from "../../../src/images/ali.png";
const Sahaarareadmore = () => {
  const [show, setShow] = useState(false);
  const linkName = show ? "" : "View More";
  return (
    <>
      <div className="sahaaraviewmore">
        <div className="mb-3 d-flex align-items-stretch">
          <div className="card card_sahaara">
            <img className="img-fluid" alt="..." src={manasi} />
            <div className="card-body d-flex flex-column">
              <h4 className="card-title card_sahaara_title">Manasi Gupta</h4>
              <h6 className="card_subtitle_sahaara">
                Founder & Executive Director
              </h6>
              <hr />
              <p className="card-text card_stext">
                Empathy, Consistency and courage are biggest instruments of
                change.
              </p>
              <div className="mt-auto">
                <SocialIcon
                  url="https://www.linkedin.com/in/manasichangemaker/"
                  className="social_media"
                  style={{
                    height: 35,
                    width: 35,
                    marginRight: 8,
                  }}
                  bgColor="#12356b"
                  fgColor="white"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3 d-flex align-items-stretch">
          <div className="card card_sahaara">
            <img className="img-fluid" alt="..." src={shristi} />
            <div className="card-body d-flex flex-column">
              <h4 className="card-title card_sahaara_title">Shristi Pandey</h4>
              <h6 className="card_subtitle_sahaara">Content Coach</h6>
              <hr />
              <p className="card-text card_stext">
                We’re all constellations but changing. We find better fits, and
                on rare occasions we face obstacles, but we’ll still align.
              </p>
              <div className="mt-auto">
                <SocialIcon
                  url="https://www.linkedin.com/in/shristi-pandey-0387a3193/"
                  className="social_media"
                  style={{
                    height: 35,
                    width: 35,
                    marginRight: 8,
                  }}
                  bgColor="#12356b"
                  fgColor="white"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3 d-flex align-items-stretch">
          <div className="card card_sahaara">
            <img className="img-fluid" alt="..." src={ali} />
            <div className="card-body d-flex flex-column">
              <h4 className="card-title card_sahaara_title">Ali Hasnain</h4>
              <h6 className="card_subtitle_sahaara">Director of Operations</h6>
              <hr />
              <p className="card-text card_stext">
                You miss 100 percent of the chances you don't take. -Wayne
                Gretzky
              </p>
              <div className="mt-auto">
                <SocialIcon
                  url="https://www.linkedin.com/in/ali-hasnain-52421a1a4/"
                  className="social_media"
                  style={{
                    height: 35,
                    width: 35,
                    marginRight: 8,
                  }}
                  bgColor="#12356b"
                  fgColor="white"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Sahaarareadmore">
          {show ? (
            <div>
              <div className="row gy-4">
                {Sahaarainfo.map((val, ind) => {
                  return (
                    <Sahaaracards
                      key={ind}
                      simg={val.simg}
                      sname={val.sname}
                      ssub={val.ssub}
                      stext={val.stext}
                      salink={val.salink}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}
          <button className="sahaaralinkmore" onClick={() => setShow(!show)}>
            {linkName}
          </button>
        </div>
      </div>
    </>
  );
};
export default Sahaarareadmore;
