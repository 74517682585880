import baatein from "../../../src/images/baatein.png";
import pastels from "../../../src/images/pastels.png";
import panel from "../../../src/images/paneldiscussion.png";
const Moreinfo = [
  {
    eventcardimgsrc: panel,
    eventcardtitle: "Panel Discussion",
    eventcardsubtitle: "21st May 2020",
    eventcarddesc: "Theme: A panel discussion signifying the importance of Mental Health Awareness and Topics related to mental health and well being Partnering with IEEE-CAS, HOTM conducted Panel Discussion on tackling issues like stereotypes present in our media, lack of information on mental health issues in education, and stigmas surrounding the major issues.",
    eventlinks: "https://www.instagram.com/p/CAZsUsbnGtc/?utm_source=ig_web_copy_link"
  },
  {
    eventcardimgsrc: pastels,
    eventcardtitle: "Pastels",
    eventcardsubtitle: "24th April 2020",
    eventcarddesc: "It was an online art competition to help people experience the therapeutic quality of art during the uncertain times of COVID-19. The event was organised in collaboration with Dopamine Planet founded by Harshita.",
    eventlinks: "https://www.instagram.com/p/B_XTNKKHQIZ/?utm_source=ig_web_copy_link"
  },
  {
    eventcardimgsrc: baatein,
    eventcardtitle: "Baatein",
    eventcardsubtitle: "4th-5th of April 2020",
    eventcarddesc: "This wholesome and interactive event was the first event of Huesofthemind. In collaboration with Your Safe hour, we brought an online platform to have more conversations and provide people with a Safe space to talk, express and share.",
    eventlinks: "https://www.instagram.com/p/B-XYP9ABn8v/?utm_source=ig_web_copy_link"
  },
];

export default Moreinfo;
