import React from "react";
const Facts = () => {
  return (
    <>
      <div className="facts_div">
        <h1 className="heading_facts">AROUND THE WORLD</h1>
        <p className="col-10 mx-auto d-flex sub_heading_facts">
          These statistics are not mere numbers, they highlight the mental state
          of people and how important it is to hold a healthy discussion about
          mental health to break the stigma.
        </p>
        <div className="row">
          <div className="container flex-direction col-10 mx-auto d-flex flex-direction">
            <div className="col-lg-3 col-md-4 col-sm-4 mx-auto">
              <h3 className="heading3">19%</h3>
              <p className="p1">
                of adults suffer from mental illness every year
              </p>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 mx-auto flex-direction">
              <h3 className="heading3">46%</h3>
              <p className="p1">
                of teenagers suffer from mental illness every year
              </p>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 mx-auto flex-direction">
              <h3 className="heading3">13%</h3>
              <p className="p1">
                of children suffer from mental illness every year
              </p>
            </div> 
          </div>
        </div>
        <p className="facts_source text-right">Source: World Health Organisation</p>  
      </div>
    </>
  );
};
export default Facts;
