import React from "react";
import negative from "../../images/negative.png";
import { Helmet } from "react-helmet-async";
const Negativethoughtsinfo = () => {
  return (
    <>
      <Helmet>
        <title>Are you feeling negative lately towards aspects which are quite normal? | Huesofthemind</title>
        <meta name="description" content="If you’re facing your problem, and fighting your negative thoughts, that’s the best and the most courageous thing you can do" />
        <link rel="canonical" href="https://huesofthemind.live/helpyourself/cope/negativethoughts" />
        <meta name="keywords" content="negative, negative toughts, stress, cope with stress, feeling negative,mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown" />
      </Helmet>
      <div className="neg_info">
        <div className="row">
          <div className="col-11 col-sm-10 col-md-8 col-lg-6 mx-auto d-flex flex-dir-top">
            <p className="neg_para">
              * Am I good enough?
              <br />
              * Am I wasting too much time? <br />
              * I’m too happy now. Something bad is going to happen to me <br />
              * Why’re they all looking at me? I barely know them! <br />
              * I don’t think my parents are proud of me... <br />
              * I can’t talk to them. Can you please ask for me? <br />
              * I will not ask my doubt in between the class because I’m scared
              I might mispronounce a word and the whole class will make fun of
              me. <br />
              * I’m being over productive. That’s not good. I’m stressing I’ll
              ruin things <br />
              * I can’t be good at this. I’ll destroy it completely <br />
              * Why can’t I get better!? <br />
              * I think I’m overthinking about overthinking...
              <br />
            </p>
          </div>
          <p className="neg_stop">STOP</p>
        </div>
        <div className="row">
          <div className="neg_div2 mx-auto d-flex flex-dir-nt">
            <div className="col-11 col-lg-6 col-md-10 col-sm-11 mx-auto text-center">
              <img src={negative} className="neg_img" alt="..." />
            </div>
            <div className="col-11 col-lg-6 col-md-10 col-sm-11 mx-auto">
              <p className="neg_desc">
                Take a deep breath in.
                <br />
                Exhale slowly.
                <br />
                Or scream into a pillow if you want.
                <br />
                Negative thoughts. They’re like weeds in a farm.
                <br />
                One pops up and the next moment you see multiple taking over.
                <br />
                Negative thoughts do the same.
                <br />
                <br />
                Any reason for these to stir up?
                <br />
                Common answers would be -<br />
                Post Traumatic Stress, Anxiety, Depression.
                <br />
                Yes everyone faces them.
                <br />
                It is quite common. But it’s also not. Weird right? exactly.
                <br />
                <br />
                How do some people do better than others?
                <br />
                They don’t let it overtake them.
                <br />
                They make sure they fight it.
                <br />
                If they can’t, they seek help. Either from others or themselves.
                <br />
                <br />
                Talking to a person who you trust,
                <br />
                Writing down what you feel
                <br />
                Talking to your inner voice or with yourself in front of a mirror
                <br />
                Counting numbers, etc.
                <br />
                Practising mindfulness and meditation,
                <br />
                in order to observe your thoughts,
                <br />
                are a few ways by which you don’t let them take over you.
                <br />
                <br />
                Having a mental breakdown?
                <br />
                Don’t think it’s weak to cry it all out.
                <br />
                It’s not weak to face your problems.
                <br />
                It’s the bravest thing one can do.
                <br />
                <br />
                Are your problems big enough for a break down?
                <br />
                Just to clarify, people face their problems differently.
                <br />
                For some it’s easy but for others, it’s not.
                <br />
                A problem is a problem.
                <br />
                No such thing as a “big” problem or a “small” problem.
                <br />
                If you’re facing your problem,
                <br />
                that’s the best and the most courageous thing you can do :)
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Negativethoughtsinfo;
