import React from "react";
import { NavLink } from "react-router-dom";
import landing from "../../../src/images/landing.png";
import "./css/Home/Home.css";
const Header = () => {
  return (
    <>
      <div className="landbg">
        <div className="row landing_div">
          <div className="col-12 col-lg-12 mx-auto d-flex flex-dir-bottom">
            <div
              className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto "
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <h1 className="landing_title">We help you help yourself</h1>
              <p className="landing_subtitle">
              Huesofthemind, a member organisation of the United Nations SDSN Youth, is a place to empower each other⁣⁣⁣⁣ by sharing, learning, coping & healing together⁣. We embrace and celebrate the hues of you. 
              </p>
              <div className="buy_book">
              <NavLink to="/huesofyou" className="btn btn-huesofyou ">
                 Hues of You
              </NavLink>
              <a href="https://forms.gle/exqaYhnuc11NyJer5" target="_blank" rel="noopener noreferrer" className="btn btn-volunteerland ">
                Join the Team
              </a>
              </div>
            </div>
            <div
              className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto text-center"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <img src={landing} className="land_img img-fluid" alt="hues of the mind" />
              
            </div>
          </div>
          <p className="silver_award">“IHW National Digital Health Award recipient”</p>
        </div>
      </div>
    </>
  );
};
export default Header;
