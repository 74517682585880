import React from "react";
const Huesradiocards = (props) => {
  return (
    <>
      <div className="col-lg-4 col-md-6 col-12 mx-auto">
        <div className="card events_cards h-100 text-center">
          <img src={props.radiocardimgsrc} className="card-img-top" alt={props.radiocardimgsrc} />
          <div className="card-body d-flex flex-column">
            <h5 className="card-title-events">{props.radiocardtitle}</h5>
            <hr />
            <p className="card-text-events">
              {props.radiocarddesc}
            </p>
            <div className="mt-auto">
            <a href={props.radiolinks} target="_blank" rel="noopener noreferrer" className="card-link-events"> Listen Now </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Huesradiocards;
