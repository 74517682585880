import React from "react";
import { SocialIcon } from "react-social-icons";
const Contactus = () => {
  return (
    <>
      <div className="contactus_div">
        <h1 className="contactus_title">CONTACT US</h1>
        <div className="row">
          <div className="col-10 col-lg-10 mx-auto d-flex flex-dir-top">
            <div className="col-10 col-lg-5 col-md-8 col-sm-10 mx-auto text-left">
              <h2 className="contactus_heading">Send us a mail!</h2>
              <p className="contactus_desc">
                While we’re good with spreading smiles, there are simpler ways
                for you to get one instantly.
              </p>
              <p className="contactus_email">
                <i className="fa fa-envelope"></i> huesofthemind@gmail.com
              </p>
              <div className="contactus_social_media">
                <h4 className="contactus_follow">Follow us on:</h4>
                <SocialIcon
                  url="https://facebook.com/huesofthemind"
                  target="_blank" rel="noopener noreferrer"
                  className="social_media_h"
                  bgColor="#dcdcdc"
                  fgColor="black"
                />
                <SocialIcon
                  url="https://www.instagram.com/huesofthemind"
                  target="_blank" rel="noopener noreferrer"
                  className="social_media_h"
                  bgColor="#dcdcdc"
                  fgColor="black"
                />
                <SocialIcon
                  url="https://twitter.com/huesofthemind"
                  target="_blank" rel="noopener noreferrer"
                  className="social_media_h"
                  bgColor="#dcdcdc"
                  fgColor="black"
                />
                <SocialIcon
                  url="https://www.youtube.com/channel/UCuUluSdKP5fBFQ_u05YtN7A"
                  target="_blank" rel="noopener noreferrer"
                  className="social_media_h"
                  bgColor="#dcdcdc"
                  fgColor="black"
                />
                <SocialIcon
                  url="https://www.linkedin.com/company/huesofthemind"
                  target="_blank" rel="noopener noreferrer"
                  className="social_media_h"
                  bgColor="#dcdcdc"
                  fgColor="black"
                />
                 <SocialIcon
                  url="https://open.spotify.com/user/h7ld4jj12xupyccypyhzchiws?si=G6K5uXJ9QUOoKmXuFT4-Hw&dl_branch=1"
                  target="_blank" rel="noopener noreferrer"
                  className="social_media_h"
                  bgColor="#dcdcdc"
                  fgColor="black"
                />
              </div>
            </div>

            <div className="col-10 col-lg-5 col-md-8 col-sm-10 mx-auto titles">
              <div className="row">
                <div className="mx-auto">
                  <form
                    action="https://docs.google.com/forms/u/3/d/e/1FAIpQLSfYC-mCTgDdKiEZtJOXz6D3C6LJRu76zF5jCxkKWT8j5VsuwQ/formResponse"
                    method="post"
                  >
                    <div className="mb-3 wrapper">
                      <input
                        type="email"
                        className="form-control contact_box"
                        name="entry.1045781291"
                        required
                        placeholder="Email"
                      />
                      <i className="fa fa-envelope-o fa-size"></i>
                    </div>
                    <div className="mb-3 wrapper">
                      <input
                        type="text"
                        className="form-control contact_box"
                        name="entry.1065046570"
                        required
                        placeholder="Subject"
                      />
                      <i className="fa fa-pencil fa-size"></i>
                    </div>
                    <div className="mb-3 wrapper">
                      <textarea
                        className="form-control contact_box"
                        name="entry.839337160"
                        required
                        placeholder="Message"
                        rows="3"
                      ></textarea>
                    </div>
                    <div className="col-12 text-center mb-3">
                      <button className="btn btn-send1" type="submit">
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contactus;
