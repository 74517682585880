import React from "react";
import kalika from "../../../src/images/kalika.png";
import manasi from "../../../src/images/manasi.png";
const Managers = () => {
  return (
    <>
      <div className="manager_div">
        <h1 className="manager_title">MEET THE PROJECT MANAGERS</h1>
        <div className="row">
          <div className="col-12 col-lg-12 mx-auto d-flex flex-dir-top">
            <div className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto text-center">
              <img src={manasi} className="manager-img1" alt="..." />
            </div>
            <div className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto">
              <div style={{marginTop: "auto", marginBottom:"auto", verticalAlign:"middle"}}>
                <p className="manager_desc">
                I founded Huesofthemind with a vision to build communities that bond, share, learn & cope together through their journeys. Where the hues of their minds weave a mélange of empathy, love and care for all. Accompany us on this journey, where we explore ourselves and help you help yourself.
                </p>
                <p className="manager_desc1">- Manasi Gupta</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row manager">
          <div className="col-12 col-lg-12 mx-auto d-flex flex-dir-bottom">
            <div className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto">
              <div>
                <p className="manager_desc2">
                Among the many labels I use to describe myself, a poet would be one of them. To share words from a place invisible to most, as daunting as it can be, is the most rewarding if it is given to a safe space. My story is just one of many that hope to bring this collection of words from a place invisible to most, to a place you feel safe in.
                </p>
                <p className="manager_desc4">-Kalika Aloni</p>
              </div>
            </div>
            <div className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto text-center">
              <img src={kalika} className="manager-img2" alt="..." />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Managers;
