import anupama from "../../images/anupama.png";
import kavita from "../../images/kavita.png";
import janani from "../../images/janani.png";
import kajal from "../../images/kajal.png";
import mehek from "../../images/mehek.png";
import malvika from "../../images/malvika.png";
import richhele from "../../images/richhele.png";
import sanchi from "../../images/sanchi.png";
import sakar from "../../images/sakar.png";
import divyani from "../../images/divyani.png";
import havovi from "../../images/havovi.png";
import divya from "../../images/divya.png";
import alisha from "../../images/alisha.png";
import pankti from "../../images/pankti.png";
import devikak from "../../images/devikak.png";
import mitanshi from "../../images/mitanshi.png";
import pompi from "../../images/pompi.png";
import nyamat from "../../images/nymat.png";
import devikaa from "../../images/devikaa.png";
import deva from "../../images/deva.png";
import nanki from "../../images/nanki.png";
import bhavna from "../../images/bhavna.png";
import mahima from "../../images/mahima.png";
import nelson from "../../images/nelson.png";
import jyotsna from "../../images/jyotsna.png";
import rimpa from "../../images/rimpa.png";
import manavi from "../../images/manavi.png";
import shubhangimore from "../../images/shubhangimore.png";
import manmeet from "../../images/manmeet.png";
import lenni from "../../images/lenni.png";
import hemangi from "../../images/hemangi.png";
import shristib from "../../images/shristib.png";
import aarohi from "../../images/aarohi.png";
import depika from "../../images/depika.png";
import kritika from "../../images/kritika.png";
import noor from "../../images/noor.png";
import juvena from "../../images/juvena.png";
import maruee from "../../images/maruee.png";
import niharika from "../../images/niharika.png";
import yati from "../../images/yati.png";
import sahitya from "../../images/sahitya.png";
import sahiti from "../../images/sahiti.png";
import ila from "../../images/ila.png";
import lalitha from "../../images/lalitha.png";
import sakshi from "../../images/sakshi.png";
import suhasni from "../../images/suhasni.png";
import priyam from "../../images/priyam.png";
import saral from "../../images/saral.png";
import surabhisetiya from "../../images/surabhisetiya.png";
import Shubhangiaggarwal from "../../images/shubhangiaggarwal.png";
import farheen from "../../images/farheen.jpeg";
import fahima from "../../images/fahima.jpg";
import navyad from "../../images/navyad.png";
import vanya from "../../images/vanya.png";
import amaey from "../../images/amaey.png";
import honeysharma from "../../images/honeysharma.png";
import shiromi from "../../images/shiromi.png";
import riddhi from "../../images/riddhi.png";
import gagandeep from "../../images/gagandeep.png";
import chitra from "../../images/chitra.png";
import rajvi from "../../images/rajvi.png";
import asmita from "../../images/asmita.png";
const Therapistinfo = [
    {
        id: 1,  
        hotmcardimgsrc: anupama,
        dname: "Anupama Das",
        location: "Kolkata",
        qualification: "BA Honours in Psychology, MA in Psychology, MBA in HR, Graphologist",
        experience: "1 year",
        expertise: "Couple therapy, Trauma informed care, Grief therapy, Infidelity therapy, Relationship counselling, Industrial counselling, Teenage therapy, Grapho-therapy, Grapho-Analysis",
        charges: "INR 600",
        contact: "+91 8697367143; anupama143das@gmail.com"
      },
      {
        id: 2,
        hotmcardimgsrc: kavita,
        dname: "Dr. Kavita Gupta",
        location: "Vadodara Gujarat, India",
        qualification: "MA(Clinical Psychology), PhD (Clinical Psychology-pursuing), MSc in Clinical Research, PhD in clinical research",
        experience: "3 years",
        expertise: "Counselling",
        charges: "Depends on the complexity of case",
        contact: "+91 9953682646"
      },
    
      {
        id: 3,
        hotmcardimgsrc: janani,
        dname: "Janani Renganathan",
        location: "Bangalore",
        qualification: "M.Sc.Counselling psychology ",
        experience: "3 years",
        expertise: "Trauma informed approach, Eclectic, Queer affirmative practitioner, Grief, loss, depression, anxiety, stress, self-worth, body image, communication, Relationship, self awareness, sexuality",
        charges: "INR 1000 (Sliding scale available)",
        contact: "+91 9443028132; jananirenganathan7@gmail.com; info@psychspire.com"
      },
    
      {
        id: 4,
        hotmcardimgsrc: kajal,
        dname: "Kajal Makwana",
        location: "Bandra West, Mumbai, Maharashtra, India",
        qualification: "M.A. Psychology (Counseling), Courses done - Intensive Clinical Training in Psychotherapy& Queer Affirmative Counseling Practices",
        experience: "2 years",
        expertise: "Handling queer individual and other psychological concerns",
        charges: "Online - INR 1,500 / In-clinic - INR 2,500",
        contact: "Website (www.thegiraffespace.come); Call/Text: +91 8369033130"
      },
    
      {
        id: 5,
        hotmcardimgsrc: mehek,
        dname: "Mahek Ramchandani",
        location: "Pune, Maharashtra, India",
        qualification: "M.A in Clinical Psychology",
        experience: "3 years",
        expertise: "Therapy for individuals with depression and addiction.",
        charges: "INR 800",
        contact: "+91 8010341906"
      },
    
      {
        id: 6,
        hotmcardimgsrc: malvika,
        dname: "Malavika Mukherjee",
        location: "Mumbai",
        qualification: "Masters in Counselling Psychology from Christ University, PG Diploma in Marriage and Family Therapy from TISS, Mumbai",
        experience: "2 years",
        expertise: "Specialization in emotion-focused therapy and identity-based concerns for adolescents, young adults, parents, couples, and working professionals. Body image concerns and existential crises are also areas of concern that I have frequently addressed across a wide range of populace. ",
        charges: "700 INR - 1000 INR",
        contact: "malavika.mkrj@gmail.com"
      },
    
      {
        id: 7,
        hotmcardimgsrc: richhele,
        dname: "Richelle Brito Carvalho",
        location: "Goa, India",
        qualification: "M.Sc. Counselling Psychology",
        experience: "5 years",
        expertise: "Couple and relationship counselling, Grief and Loss counselling, Trauma informed counselling, Coping with stress and anxiety, managing conflict, managing interpersonal relationships.",
        charges: "INR 1500",
        contact: "+91 9767969405; wholistic.connect@gmail.com"
      },
    
      {
        id: 8,
        hotmcardimgsrc: sanchi,
        dname: "Saanchi Saraff",
        location: "New Delhi, India",
        qualification: "M.A. Clinical psychology, Diploma in Expressive Arts Therapy",
        experience: "2 years",
        expertise:"Stress, Anxiety, Trauma-Related Issues and Relationships Issues",
        charges: "INR 1000",
        contact: "instagram.com/openmindcloset; +91 9711029011; saraffsaanchi30@gmail.com"
      },
    
      {
        id: 9,
        hotmcardimgsrc: sakar,
        dname: "Sakar Narula",
        location: "Gurugram, Haryana, India and Ghaziabad, Uttar Pradesh, India",
        qualification: "Masters in Clinical Psychology and Expressive Arts Therapist",
        experience: "2 years",
        expertise: "Stress, Anxiety, Depression, Borderline Personality Disorder, Obsessive Compulsive Disorder, Relationship Issues, Body Dysmorphia, Body Image Issues, Self Image, Self Confidence, Anger and Suicidal Management.",
        charges: "INR 1000",
        contact: "+91 9599343223"
      },
    
      {
        id: 10,
        hotmcardimgsrc: divyani,
        dname: "Divyani Chawala",
        location: "New Delhi, India",
        qualification: "Master's in Clinical Psychology",
        experience: "6 years",
        expertise: "Anxiety, Trauma and Self-based Work",
        charges: "INR 1000",
        contact: "Divyani.chawla19@gmail.com"
      },
    
      {
        id: 11,
        hotmcardimgsrc: havovi,
        dname: "Havovi Hyderabadwalla",
        location: "Online",
        qualification: "M.A. In Clinical Psychology (from India); M.Sc. in Forensic Psychology (from UK)",
        experience: "9 years",
        expertise: "Clinical Psychology & Forensic Psychology",
        charges: "INR 2,500",
        contact: "+91 9820019322"
      },
    
      {
        id: 12,
        hotmcardimgsrc: divya,
        dname: "Divya Srivastava",
        location: "Mumbai, Maharashtra, India",
        qualification: "M.A. Counselling Psychology",
        experience: "9 years",
        expertise: "Anxiety, Eating Disorders, Stress, Trauma; Relationship Concerns",
        charges: "INR 3,000",
        contact: "support@thesilverlining.in"
      },
    
      {
        id: 13,
        hotmcardimgsrc: alisha,
        dname: "Alisha Shail",
        location: "Mumbai, Maharashtra, India",
        qualification: "M.A Clinical Psychology",
        experience: "6 years",
        expertise: "Testing, Clinical Psychology, Personality Disorders, Anxiety, Depression and any other Mental health Issue that needs holding space.",
        charges: "INR 2,000",
        contact: "+91 9920731799; alisha.shail1592@gmail.com"
      },
      {
        id: 14,
        hotmcardimgsrc: pankti,
        dname: "Pankti Gohel",
        location: "Vadodara, Gujarat, India",
        qualification: "B.A. (Hons) Psychology & M.A. in Applied Psychology with specialization in Counselling",
        experience: "4.5 years",
        expertise: "Self-based work, Relationships, Anxiety, Stress, Trauma, and Workplace related concerns. Experience in individual psychotherapy, couples therapy, and family therapy.",
        charges: "INR 1500 to INR 2500 depending on the client's financial capacity ",
        contact: "+91 78783 88880; pankti93@gmail.com"
      },
      {
        id: 15,
        hotmcardimgsrc: devikak,
        dname: "Devika Kapoor",
        location: "Mumbai, Maharashtra, India",
        qualification: "MA Counseling Psychology",
        experience: "4 years",
        expertise: "Anxiety, Depression, Relationship Concerns and Trauma.",
        charges: "INR 1,500",
        contact: "9619046437; instrength12@gmail.com"
      },
      {
        id: 16,
        hotmcardimgsrc: mitanshi,
        dname: "Mitanshi Shah",
        location: "Ahmedabad, Gujarat, India",
        qualification: "MSc. Psychological Therapies (from QMUL, London)",
        experience: "2 years ( 850+ hours of practice)",
        expertise: "Anxiety, Depression, Relationship Issues, Stress",
        charges: " INR 500",
        contact: "+91 9913000025; mitanshishah1698@gmail.com"
      },
      {
        id: 17,
        hotmcardimgsrc: pompi,
        dname: "Pompi Banerjee",
        location: "Online",
        qualification: "M. Sc in Applied Psychology (Clinical), PGD Psychological Counseling, Internship in Process Work",
        experience: "10 years",
        expertise: "Adult Survivors of trauma and abuse, Gender Identity, Sexuality, Trauma Responses, Interpersonal Relationship Issues, Self-Exploration, Anxiety, Depression, Anger Management, Stress Management, Self-Esteem Issues. Doesn't work with children.",
        charges: "Sliding scale fees structure",
        contact: "+91 7044082034; pompi.banerjeekc@gmail.com"
      },
      {
        id: 18,
        hotmcardimgsrc: nyamat,
        dname: "Nyamat Chadha",
        location: "Mumbai, Maharashtra, India",
        qualification: "M.A. Clinical Psychology",
        experience: "2 years",
        expertise: "Anxiety, Depression, Relational Concerns",
        charges: "INR 1,000",
        contact: "nyamat.chadha@gmail.com"
      },
      {
        id: 19,
        hotmcardimgsrc: devikaa,
        dname: "Devika Arora",
        location: "Bangalore, Karnataka, India",
        qualification: "BSc M.A Psych",
        experience: "3 years",
        expertise: "Anxiety, Relationships, Goal and Career Clarity",
        charges: "INR 1,500",
        contact: "9830912234; debou13@gmail.com"
      },
      {
        id: 20,
        hotmcardimgsrc: deva,
        dname: "Deva Pramod VB",
        location: "Kerala, India",
        qualification: "M.Sc Applied Psychology - Clinical, PGDGC",
        experience: "6 years",
        expertise: "Experienced in Behavior modification Programs, Counselling, Therapeutic Interventions for Neurotic conditions, Career management, Psychological testing & Assessments, Learning Interventions and management, Family Well being Counselling areas includes Child/Adolescent, Student, Individual, Parental, Pre marital - Marital and adjustments, Family",
        charges: "INR 1,000",
        contact: "+91 7591911213; vbdevan.psy@gmail.com; https://www.facebook.com/vbdevan.psy; https://www.facebook.com/askthepsychologistvbd/"
      },
      {
        id: 21,
        hotmcardimgsrc: nanki,
        dname: "Nanki Oberoi",
        location: "Delhi NCR, New Delhi, India",
        qualification: "MSc. Psychoanalytic Developmental Psychology; MA Psychology; BA(hons) Psychology",
        experience: "7 years",
        expertise: "Child and Adolescent Mental Health",
        charges: "INR 2,500 to 3,000",
        contact: "nankioberoi11@gmail.com"
      },
      {
        id: 22,
        hotmcardimgsrc: bhavna,
        dname: "Bhavna Lalwani",
        location: "Mumbai, Maharashtra, India",
        qualification: "MA in Counselling (from TISS); LLB (from MU)",
        experience: "10 years",
        expertise: " Narrative-based + Feminist Approach. Children and families with developmental disabilities, women and children who have been victims of domestic violence and sexual abuse, and adults with emotional and behavioral concerns which stem from their relationships, workplace stressors, personal loss and trauma.",
        charges: "INR 1,000 to 3,000",
        contact: "+91 9869543729"
      },
      {
        id: 23,
        hotmcardimgsrc: mahima,
        dname: "Mahima Mallya",
        location: "Bangalore, Karnataka, India",
        qualification: "M.Sc in Counselling Psychology",
        experience: "4 years",
        expertise: "Relationship Issues, Self-Esteem, Depression, Anxiety, Work-Life Balance, Stress & Emotional Management",
        charges: "INR 1,000",
        contact: "mahimamallya18@gmail.com; Book an appointment here: https://booking.appointy.com/mahimamallya"
      },
      {
        id: 24,
        hotmcardimgsrc: nelson,
        dname: "Dr. Nelson C. Elias",
        location: "Online",
        qualification: "M A.,M.Phil.,Ph.D (Psy.), B.Ed., PGDGC",
        experience: "3 years",
        expertise: "Counselling and Therapy",
        charges: "Free Service",
        contact: " +91 9288092788; psyclinichelpline@gmail.com"
      },
      {
        id: 25,
        hotmcardimgsrc: jyotsna,
        dname: "Jyotsna Sodhi",
        location: "Vasant Vihar, New Delhi, India",
        qualification: " MA in Applied Clinical Psychology; Advanced Diploma in REBT (Albert Ellis Institute, New York)",
        experience: "3 years",
        expertise: "Child, Adolescent, Young Adult mental health",
        charges: "INR 2,500",
        contact: "+91 9821477730; jyotsna@karmacare.in"
      },
      {
        id: 26,
        hotmcardimgsrc: rimpa,
        dname: "Rimpa Sarkar",
        location: "Mumbai, Online",
        qualification: "Masters in Clinical Psychology; Trained in CBT & NLP",
        experience: "More than 10 years",
        expertise: "Suicidal ideation, Mood Disorder & Anxiety Disorder",
        charges: "INR 1,000 to 2,500",
        contact: "+91 9769592733; hello@rimpasarkar.com"
      },
      {
        id: 27,
        hotmcardimgsrc: manavi,
        dname: "Manavi Khurana",
        location: "Vasant Vihar, New Delhi, India",
        qualification: "Masters in Counselling (from NYU)",
        experience: "8 years",
        expertise: "Sexuality & Sexual Health",
        charges: "INR 4,000",
        contact: "+91 9821477730"
      },
      {
        id: 28,
        hotmcardimgsrc: shubhangimore,
        dname: "Shubhangi More",
        location: "Jharkhand",
        qualification: "MSc Counseling Psychology (Honors)/ Training in Couple & Family Therapy",
        experience: "1 year",
        expertise: "Relationships/ Anxiety/ Trauma/ Grief/ Depression/ Personal growth",
        charges: "1000 (sliding scale available)",
        contact: "7992286410/ shubhangi.therapist@pm.me"
      },
      {
        id: 29,
        hotmcardimgsrc: manmeet,
        dname: "Manmeet Nagi",
        location: "Amritsar, Punjab",
        qualification: "MA in psychology",
        experience: "2 years",
        expertise: "Trauma , gender sexuality and addiction",
        charges: "INR 800 to INR 1500",
        contact: "Manmitkor@gmail.com or 8195979488"
      },
      {
        id: 30,
        hotmcardimgsrc: lenni,
        dname: "Lenni George",
        location: "Hyderabad",
        qualification: "MSc(hon) Counselling Psychology, QACP",
        experience: "5+ years",
        expertise: "Individual and group, queer, trauma, abuse, body image and anxiety ",
        charges: "INR 1,500",
        contact: "www.fenweh.com/book-online"
      },
      {
        id: 31,
        hotmcardimgsrc: hemangi,
        dname: "Hemangi Vyawahare",
        location: "Online",
        qualification: "MA Clinical Psychology, PHD Scholar",
        experience: "8+ years",
        expertise: "Non directive existential therapy",
        charges: "INR 1000-1500",
        contact: "Info@hemangivyawahare.com, https://www.hemangivyawahare.com/"
      },
      {
        id: 32,
        hotmcardimgsrc: shristib,
        dname: "Srishti Banerjee",
        location: "Bangalore",
        qualification: "Certified Queer Affirmative Therapist, QACP Mariwala Health Initiative; MSc. Mental Health Studies, King's College London",
        experience: "3+ years",
        expertise: "Expertise - LGBT+ mental health, stress and burnout, emotional regulation, career-related issues",
        charges: "INR 1250 -1500 (1250 for students and 1500 for working professionals)",
        contact: "therapyatidentity@gmail.com"
      },
      {
        id: 33,
        hotmcardimgsrc: aarohi,
        dname: "Aarohi Doshi",
        location: "Pune, Maharashtra, India",
        qualification: "M.A Clinical Psychology, Post Graduate Diploma in Counseling Psychology, Certifications in Dance Movement therapy, Visual Art Therapy, Reiki",
        experience: "5 years",
        expertise: "Counseling through the medium of Art and Movement- Relationships, (Couple, Family, Work), Adolescents with Special Needs, Individuals suffering with Anxiety",
        charges: "INR 1,000 for one personal session for an hour. Groups of 6 - INR 3,000 for one workshop per hour",
        contact: "info@aarohidoshi.com, Website- www.aarohidoshi.com, Phone: 9823519725 (WhatsApp only for now) (15min free consultation)"
      },
      {
        id: 34,
        hotmcardimgsrc: depika,
        dname: "Deepika Oberoi",
        location: "Mumbai, Maharashtra, India",
        qualification: "Msc Organic Chemistry; MA Counseling Psychology; SEN Diploma",
        experience: "1 year (approximately)",
        expertise: "REBT Practitioner",
        charges: "INR 500",
        contact: "oberoi.deepika@gmail.com"
      },
    
      {
        id: 35,
        hotmcardimgsrc: kritika,
        dname: "Dr Krithika Umananth",
        location: "Chennai, Tamil Nadu, India",
        qualification: "Bachelors in Homeopathic Medicine and Surgery (BHMS - Qualified as a Medical Doctor), Certified Emotional Intelligence Coach, Certified Cognitive Behavioural Coach, Certified Storytelling Coach, Certified Social Emotional Skills Consultant",
        experience: "1 year",
        expertise: "Coaching and Mentoring using Emotional Intelligence, Positive Psychology, Expressive Arts, CBT",
        charges: "INR 500 per hour",
        contact: "krithikaumananth@gmail.com; instagram.com/krithika_umananth; https://www.facebook.com/KrithikaUmananth"
      },
    
      {
        id: 36,
        hotmcardimgsrc: noor,
        dname: "Dr. Noor Gill",
        location: "Jalandhar, Punjab, India",
        qualification: "MBBS, Diploma in Psychotherapy, I also sometimes write (no qualification, just creative)",
        experience: "1 year",
        expertise: "Doctor (Allopathy); Psychotherapy; Writing/Journaling",
        charges: "For a therapy session - INR 200 per 45 mins; For a session to guide you on writing as a form of expression/ journaling - INR 150 per 30 mins",
        contact: "noorgill169@gmail.com"
      },
      {
        id: 37,
        hotmcardimgsrc: juvena,
        dname: "Juvena Ajwani",
        location: "Pune, Maharashtra, India",
        qualification: "BA is Psychology, PG Diploma in Dance/Movement Therapy",
        experience: "9 months",
        expertise: "Body-based approach on underlying issues of Self-Perception, Body Image, and Awareness through an Art-and-Movement-based Lens",
        charges: "INR 200 to 300 per person in a group setting",
        contact: "+91 9604266826; juvena.mentalhealth@gmail.com"
      },
    
      {
        id: 38,
        hotmcardimgsrc: maruee,
        dname: "Mauree Pahuja",
        location: "Pune, Maharashtra, India",
        qualification: "Expressive Arts Practitioner, Masters of Optometry",
        experience: "Less than 1 year",
        expertise: "Expressive Arts",
        charges: "INR 1,500",
        contact: "9890540404"
      },
    
      {
        id: 39,
        hotmcardimgsrc: niharika,
        dname: "Neeharika Jaiswal",
        location: "New Delhi, India",
        qualification: "MBBS, MD Psychiatry",
        experience: "4 years",
        expertise: "All Psychiatric Disorders",
        charges: "INR 1,000",
        contact: "sincerely.neha@gmail.com; instagram.com/_brainandmind_"
      },
    
      {
        id: 40,
        hotmcardimgsrc: yati,
        dname: "Yati Parikh",
        location: "Mumbai, Maharashtra, India",
        qualification: "Post Grad in Behavioral Science and Internationally certified Intermodal Expressive Arts Practitioner",
        experience: "More than 6 years",
        expertise: "Healing through the use of Expressive Arts",
        charges: "INR 1,500 per 1 hour session",
        contact: "instagram.com/spaceforexpressivearts; contact.ekasva@gmail.com"
      },
    
      {
        id: 41,
        hotmcardimgsrc: sahitya,
        dname: "Sahitya Shrivastava",
        location: "Online",
        qualification: "Bachelors Degree in Music, Psychology and English Literature (from Christ University, Bangalore); PG Diploma Certificate in Expressive Arts Therapy (qualifies as an Expressive Arts Therapy Practitioner - from St Xavier's, Bombay)",
        experience: "Internship under PG Diploma Certificate",
        expertise: "Expressive Arts Therapy",
        charges: "INR 500 per 45 minute session",
        contact: "+91 8296487673; shrivastava.sahitya8993@gmail.com"
      },
      {
        id: 42,
        hotmcardimgsrc: sahiti,
        dname: "Sahiti Gavarikar",
        location: "Bangalore, Karnataka, India",
        qualification: "M.Sc in Counselling Psychology",
        experience: "2 years",
        expertise: "Cognitive Behaviour Therapy",
        charges: "INR 500 to 1,000",
        contact: "therestoryproject@protonmail.com"
      },
    
      {
        id: 43,
        hotmcardimgsrc: ila,
        dname: "Ila Kulshrestha",
        location: "Delhi NCR, New Delhi, India",
        qualification:" M.Phil. Clinical Psychology",
        experience: "7 years",
        expertise: "Trauma informed psychotherapy, Expressive arts therapy, Queer affirmative Psychotherapy, Clinical conditions, couples therapy",
        charges: "INR 2,000",
        contact: "+91 9599886775; ila.kulshrestha@gmail.com"
      },
      {
        id: 44,
        hotmcardimgsrc: lalitha,
        dname: "Lalitha Pooja",
        location: "Hyderabad, Andhra Pradesh, India",
        qualification: "M.Sc in Applied Psychology with Specialisation in Counselling ",
        experience: "2 years",
        expertise: "Certified in Queer Affirmative and Narrative therapy, and is a Mindfulness practitioner.",
        charges: "1000 (sliding scale available)",
        contact: "+91 9600134225; ishliving@gmail.com"
      },
      {
        id: 45,
        hotmcardimgsrc: sakshi,
        dname: "Sakshi Jain",
        location: "Mumbai, Maharashtra, India",
        qualification: "Masters in Clinical Psychology, Certified RECBT therapist",
        experience: "3 years",
        expertise: "Therapy & Testing",
        charges: "INR 1,000",
        contact: "+91 9029069694"
      },
      {
        id: 46,
        hotmcardimgsrc: suhasni,
        dname: "Suhasini Subramanian",
        location: "New Delhi, India",
        qualification: "MSc Psychology and UNESCO-CID Certified Expressive Arts Based Therapist",
        experience: "3 years",
        expertise: "Expressive arts therapy, stress management, multi-cultural/third-culture challenges, anxiety, depression, interpersonal relationship concerns, personal growth, body image",
        charges: "INR 3,000",
        contact: "suhasini@karmacare.in"
      },
      {
        id: 47,
        hotmcardimgsrc: priyam,
        dname: "Priyam Kumar",
        location: "New Delhi, India",
        qualification: "Masters in Psychology, Post Graduate Diploma in Rehabilitation Psychology, UNESCO certified (Expressive Art Therapist), RCI-Certified",
        experience: "5 years",
        expertise: "Cognitive Behaviour Therapy and Expressive Art Therapy",
        charges: "INR 950",
        contact: "priyamkumar.mh@gmail.com"
      },
      {
        id: 48,
        hotmcardimgsrc: saral,
        dname: "Sarla Totla",
        location: "Kolkata, India",
        qualification: "Advanced 3 Year Diploma in Counselling & Stress Management",
        experience: "6+ years",
        expertise: "Cognitive Behaviour Therapy based counselling- stress, depression, anxiety, personality disorder, relationship conflicts, parental guidance, addiction & substance abuse" ,
        charges: "INR 1200",
        contact: "+91 9836616000; sarlatotla@gmail.com"
      },
      {
        id: 49,
        hotmcardimgsrc: Shubhangiaggarwal,
        dname: "Shubhangi Aggarwal",
        location: "Delhi",
        qualification: "M.Phil Clinical Psychology",
        experience: "4+ years",
        expertise: "Clinical Psychology",
        charges: "INR 1000",
        contact: "9999643758"
      },
      {
        id: 50,
        hotmcardimgsrc: surabhisetiya,
        dname: "Surabhi Setiya",
        location: "Pune",
        qualification: "Msc in clinical neurodevelopmental sciences and pursuing PGCCP",
        experience: "3 years",
        expertise: "Depression, anxiety and relationship counselling ",
        charges: "1200 rupees for 60 mins session",
        contact: "Through instagram- therapybysurabhi"
      },
      {
        id: 51,
        hotmcardimgsrc: farheen,
        dname: "Farheen Ayesha",
        location: "Bangalore",
        qualification: "M.Sc in Psychology, Certified in Queer Affirmative Therapy by MHI. ",
        experience: "1 year",
        expertise: "Queer Affirmative Existential- Gestalt- Feminist Approach ",
        charges: "INR 1000",
        contact: "farheen.ayesha257@gmail.com, 7406974512"
      },
      {
        id:52,
        hotmcardimgsrc: fahima,
        dname: "Fahima Rashid ",
        location: "Lucknow",
        qualification: "M.A clinical psycholgy, P.G.dip counselling psychology",
        experience: "15 years",
        expertise: "Parenting troubles, adolescent behavioral and emotional issues, Stress and  Anxiety related issues ",
        charges: "INR 1000",
        contact: "7275537810"
      },
      {
        id:53,
        hotmcardimgsrc: navyad,
        dname: "Navya D Mahajan",
        location: "Lucknow",
        qualification: "MPhil Clinical Psychology ",
        experience: "6 years",
        expertise: "Cognitive behavioural therapy, Mindfulness based practices, emotion focused therapy ",
        charges: "INR 1200",
        contact: "8527840660, listeningspace.work@gmail.com"
      },
      {
        id:54,
        hotmcardimgsrc: vanya,
        dname: "Vanya Chadha (She/her)",
        location: "Noida, Uttar Pradesh",
        qualification: "B.A (H) Psychology from Daulat Ram College, Delhi University (2015-2018) and M.A Counselling Psychology from Amity University Noida (2018-2020).",
        experience: "1 year",
        expertise: "Personal Counselling, Anxiety Counselling, Stress management, Relationship management, Anger management and Time management.",
        charges: "INR 400",
        contact: "reflectandheal@gmail.com"
      },
      {
        id:55,
        hotmcardimgsrc: amaey,
        dname: "Dr Amaey Parekh",
        location: "Mumbai",
        qualification: "MDS (Prosthodontics and Implantology), MAPC (Counseling Psychology), Pg Dip (Art Therapy), Certified Reiki Master, Pranic Healer, Hypnotherapist, Silva Mind Control Graduate, DMIT Counsellor",
        experience: "Mental Health and Healing - 3 years, Dentistry - 11 years",
        expertise: "Stress, Anxiety, Relationships, Self-love, Inner Child Healing, Past Life Regression, Art Therapy, Career counseling",
        charges: "INR 1200",
        contact: "thethinkingpalette@gmail.com, +919821698346"
      },
      {
        id:56,
        hotmcardimgsrc: honeysharma,
        dname: "Honey Sharma",
        location: "Gurugram",
        qualification: "Graduation(Clinical Psychology),Post Graduation(Clinical Psychology), PGDGC,B.Ed",
        experience: "4 years",
        expertise: "Counselling",
        charges: "INR 700",
        contact: "+919873117527 (Whatsapp)"
      },
      {
        id:57,
        hotmcardimgsrc: shiromi,
        dname: "Shiromi Chaturvedi",
        location: "Delhi, Jaipur, and Mumbai",
        qualification: "Shiromi - MA Applied Psychology (specialization in counseling), TISS; Absy- MA in Community Psychology, University of Brighton (UK); Janice- M.Sc. Psychological Counseling, IIPR Bangalore",
        experience: "2 years",
        expertise: "Trauma, Child Counseling, relationship counseling, counseling and guidance, anxiety, depression, self-esteem, body image, academic concerns, workplace issues, and so on. ",
        charges: "INR 500-2000",
        contact: "thesocialtherapistindia@gmail.com, Instagram: @therapyshorts"
      },
      {
        id:58,
        hotmcardimgsrc: riddhi,
        dname: "Riddhi Panchal",
        location: "Mumbai & Online",
        qualification: "BA in Psychology, MA (Clinical Psychology)",
        experience: "3.5 years",
        expertise: "Anxiety, Depression, BPD, Burn out, Career issues, Exam anxiety, Emotional issues, Self-esteem issues, relationship counseling, life transitions, etc.",
        charges: "Offline/In clinic: 1st session - INR 1500/-. Follow up sessions - INR 1000/-; Online session: First session - INR 1000/-, Online session: INR 800/-",
        contact: "8850674186, swamann.journey@gmail.com"
      },
      {
        id:59,
        hotmcardimgsrc: gagandeep,
        dname: "Gagandeep Kaur",
        location: "C-Block, New Friends Colony, New Delhi-110065",
        qualification: "MPhil in Clinical Psychologist (IHBAS)",
        experience: "15 years",
        expertise: "Clinical Psychology, Relationships issues, Adolescent Counselling.",
        charges: "INR 1500/-",
        contact: "9818359575"
      },
      {
        id:60,
        hotmcardimgsrc: chitra,
        dname: "Chitrangana Patankar",
        location: "Online",
        qualification: "M.A. in Psychology with an Emphasis in Counseling; Diploma in Counselling; B.A. in Psychology.",
        experience: "1 year",
        expertise: "Psychological Counseling ",
        charges: "INR 1000/-",
        contact: "chitra_patankar@hotmail.com"
      },
      {
        id:61,
        hotmcardimgsrc: rajvi,
        dname: "Rajvi Turakhia",
        location: "Mumbai & Online",
        qualification: "Masters in Psychology: Lifespan Counselling ",
        experience: "8 months",
        expertise: "Anxiety, stress, self-esteem, self-confidence, anger, relationship issues, etc. Can work with 8+ age of the clients based on the education and work experience. ",
        charges: "INR 1000/-",
        contact: "therapywithrajvi@gmail.com"
      },
      {
        id:62,
        hotmcardimgsrc: asmita,
        dname: "Asmita Nagpal",
        location: "New Delhi, India",
        qualification: "Masters in Clinical Psychology; Advanced Diploma in Child Guidance and Counselling ",
        experience: "2 years",
        expertise: "Child and Adolescent Counselling, Parental Counselling ",
        charges: "INR 700/- for a 50 minutes session ",
        contact: "Instagram- @parentinapickle (DM), Email: parentinapickle@gmail.com, Phone: 9654744665"
      },
]
export default Therapistinfo;