import React from 'react';
import HotmRepocard from './HotmRepocard';

function SearchedRepo({ filteredPersons }) {
  const filtered = filteredPersons.map( person =>  <HotmRepocard key={person.id} person={person} />); 
  return (
    <div>
      {filtered}
    </div>
  );
}

export default SearchedRepo;