import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const Modalrepo = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <>
      <button className="drop_mail" onClick={onOpenModal} >Drop a mail!</button>
      <Modal open={open} onClose={onCloseModal} center>
        <form action="https://docs.google.com/forms/u/3/d/e/1FAIpQLScBILhU3gzT7VEZjnZM1aCn2YU3CuqaNfWyeAmjOy1lt_kbow/formResponse" method="POST">
          <div className="mb-3 label_modal">
            <label className="col-form-label">Your Email:</label>
            <input type="text" name="entry.1556369182" className="form-control" placeholder="name@example.com" required/>
          </div>
          <div className="mb-3 label_modal">
            <label className="col-form-label">Do you have any query/message for us?</label>
            <textarea name="entry.299331690" className="form-control" placeholder="Message..."></textarea>
          </div>
          <button type="submit" className="btn btn-submodal">Submit</button>
        </form>
      </Modal>
    </>
  );
};
export default Modalrepo;