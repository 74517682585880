import React from "react";
import { NavLink } from "react-router-dom";
const SelfCare = () => {
  return (
    <>
      <div className="selfcare_div_outer">
        <div className="selfcare_div">
          <div className="selfcare_card_div">
            <div className="card selfcare_card">
              <div className="card-body">
                <h5 className="card-title selfcare_title_card">SELF CARE</h5>
                <hr />
                <p className="card-text selfcare_card_desc">
                In the long run, if you don’t take time to fill up your own cup, you won’t be able to pour from it any more. While some may misconstrue self-care as selfish, it's far from that. Take a step forward towards having a healthy relationship with yourself because if not now, then when?
                </p>
                <NavLink to="/helpyourself/cope/selfcare" className="btn btn-selfcare">
                  Get Started
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SelfCare;
