import React from "react";
//import pranavi from "../../../src/images/pranavi1.png";
import manasi from "../../../src/images/manasi.png";
import saanchi from "../../../src/images/Saanchi.png";
import krithika from "../../../src/images/krithika.png";
import rishi from "../../../src/images/rishi.png";
import shristi from "../../../src/images/shristi.png";
import goutam from "../../../src/images/Goutam.png";
import rohit from "../../../src/images/rohit.png";
import ali from "../../../src/images/ali.png";
import vrinda from "../../../src/images/vrinda.png";
import kalika from "../../../src/images/kalika.png";
import raashi from "../../../src/images/raashi1.png";
import secret from "../../../src/images/secret.png";
import janani from "../../../src/images/janani1.png";
import noor from "../../../src/images/noor1.png";
import devanshi from "../../../src/images/devanshi.png";
import anushka from "../../../src/images/anushka.png";
import hrushita from "../../../src/images/hrushita.png";
import shubhi from "../../../src/images/shubhi.png";

import { SocialIcon } from "react-social-icons";
import "./style.css";
import Sahaarareadmore from "./Sahaarareadmore";
const Sahaara = () => {
  return (
    <>
      <div className="sahaara_div">
        <div className="container">
          <div className="col-lg-10 col-md-10 col-sm-11 col-11 mx-auto text-center">
            <h3 className="sahaara_title"> MEET OUR 'TEAM SAHAARA'</h3>
            <p className="sahaara_desc">
              ‘Sahaara’ translates to Support, and our team envisions to support
              others and make a difference in their lives. We do everything with
              love, that’s what makes our organization beautiful.
            </p>
          </div>
          <div className="row  car_hide">
            <div className="col-12">
              <div className="top-content">
                <div
                  id="carouselExampleIndicators2"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#carouselExampleIndicators2"
                      data-slide-to="0"
                      className="active"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators2"
                      data-slide-to="1"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators2"
                      data-slide-to="2"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators2"
                      data-slide-to="3"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators2"
                      data-slide-to="4"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators2"
                      data-slide-to="5"
                    ></li>
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="row sahaara_cards">
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img className="img-fluid" alt="..." src={manasi} />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Manasi Gupta
                              </h4>
                              <h6 className="card_subtitle_sahaara">
                                Founder & Executive Director
                              </h6>
                              <hr />
                              <p className="card-text card_stext">
                                Empathy, Consistency and Courage are the biggest
                                instruments of Change.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/manasichangemaker/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={shristi}
                            />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Shristi Pandey
                              </h4>
                              <h6 className="card_subtitle_sahaara">
                                Content Coach
                              </h6>
                              <hr />
                              <p className="card-text card_stext">
                                We're all constellations but changing. We find
                                better fits, and on rare occasions we face
                                obstacles, but we'll still align.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/shristi-pandey-0387a3193/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={pranavi}
                            />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Pranavi Pendyala
                              </h4>
                              <h6 className="card_subtitle_sahaara">
                                Director of Operations
                              </h6>
                              <hr />
                              <p className="card-text card_stext">
                                It takes Sunshine and Rain to make a Rainbow.
                                So, do not surrender your wonderfulness and
                                uniqueness no matter what!
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/pranavi-pendyala-4ab2271b7/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img className="img-fluid" alt="..." src={ali} />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Ali Hasnain
                              </h4>
                              <h6 className="card_subtitle_sahaara">
                                Director of Operations
                              </h6>
                              <hr />
                              <p className="card-text card_stext">
                                You miss 100 percent of the chances you don't
                                take. -Wayne Gretzky
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/ali-hasnain-52421a1a4/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row sahaara_cards">
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img className="img-fluid" alt="..." src={kalika} />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Kalika Aloni
                              </h4>
                              <h6 className="card_subtitle_sahaara">
                                Director of Design
                              </h6>
                              <hr />
                              <p className="card-text card_stext">
                                The time is Now - yet in this Now is a sense of
                                timelessness. The place is Here - yet in this
                                Here is a hint of everywhere -Ann Atwood
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.instagram.com/a.foxwithsocks/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img className="img-fluid" alt="..." src={vrinda} />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Vrinda Gupta
                              </h4>
                              <h6 className="card_subtitle_sahaara">
                                Design Coach
                              </h6>
                              <hr />
                              <p className="card-text card_stext">
                                Embrace your flaws, imperfection is beautiful.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/vrindaguptaa/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={hrushita}
                            />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Hrushitaa Murali
                              </h4>
                              <h6 className="card_subtitle_sahaara">
                                Vice Executive Director
                              </h6>
                              <hr />
                              <p className="card-text card_stext">
                                Give yourself the power to choose to be
                                vulnerable only in front of those you like, not
                                those who like you.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/hrushitaa/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row sahaara_cards">
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={anushka}
                            />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Anushka Gupta
                              </h4>
                              <h6 className="card_subtitle_sahaara">
                                Director of Human Resources
                              </h6>
                              <hr />
                              <p className="card-text card_stext">
                                Let everything happen to you. Beauty and terror.
                                Just keep going. No feeling is final. -Rainer
                                Maria Rilke
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="http://instagram.com/_anushkagupta__"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img className="img-fluid" alt="..." src={noor} />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Dr. Noor Gill
                              </h4>
                              <h6 className="card_subtitle_sahaara">Advisor</h6>
                              <hr />
                              <p className="card-text card_stext">
                                Working on making caring cool again.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/noor-gill-757214191/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img className="img-fluid" alt="..." src={janani} />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Janani Renganathan
                              </h4>
                              <h6 className="card_subtitle_sahaara">Advisor</h6>
                              <hr />
                              <p className="card-text card_stext">
                                Grow through what you go through
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/janani-renganathan-402519164/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row sahaara_cards">
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={krithika}
                            />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Dr. Krithika Ananth
                              </h4>
                              <h6 className="card_subtitle_sahaara">Advisor</h6>
                              <hr />
                              <p className="card-text card_stext">
                                A doctor, coach and mentor working towards
                                spreading awareness and perspectives on holistic
                                well being, growth and development in
                                individuals and societies.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/krithikaumananth/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img className="img-fluid" alt="..." src={raashi} />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Raashi Thakran
                              </h4>
                              <h6 className="card_subtitle_sahaara">Advisor</h6>
                              <hr />
                              <p className="card-text card_stext">
                                “If you can see your path laid out in front of
                                you step by step, you know it’s not your path.
                                Your own path you make with every step you take.
                                That’s why it’s your path.” - Joseph Campbell
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/raashi-thakran-348241168"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={saanchi}
                            />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Saanchi Saraff
                              </h4>
                              <h6 className="card_subtitle_sahaara">Advisor</h6>
                              <hr />
                              <p className="card-text card_stext">
                                The beautiful thing about learning is nobody can
                                take it away from you. Even a small kid teaches
                                you patience, be open to knowledge and you'll
                                always grow.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/saanchi-saraff-3bba4797/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row sahaara_cards">
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={devanshi}
                            />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Devanshi Chaubey
                              </h4>
                              <h6 className="card_subtitle_sahaara">
                                Content Coach
                              </h6>
                              <hr />
                              <p className="card-text card_stext">
                                Your gracious and daring heart doesn't go
                                unappreciated.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/devanshi-chaubey-a1b9a71aa/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img className="img-fluid" alt="..." src={goutam} />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Goutam Sree Govind
                              </h4>
                              <h6 className="card_subtitle_sahaara">
                                Content Coach
                              </h6>
                              <hr />
                              <p className="card-text card_stext">
                                Passion is that one thing that makes a person
                                challenge everything.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/goutamsreegovind/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img className="img-fluid" alt="..." src={rohit} />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Rohit Chakraborty
                              </h4>
                              <h6 className="card_subtitle_sahaara">
                                Content Coach
                              </h6>
                              <hr />
                              <p className="card-text card_stext">
                                Walk slowly, but never walk backwards.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/rohit-chakraborty-387694179/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row sahaara_cards">
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img className="img-fluid" alt="..." src={secret} />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                The Xenial Secret
                              </h4>
                              <h6 className="card_subtitle_sahaara">
                                Content Coach
                              </h6>
                              <hr />
                              <p className="card-text card_stext">
                                Kindness, this is what is missing!
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.instagram.com/thexenialsecret/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img className="img-fluid" alt="..." src={rishi} />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Rishi Raj Singh
                              </h4>
                              <h6 className="card_subtitle_sahaara">
                                Operations Coach
                              </h6>
                              <hr />
                              <p className="card-text card_stext">
                                Heal yourself before you help heal others
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/rishirajsinghchhabra/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_sahaara">
                            <img
                              className="img-fluid"
                              alt="shubhi"
                              src={shubhi}
                            />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_sahaara_title">
                                Shubhi
                              </h4>
                              <h6 className="card_subtitle_sahaara">
                                Technology Coach
                              </h6>
                              <hr />
                              <p className="card-text card_stext">
                                Lead from the heart, not the head.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/shubhi-dwivedi-66984b189/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    className="carousel-control-prev"
                    href="#carouselExampleIndicators2"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#carouselExampleIndicators2"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Sahaarareadmore />
        </div>
      </div>
    </>
  );
};
export default Sahaara;
