import React from "react";
import contact2 from "../../images/contact2.png";
import { SocialIcon } from "react-social-icons";
import { Helmet } from "react-helmet-async";
const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us | Huesofthemind </title>
        <meta name="description" content="While we’re good with spreading smiles, there are simpler ways for you to get one instantly." />
        <link rel="canonical" href="https://huesofthemind.live/contact" />
        <meta name="keywords" content="mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown" />
      </Helmet>
      <div className="contact_uspage_div">
        <h1 className="contact_us_title">CONTACT US</h1>
        <div className="row">
          <div className="col-10 col-lg-7 col-md-10 col-sm-10 mx-auto">
            <p className="contact_us_desc text-center">
              While we’re good with spreading smiles, there are simpler ways for
              you to get one instantly.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-10 col-lg-10 mx-auto d-flex flex-dir-contact">
            <div className="col-10 col-lg-5 col-md-8 col-sm-10 mx-auto">
              <h2 className="contact_us_heading">Send us a mail!</h2>
              <p className="contact_us_email">
                <i className="fa fa-envelope"></i> huesofthemind@gmail.com
              </p>
              <form
                action="https://docs.google.com/forms/u/3/d/e/1FAIpQLSfYC-mCTgDdKiEZtJOXz6D3C6LJRu76zF5jCxkKWT8j5VsuwQ/formResponse"
                method="post"
              >
                <div className="mb-3 wrapper">
                  <input
                    type="email"
                    className="form-control contact_box"
                    name="entry.1045781291"
                    required
                    placeholder="Email"
                  />
                  <i className="fa fa-envelope-o fa-size"></i>
                </div>
                <div className="mb-3 wrapper">
                  <input
                    type="text"
                    className="form-control contact_box"
                    name="entry.1065046570"
                    required
                    placeholder="Subject"
                  />
                  <i className="fa fa-pencil fa-size"></i>
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control contact_box"
                    name="entry.839337160"
                    required
                    placeholder="Message"
                    rows="3"
                  ></textarea>
                </div>
                <div className="col-12 text-center mb-3">
                  <button className="btn btn-send" type="submit">
                    Send
                  </button>
                </div>
              </form>

              <div className="contact_us_social_media">
                <h4 className="contact_us_follow">Follow us on:</h4>
                <SocialIcon
                  url="https://facebook.com/huesofthemind"
                  target="_blank" rel="noopener noreferrer"
                  className="social_media_con"
                  bgColor="#dcdcdc"
                  fgColor="black"
                />
                <SocialIcon
                  url="https://www.instagram.com/huesofthemind"
                  target="_blank" rel="noopener noreferrer"
                  className="social_media_con"
                  bgColor="#dcdcdc"
                  fgColor="black"
                />
                <SocialIcon
                  url="https://twitter.com/huesofthemind"
                  target="_blank" rel="noopener noreferrer"
                  className="social_media_con"
                  bgColor="#dcdcdc"
                  fgColor="black"
                />
                <SocialIcon
                  url="https://www.youtube.com/channel/UCuUluSdKP5fBFQ_u05YtN7A"
                  target="_blank" rel="noopener noreferrer"
                  className="social_media_con"
                  bgColor="#dcdcdc"
                  fgColor="black"
                />
                <SocialIcon
                  url="https://www.linkedin.com/company/huesofthemind"
                  target="_blank" rel="noopener noreferrer"
                  className="social_media_con"
                  bgColor="#dcdcdc"
                  fgColor="black"
                />
                <SocialIcon
                  url="https://open.spotify.com/user/h7ld4jj12xupyccypyhzchiws?si=G6K5uXJ9QUOoKmXuFT4-Hw&dl_branch=1"
                  target="_blank" rel="noopener noreferrer"
                  className="social_media_con"
                  bgColor="#dcdcdc"
                  fgColor="black"
                />
              </div>
            </div>

            <div
              className="col-10 col-lg-6 col-md-8 col-sm-10 mx-auto titles"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <div className="row">
                <div className="mx-auto">
                  <img
                    src={contact2}
                    alt="..."
                    className="contactus_img img-fluid "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;