import React from "react";
const EventsCards = (props) => {
  return (
    <>
      <div className="col-lg-4 col-md-6 col-12 mx-auto">
        <div className="card events_cards h-100 text-center">
          <img
            src={props.eventcardimgsrc}
            className="card-img-top"
            alt={props.eventcardimgsrc}
          />
          <div className="card-body d-flex flex-column">
            <h5 className="card-title-events">{props.eventcardtitle}</h5>
            <h5 className="card-subtitle-events">{props.eventcardsubtitle}</h5>
            <hr />
            <p className="card-text-events">{props.eventcarddesc}</p>
            <div className="mt-auto">
              <a
                href={props.eventlinks}
                target="_blank"
                rel="noopener noreferrer"
                className="card-link-events"
              >
                Know more
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsCards;
