import React from "react";
import gaythri from "../../../src/images/gaythri1.png";
import dinky1 from "../../../src/images/dinky1.png";
import sanjana1 from "../../../src/images/sanjana1.png";
import nikitha from "../../../src/images/nikitha.png";
import divyac from "../../../src/images/divyac.png";
import osh from "../../../src/images/osh.png";
import pakhi from "../../../src/images/pakhi.png";
import manasi1 from "../../../src/images/manasi1.png";
import kalika1 from "../../../src/images/kalika1.png";
import hrushita from "../../../src/images/hrushita.png";
import rhea from "../../../src/images/rhea.png";
import lakshita from "../../../src/images/lakshita.png";
import shristihota from "../../../src/images/shristihota.png";
import shristi from "../../../src/images/shristi.png";
import { SocialIcon } from "react-social-icons";
import "./style.css";
import AuthorReadmore from "./AuthorReadmore";
const Author = () => {
  return (
    <>
      <div className="author_div">
        <div className="container">
          <div className="col-lg-10 col-md-10 col-sm-11 col-11 mx-auto text-center">
            <h3 className="author_title">Meet The authors </h3>
          </div>
          <div className="row  car_hide">
            <div className="col-12">
              <div className="top-content">
                <div
                  id="carouselExampleIndicators2"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#carouselExampleIndicators2"
                      data-slide-to="0"
                      className="active"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators2"
                      data-slide-to="1"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators2"
                      data-slide-to="2"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators2"
                      data-slide-to="3"
                    ></li>
                    <li
                      data-target="#carouselExampleIndicators2"
                      data-slide-to="4"
                    ></li>
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="row author_cards">
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_author">
                            <img className="img-fluid" alt="..." src={dinky1} />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_author_title">
                                Dinky Tejwani
                              </h4>
                              <hr />
                              <p className="card-text card_atext">
                                I always try to remind myself that even though
                                flowers and fairy lights are nothing alike, they
                                are still uniquely beautiful.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.instagram.com/inkyydinky/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_author">
                            <img className="img-fluid" alt="..." src={divyac} />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_author_title">
                                Divya Chaturvedi
                              </h4>
                              <hr />
                              <p className="card-text card_atext">
                                "The future belongs to those who believe in the
                                beauty of their dreams." -Eleanor Roosevelt
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/divya-chaturvedi-841634203/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_author">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={gaythri}
                            />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_author_title">
                                Gayathri Vijay
                              </h4>
                              <hr />
                              <p className="card-text card_atext">
                              You know you’re healing when one of two things happens— you don’t feel the urge to look back, or you look back at your past with fondness rather than regret.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/gayathri-vijay-3a6766a4/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row author_cards">
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_author">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={hrushita}
                            />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_author_title">
                                Hrushitaa Murali
                              </h4>
                              <hr />
                              <p className="card-text card_atext">
                                Give yourself the power to choose to be
                                vulnerable only in front of those you like, not
                                those who like you.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/hrushitaa/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_author">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={kalika1}
                            />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_author_title">
                                Kalika Aloni
                              </h4>
                              <hr />
                              <p className="card-text card_atext">
                                Embrace your flaws, imperfection is beautiful.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.instagram.com/a.foxwithsocks/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_author">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={lakshita}
                            />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_author_title">
                                Lakshita Gupta
                              </h4>
                              <hr />
                              <p className="card-text card_atext">
                                In all chaos there is a cosmos, in all disorder
                                a secret order.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/lakshita-gupta-1b5a701a0/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row author_cards">
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_author">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={manasi1}
                            />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_author_title">
                                Manasi Gupta
                              </h4>
                              <hr />
                              <p className="card-text card_atext">
                                Empathy, consistency and courage are the biggest
                                instruments of change.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/manasichangemaker/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_author">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={nikitha}
                            />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_author_title">
                                Nikhitha Yeturu
                              </h4>
                              <hr />
                              <p className="card-text card_atext">
                                There's only one way I wanna live : To
                                completely embrace who I am and fly with pride.
                                Let myself be free, unapologetic and wild.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/nikhitha-yeturu-8689251aa/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_author">
                            <img className="img-fluid" alt="..." src={osh} />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_author_title">
                                Oishika Sah
                              </h4>
                              <hr />
                              <p className="card-text card_atext">
                                There's so much to learn, love, and most
                                importantly, to live.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/oishika-sah-827ba51ba/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row author_cards">
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_author">
                            <img className="img-fluid" alt="..." src={pakhi} />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_author_title">
                                Pakhi Dubey
                              </h4>
                              <hr />
                              <p className="card-text card_atext">Where your focus goes, energy flows.</p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.instagram.com/dubeypakhi_11"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_author">
                            <img className="img-fluid" alt="..." src={rhea} />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_author_title">
                                Rhea Mathews
                              </h4>
                              <hr />
                              <p className="card-text card_atext">
                                In all chaos there is a cosmos, in all disorder
                                a secret order. - Carl Jung
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/rhea-mathews-aa7698112/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch">
                          <div className="card card_author">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={shristihota}
                            />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_author_title">
                                Srishti Hota
                              </h4>
                              <hr />
                              <p className="card-text card_atext">
                                I believe in Manifestation and the universe. I
                                also believe in being self-aware of my emotions
                                and beliefs, they help me tackle my mental
                                health issues.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/srishti-hota-461981199/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="row author_cards mx-auto">
                        <div className="col-md-4 mb-3 d-flex align-items-stretch mx-auto">
                          <div className="card card_author">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={shristi}
                            />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_author_title">
                                Shristi Pandey
                              </h4>
                              <hr />
                              <p className="card-text card_atext">
                                We’re all constellations but changing. We find
                                better fits, and on rare occasions we face
                                obstacles, but we’ll still align.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.linkedin.com/in/shristi-pandey-0387a3193/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3 d-flex align-items-stretch mx-auto" >
                          <div className="card card_author">
                            <img
                              className="img-fluid"
                              alt="..."
                              src={sanjana1}
                            />
                            <div className="card-body d-flex flex-column">
                              <h4 className="card-title card_author_title">
                                Sanjana Srinivasan
                              </h4>
                              <hr />
                              <p className="card-text card_atext">
                                Sometimes it is the people no one imagines
                                anything of who do the things that no one can
                                imagine.
                              </p>
                              <div className="mt-auto">
                                <SocialIcon
                                  url="https://www.instagram.com/s_sanjana246/"
                                  className="social_media"
                                  style={{
                                    height: 35,
                                    width: 35,
                                    marginRight: 8,
                                  }}
                                  bgColor="#12356b"
                                  fgColor="white"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    className="carousel-control-prev"
                    href="#carouselExampleIndicators2"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#carouselExampleIndicators2"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <AuthorReadmore />
        </div>
      </div>
    </>
  );
};
export default Author;
