import React from "react";
import Faqs from "./Faqs";
import HelpYourselfHead from "./HelpYourselfHead";
import { Helmet } from "react-helmet-async";
const HelpYourself = () => {
  return (
    <>
      <Helmet>
        <title>Help Yourself | Mental Health FAQ's | Huesofthemind </title>
        <meta name="description" content="Learn with HOTM about the broad spectrum of mental health. Read FAQ's about different disorders such aS PSTD, Personality disorders and know the stories of other people. This is a safe space. "/>
        <link rel="canonical" href="https://huesofthemind.live/helpyourself" />
        <meta name="keywords" content="faq's, cope with hotm, learn with hotm, safepace, pstd, personality disorders, mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown" />
      </Helmet>
      <HelpYourselfHead />
      <Faqs />
    </>
  );
};
export default HelpYourself;