import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Popularinfo from "./Popularinfo";
import PopularCards from "./PopularCards";
const PopularEvents = () => {
  return (
    <>
     
      <div className="popularevents_div">
         <div className="row">
           <div className="col-10 col-lg-10 mx-auto">
             <h1 className="popularevents_heading">POPULAR EVENTS</h1>
             <p className="popular_events_desc">
               Some people have called our events Life-changing, and that has
               truly made all the difference in our lives!
               <br /> Have a look at our brightest hues.
             </p>
           </div>

           <div className="col-10 col-lg-9 mx-auto">
          <Carousel infiniteLoop autoPlay interval="18000" showThumbs={false} showStatus={false} showIndicators={false}>
            {Popularinfo.map((val, ind) => {
              return (
                <PopularCards
                  key={ind}
                  popularimgsrc={val.popularimgsrc}
                  populartitle={val.populartitle}
                  popularsubtitle={val.popularsubtitle}
                  populardesc={val.populardesc}
                  popularlink={val.popularlink}
                />
              );
            })}
          </Carousel>
        </div>
      </div>
      </div>
    </>
  );
};
export default PopularEvents;
