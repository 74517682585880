import React from "react";
import AboutPara from "./AboutPara";

const HeaderAbout = () => {
  return (
    <>
      <div className="abouthead_div">
        <div className="row">
          <div className="col-8 col-sm-8 col-md-8 col-lg-7">
              <h1 className="abt_heading">WHERE EMPATHY IS YOUR POWER</h1>
              <p className="abt_para1">Huesofthemind is a non-profit organization run by progressive youth, where we try to have more conversations related to mental health to spread awareness and reduce the stigma around it.</p>
          </div>
        </div>
      </div>
   <AboutPara />
    </>
  );
};
export default HeaderAbout;
