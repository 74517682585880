import React from "react";
import hands from "../../images/hands.png";
const Workshops = () => {
  return (
    <>
      <div className="workshops_div">
        <div className="row">
          <div className="col-10 col-sm-11 col-md-12 col-lg-10 mx-auto">
            <h1 className="workshop_heading">THROUGH OUR WORKSHOPS AND CAMPAIGNS</h1>
            <div className="container-fluid col-10 col-md-12 col-lg-12 mx-auto">
              <div className="row">
                <div className="col-xs-10 col-sm-10 col-md-4 col-lg-4 col-xl-4 mx-auto" style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <p className="w_para">We aspire to create</p>
                  <p className="w_para1">a safe space<br />
                  &<br />
                 encourage inclusivity<br /></p>
                </div>
                <div className="col-xs-10 col-sm-10 col-md-4 col-lg-4 col-xl-4 mx-auto text-center" style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <img src={hands} className="hands_img" alt="..." />
                </div>
                <div className="col-xs-10 col-sm-10 col-md-4 col-lg-4 col-xl-4 mx-auto" style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <p className="w_para2">We share resources for</p>
                  <p className="w_para3">wellbeing<br />
                  &<br />
                  mental wellness<br /></p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-10 col-lg-8 mx-auto">
              <p className="workshops_para4">We make resources available and more affordable
and act as a tool for Self-help, especially amongst children and the youth</p>
</div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Workshops;
