import React from "react";
import { Helmet } from "react-helmet-async";
const Adhd = () => {
  return (
    <>
      <Helmet>
        <title>What is attention deficit hyperactivity disorder(ADHD)? | Huesofthemind</title>
        <meta name="description" content="ADHD - Attention Deficit Hyperactivity Disorder. A person with ADHD has differences in brain development and brain activity that affect attention, the ability to sit still, and self-control. Check out FAQ's related to ADHD" />
        <link rel="canonical" href="https://huesofthemind.live/helpyourself/learnwithhotm/adhd" />
        <meta name="keywords" content="adhd, Attention deficit hyperactivity disorder,mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown" />
      </Helmet>
      <div className="adhd_div">
        <div className="row">
          <div className="col-10 col-lg-8 mx-auto text-center">
            <h1 className="adhd_title">ADHD</h1>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item acc_items_adhd">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button accordion-toggle-adhd"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    What is it?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="answer_adhd">
                      ADHD stands for attention deficit hyperactivity disorder. It is a medical condition. A person with ADHD has differences in brain development and brain activity that affect attention, the ability to sit still, and self-control. ADHD can affect a child at school, at home, and in friendships.⁣
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item acc_items_adhd">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed accordion-toggle-adhd"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Can it be cured?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headi  ngTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="answer_adhd">
                      There's no known cure for ADHD, but several options can help your child manage their symptoms. Treatments range from behavioural intervention to prescription medication.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item acc_items_adhd">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed accordion-toggle-adhd"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    What medical assistance is required?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="answer_adhd">
                      ADHD can be treated using medication or therapy, but a combination of both is often best. Treatment is usually arranged by a specialist, such as a paediatrician or a psychiatrist, although the condition may be monitored by your GP.⁣
                      Medications can affect children differently and can have side effects such as decreased appetite or sleep problems. One child may respond well to one medication, but not to another.⁣
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item acc_items_adhd">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed accordion-toggle-adhd"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    How should we approach someone with the same?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="answer_adhd">
                      • Be an acceptor and a good listener: In various follow-up studies of children with ADHD into adulthood, researchers working independently of each other discovered something: Adults with ADHD who felt they were doing pretty well always had someone who accepted them for who and what they were.⁣<br />
                      • Be a support team member: Adults with ADHD don’t just need professionals to diagnose, treat, and support them through the difficulties they experience in trying to change themselves and their lives for the better. They also need people who understand they are struggling to cope with a neurodevelopmental disability and who show some compassion for their struggles.⁣<br />
                      • Become an advocate: Sometimes your loved one may need help in explaining what ADHD is to others in and outside of your family. Or they could use your assistance in defending against the callous or ignorant opinions of others.⁣<br />
                      • Become a benefactor. This is not a role most relatives, partners, or close friends can play. It involves having the financial resources to be able to help pay for constructive things your loved one wishes to do.<br />

                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item acc_items_adhd">
                <h2 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed accordion-toggle-adhd"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    How to know if you have ADHD?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="answer_adhd">
                      If you associate with the symptoms of ADHD, then it’s a possibility that you might have ADHD. Adult ADHD symptoms may include:<br />
                      • Impulsiveness.<br />
                      • Disorganization and problems prioritizing.<br />
                      • Poor time management skills.<br />
                      • Problems focusing on a task.<br />
                      • Trouble multitasking.<br />
                      • Excessive activity or restlessness.<br />
                      • Poor planning.<br />
                      • Low frustration tolerance.<br />

                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item acc_items_adhd">
                <h2 className="accordion-header" id="headingSix">
                  <button
                    className="accordion-button collapsed accordion-toggle-adhd"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Consultation
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p className="answer_adhd">
                      No official helpline has been set up yet, however you can always seek help from trained medical professionals in all hospitals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
};
export default Adhd;
