import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Huesofmembersinfo from "./Huesofmembersinfo";
import HuesofmembersCards from "./HuesofmembersCards";
import "./cardark.css";
const Huesofmembers = () => {
  return (
    <>
       <div className="huemem_div">
       <div className="container">
         <h1 className="huemem_title">HUES of our members</h1>
          <Carousel className="car_dark"
            infiniteLoop
            autoPlay interval="18000"
            showThumbs={false}
            showStatus={false}
          >
            {Huesofmembersinfo.map((val, ind) => {
              return (
                <HuesofmembersCards
                  key={ind}
                  huememimg={val.huememimg}
                  huememtext={val.huememtext}
                  huememname={val.huememname}
                  huememdesc={val.huememdesc}
                />
              );
            })}
          </Carousel>
        </div>
      </div>
    </>
  );
};
export default Huesofmembers;
