import React from "react";
import { SocialIcon } from "react-social-icons";
const Sahaaracards = (props) => {
  return (
    <>
      <div className="col-md-4 mb-3 d-flex align-items-stretch">
        <div className="card card_sahaara">
          <img className="img-fluid" alt="..." src={props.simg} />
          <div className="card-body d-flex flex-column">
            <h4 className="card-title card_sahaara_title">{props.sname}</h4>
            <h6 className="card_subtitle_sahaara">{props.ssub}</h6>
            <hr />
            <p className="card-text card_stext">
              {props.stext}
            </p>
            <div className="mt-auto">
              <SocialIcon
                url={props.salink}
                className="social_media"
                style={{
                  height: 35,
                  width: 35,
                  marginRight: 8,
                }}
                bgColor="#12356b"
                fgColor="white"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sahaaracards;
