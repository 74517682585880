import React from "react";
import SearchedCard from "./SearchedCard";
const SearchedEventslist = ({ filteredPersons }) => {
    const filtered = filteredPersons.map( person =>  
    <SearchedCard key={person.id} person={person} />); 
    return (
      <>
        {filtered}
      </>
    );
};
export default SearchedEventslist;
