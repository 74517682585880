import React from 'react';
const Volunteering = () => {
    return (
        <>
         <div className="volunteer">
        <div className="col-lg-8 col-md-10 col-10 mx-auto text-center">
        <h1 className="volunteer_title">BE A PART OF THE CHANGE</h1>
          <p className="volunteer_para">
          If you are committed to eliminating the stigma surrounding mental illness through awareness and education, we would be more than happy to collaborate with you.
          </p>
          <a href="https://forms.gle/exqaYhnuc11NyJer5" target="_blank" rel="noopener noreferrer" className="btn btn-volunteering text-center">
            Join the Team
          </a>
        </div>
      </div>   
        </>
    )
}
export default Volunteering;
