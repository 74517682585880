import React from "react";
const ParticipantsCards = (props) => {
  return (
    <>
      <div className="card h-100 participantscard_div">
        <div className="row">
          <div
            className="col-lg-6 col-md-10 mx-auto"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <div className="card-body">
              <p className="card-text-participants">
                {props.partext}
              </p>
              <p className="card-text-participants1">Love and Regards</p>
              <p className="card-text-participants2">{props.parname}</p>
              <p className="card-text-participants3">
                {props.pardesc}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ParticipantsCards;
