import React from "react";
import selftalk from "../../images/selftalk.png";
const Selftalk = () => {
  return (
    <>
      <div className="selftalk_div">
        <h1 className="selftalk_title">SELF TALK</h1>
        <div className="row">
          <div className="col-12 col-lg-11 mx-auto d-flex flex-dir-nt">
            <div className="col-10 col-lg-6 col-md-8 col-sm-10 mx-auto text-center" style={{marginTop: "auto", marginBottom:"auto"}}>
              <img src={selftalk} className="selftalk_img" alt="..." />
            </div>
            <div className="col-10 col-lg-5 col-md-8 col-sm-8 mx-auto">
              <p className="selftalk_desc">
                How we talk to ourselves matters. What are you saying to
                yourself? it makes all the difference.⁣
                <br />
                <br />
                ⁣Are you saying mean things to yourself? that is going to
                reflect and result in self doubt, low self esteem and under confidence. It can also lead to you being negative. Our thoughts and emotions are contagious. They reflect themselves in our
                relationships too, hence our thought cycle has more impact than
                you might feel.⁣
                <br />
                <br />
                ⁣Affirmations help a lot. Say something nice to yourself aloud
                and see how maybe a hint of smile appears on your face.
                <br />
                <br />
                Be kind to yourself, treat yourself non judgmentally and
                respectfully. It’s okay to make mistakes, don’t be so hard on
                yourself if you make any, just learn from them.😃 ⁣<br />
                <br />
                ⁣Criticism is important but the next time you observe yourself
                going into a spiral of ⁣negative self talk, stop! take a deep
                breath and tell yourself you’re amazing. even these little
                changes lead to big results. Let’s be kinder to ourselves, let’s
                love ourselves a little more. 🌼⁣
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Selftalk;
