const Participantsinfo = [
    {
        partext: "The workshop was amazing! All the activities really helped me to gain insights on my feelings and understanding them. The activities that were scheduled were really to the point.",
        parname: " Anonymous",
        pardesc: "Coping with Uncertainty, Girl up Sydney"
    },
    {
        partext: "The workshop was soooo amazinggg, loved the space! I liked the part when we had to write about things, people we like, the most!",
        parname: "Karuna Tuladhar",
        pardesc: "Wellbeing at Work, AIESEC in Nepal"
    },
    {
        partext: "This was one of the best memories of mine during this pandemic & I'll forever be in touch with this organization. The organisers were so amazing and arranged it so well. Honestly speaking, this inspired me a lot. ",
        parname: "Rituparna Sukla Baidya",
        pardesc: "Psychology student | Participant, Empower"
    },
    {
        partext: "I found the session very endearing, it helped me in looking at life in a new vivid aspect and the tasks were fun :)",
        parname: "Manyata yadav",
        pardesc: "Participant, Soulful"
    },
    {
        partext: "This session not only made me feel better about myself but also gave me a boost of confidence, something that I believe everyone needs during the pandemic. I felt like I should make more attempts to know myself after this session.",
        parname: "Anurang Phad",
        pardesc: "Participant, Exploring yourself via Writing"
    },
    {
        partext: "I was relieved and just felt comfortable and light- headed. I learnt such a beautiful exercise today!",
        parname: "Akhil Tripathi",
        pardesc: "Participant, Expressive Arts"
    },  
    {
        partext: "I always feel I am a little adamant in expressing, I want to share but somehow I don't, but by attending these kind of sessions I think I gain confidence to speak up and share.",
        parname: "Sukriti Sanawar",
        pardesc: "Participant, Exploring yourself via Writing"
    },
    {
        partext: "The session was very calming and relaxing. I got to learn so much about myself.  The interactions were the best part. It was not at all a like a regular session as it was so interactive and so enlightening. This session will go a long way for me, & will help me improve my mental health.",
        parname: "Anonymous",
        pardesc: "Participant, Coping with Uncertainty"
    },
]
export default Participantsinfo;