import React from "react";
const Feedbackbookcards = (props) => {
  return (
    <>
      <div className="card h-100 feedbackbookcard_div">
        <div className="row">
          <div
            className="col-lg-6 col-md-10 mx-auto"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <div className="card-body">
              <p className="card-text-feedbackbook">
                {props.feedtext}
              </p>
              <p className="card-text-feedbackbook2">{props.feedname}</p>
              <p className="card-text-feedbackbook3">
                {props.feeddesc}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Feedbackbookcards;
