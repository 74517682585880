import React from "react";
import Head from "./Head";
import PopularEvents from "./PopularEvents";
import EventsList from "./EventsList";
import Participants from "./Participants";
import Collaboratewithus from "../Home/Collaboratewithus";
import Eventsdetails from "./Eventsdetails";
import Deliveredworkshops from "./Deliveredworkshops";
import { Helmet } from "react-helmet-async";
const Events = () => {
  return (
    <>
      <Helmet>
        <title>Events | Huesofthemind</title>
        <meta name="description" content="Our workshops, camps, events & conferences are a celebration of our journey with mental health."/>
        <meta name="keywords" content="mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown, mental health workshops, mental health events" />
        <link rel="canonical" href="https://huesofthemind.live/events" />
      </Helmet>
      <Head details={Eventsdetails} />
      <PopularEvents />
      <EventsList />
      <Participants />
      <Deliveredworkshops />
      <Collaboratewithus />
    </>
  );
};
export default Events;
