import prideforall from "../../../src/images/prideforall.PNG";
import harekfriend from "../../../src/images/harekfriend.png";
import easy from "../../../src/images/takeiteasy.png";
import gala from "../../../src/images/gala.png";
import celebrateuncertainty from "../../../src/images/celebrateuncertainty.png";
import art from "../../../src/images/art.png";
import twitter from "../../../src/images/twitter.png";
const Campaignsinfo = [
  {
    eventcardimgsrc: twitter,
    eventcardtitle: "Twitter Spaces",
    eventcardsubtitle: "2022",
    eventcarddesc:
      "We facilitated multiple Twitter spaces on topics ranging from coping with placement season, fear of missing out and nurturing relationships to empower one another in our journey of mental well-being.",
    eventlinks: "https://twitter.com/huesofthemind",
  },
  {
    eventcardimgsrc: easy,
    eventcardtitle: "Taking It Easy",
    eventcardsubtitle: "6th December 2020",
    eventcarddesc:
      "This was an instagram live with Ansh Mehra where we had a very insightful discussion about ‘taking it easy’. We wanted to highlight how important it is to take a break and not be very hard on yourself.",
    eventlinks:
      "https://www.instagram.com/tv/CIdXXZuCgT7/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: art,
    eventcardtitle: "Art and Mental Health",
    eventcardsubtitle: "November 2020",
    eventcarddesc:
      "In collaboration with Omisha (Omkaraa), we organised a month-long campaign on art & its impact on mental health with multiple events, a giveaway and informative posts.",
    eventlinks:
      "https://www.instagram.com/p/CHp3GQ2hpc7/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: gala,
    eventcardtitle: "Gratitude Gala, Meraki",
    eventcardsubtitle: "October 2020",
    eventcarddesc:
      "We stirred conversations about Gratitude & Self Love with multiple mental health advocates and encouraged our community to care for themselves more.",
    eventlinks:
      "https://www.instagram.com/tv/CGxUyQqicWD/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: harekfriend,
    eventcardtitle: "Har ek Friend Zaroori Hota Hai",
    eventcardsubtitle: "2nd August 2020",
    eventcarddesc:
      "This wholesome session by Dr Noor explained how to support a friend when they reach out to us. This was followed by a virtual concert with Harsh. The next event was a Journaling activity with Pranavi and to finish was a surprise video titled “AREY, YAAR”.",
    eventlinks:
      "https://www.instagram.com/p/CDRPtnQhboX/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: prideforall,
    eventcardtitle: "Pride for All",
    eventcardsubtitle: "21st - 28th June 2020",
    eventcarddesc:
      "In collaboration with VIT Visual Bloggers Club and Team UMEED of AIESEC, this event was created in the spirit of the Pride month of June, to share stories of the amalgamation of power and love. Various speakers talked about dealing with homophobia and how to be a better ally to the LGBTQ+ movement.",
    eventlinks:
      "https://www.instagram.com/p/CBvy27wB0pq/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: celebrateuncertainty,
    eventcardtitle: "Celebrate uncertainty with Under25",
    eventcardsubtitle: "May 2020",
    eventcarddesc:
      "In a week-long campaign with Under25, we had challenges, events and more to cope with the uncertainty of the pandemic together, with an open platform for one to express their experiences and find comfort.",
    eventlinks:
      "https://www.instagram.com/p/B_b3oh7nt4n/?utm_source=ig_web_copy_link",
  },
];

export default Campaignsinfo;
