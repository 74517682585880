import React from "react";
import { Helmet } from "react-helmet-async";
const Ocd = () => {
  return (
    <>
          <Helmet>
        <title>What is OCD and How to seek help? | Huesofthemind</title>
        <meta name="description" content="Ocd is the feeling of constant need to maintain the symmetry of the environment. OCD causes the brain to get stuck on a particular thought or urge." />
        <link rel="canonical" href="https://huesofthemind.live/helpyourself/learnwithhotm/ocd" />
        <meta name="keywords" content="Obsessive - compulsive disorder, ocd,mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown" />
      </Helmet>

      <div className="ocd_div">
        <div className="row">
          <div className="col-11 col-sm-11 col-md-10 col-lg-10 mx-auto">
            <h1 className="ocd_heading">OCD</h1>
            <p className="ocd_desc">Excessively stressing the ritualised hand washing, showering or bathing yourself⁣, the unshakable feeling of the dishes not being clean enough⁣, the constant need for maintaining the symmetry of the environment⁣.</p><br /> 
            <p className="ocd_desc">
            It’s normal, on occasions, to go back and double-check if the stove’s turned off, or whether the things in your room are aligned properly, but if you suffer from obsessive-compulsive disorder (OCD), obsessive thoughts and compulsive behaviours become so consuming they interfere with your daily life.
            </p><br /> 
            <p className="ocd_desc">
            OCD causes the brain to get stuck on a particular thought or urge. While you don’t derive any sense of pleasure from performing these repetitive behaviours, they may offer some passing relief for the anxiety generated by the obsessive thoughts. ⁣ To resist your urges, here are a few tips you could keep in mind-⁣<br /> 
            • Identify your triggers- the thoughts or situations—that bring on your obsessions and compulsions. rate their intensities and record a list of the triggers you experience.⁣<br />
            • Try to resist your compulsion- the more you avoid them, the scarier they might feel. Confront some of your fears and slowly expose yourself to them.⁣<br /> 
            • Reach out for support- OCD support groups enable you to share your own experiences through effective reminders.⁣<br /> 
            • Effective lifestyle changes- try refocusing your mind when obsessive thoughts and compulsions arise.<br />
            </p><br /> 
            <p className="ocd_desc">
            Once people are more informed about OCD, it is easier to be supportive and understanding to those dealing with it. People with OCD usually tend to act compulsively due to guilt and low self-esteem. Therefore,<br /> 
            1. Talk with the person to reduce their levels of stress and anxiety.⁣<br /> 
            2. Encourage the person to seek professional help and medication; assist in contacting a therapist or a treatment program suitable for him/her.⁣<br /> 
            3. Treat the disorder as an easily treatable one, so that the person having OCD knows that is nothing to be embarrassed or ashamed of. also, if you are someone who had OCD in the past, try sharing your own experiences to break the stigma about such disorders.⁣<br /> 
            4. Be appreciative- acknowledge the small improvements that follow in the process.⁣<br /> 
            5. Patience is the key- living with a person with OCD might be difficult at times, but try your best to be patient and non-judgemental.<br />
            </p><br /> 
            <p className="ocd_desc">
            How to seek help? Treatment for most OCD patients should involve Exposure and Response Prevention (ERP) and/or medication. - done by a licensed mental health professional (such as a psychologist, social worker, or mental health counsellor) - medications can only be prescribed by licensed medical professionals, who would ideally work together with your therapist to develop a treatment plan.
            </p><br /> 
            <p className="ocd_disclaimer">
            Disclaimer: The information above is for awareness purposes and has been reviewed by Mental Health professionals. Please never diagnose yourself. Consult a professional for more details.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Ocd;
