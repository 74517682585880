import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Timeline1 from '../../images/Timeline1.png';
import Timeline2 from '../../images/Timeline2.png';
import Timeline3 from '../../images/Timeline3.png';
import Timeline4 from '../../images/Timeline4.png';
import Timeline5 from '../../images/Timeline5.png';
import Timeline6 from '../../images/Timeline6.png';
const Journey = () => {
  return (
    <>
      <div className="journey_div">
        <div className="container">
          <h1 className="journey_title">OUR JOURNEY</h1>
          <Carousel 
            infiniteLoop
            autoPlay interval="10000"
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
          >
            <div>
              <img src={Timeline1} alt="..." className="car-journey img-fluid "/>
            </div>
            <div>
              <img src={Timeline2} alt="..." className="car-journey img-fluid "/>
              </div>
            <div>
              <img src={Timeline3} alt="..." className="car-journey img-fluid "/>
            </div>
            <div>
              <img src={Timeline4} alt="..." className="car-journey img-fluid "/>
            </div>
            <div>
              <img src={Timeline5} alt="..." className="car-journey img-fluid "/>
              </div>
            <div>
              <img src={Timeline6} alt="..." className="car-journey img-fluid "/>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  );
};
export default Journey;
