import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Feedbackbookinfo from './Feedbackbookinfo';
import Feedbackbookcards from './Feedbackbookcards';
const Feedbackbook = () => {
  return (
    <>
      <div className="feedbackbook_div">
             <div className="container">
      <h1 className="feedbackbook_heading">HOW PEOPLE FIND HUES OF YOU</h1>
          <Carousel infiniteLoop autoPlay interval="14000" showThumbs={false} showStatus={false}>
            {Feedbackbookinfo.map((val, ind) => {
              return < Feedbackbookcards key={ind} 
                                feedtext={val.feedtext}
                                feedname={val.feedname}
                                feeddesc={val.feeddesc} />;
            })}
          </Carousel>
        </div>
      </div>
    </>
  );
};
export default Feedbackbook;
