import React from 'react';
import HeaderAbout from './HeaderAbout';
import Workshops from './Workshops';
import Huesofmembers from './Huesofmembers';
import Volunteering from './Volunteering';
import Sahaara from './Sahaara';
import Journey from './Journey';
import { Awards } from './Awards';
import { Helmet } from "react-helmet-async";
const About = () => {
  return (
    <>
      <Helmet>
        <title>About | Huesofthemind</title>
        <meta name="description" content="Huesofthemind is a non-profit organization run by progressive youth, where we try to have more conversations related to mental health to spread awareness and reduce the stigma around it." />
        <link rel="canonical" href="https://huesofthemind.live/about" />
      </Helmet>
      <HeaderAbout />
      <Journey />
      <Awards />
      <Workshops />
      <Sahaara />
      <Huesofmembers />
      <Volunteering />
    </>
  )
}
export default About;