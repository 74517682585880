import React from "react";
export const HuesLetters = () => {
  return (
    <>
    <div className="huesletters_div_outer">
      <div className="huesletters_div">
        <div className="huesletters_card_div">
          <div className="card huesletters_card">
            <div className="card-body">
              <h5 className="card-title huesletters_title_card">HUESLETTER</h5><hr />
              <p className="card-text huesletters_card_desc">
                Living a happier life might seem like a tall order, the
                mindfulness, the highs and lows, the inner happiness! ⁣<br /><br />
                But having something at your disposal, whenever and wherever you
                need it, makes it easier and more fun. With just a click away,
                we present to you our own HuesLetter!⁣
                <br /><br />
                Our HuesLetter has been created with awesome resources filled
                with tips, tricks, poetry, art, personal stories and so much
                more and has been baked with love, love and only love. ⁣<br /><br />
                It’s sure to inspire you on your journey to mental wellness and
                remind you to take a break.
                <br />
              </p>
              <div className="hl_buttons">
                <div>
              {/* <NavLink to="/helpyourself/cope/huesletters" className="btn btn-read_huesletters">  
                Read Huesletters
              </NavLink> */}
              <a href="https://us2.campaign-archive.com/home/?u=b3cd03e45a10a67bf4081b66f&id=0389f7a0b8" target="_blank" rel="noopener noreferrer" className="btn btn-read_huesletters">  
                Read HuesLetters
              </a>
              </div>
              <div>
              <a href="https://live.us2.list-manage.com/subscribe?u=b3cd03e45a10a67bf4081b66f&id=0389f7a0b8" target="_blank" rel="noopener noreferrer" className="btn btn-subscribe_huesletters">  
                Subscribe Now
              </a>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
export default HuesLetters;
