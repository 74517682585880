import React from 'react'
const PopularCards = (props) => {
    return (
        <>
           <div className="card h-100 popularevent_card">
                    <div className="row">
                      <div
                        className="col-md-5 text-center"
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                      >
                        <img
                          src={props.popularimgsrc}
                          alt="..."
                          className="card-img-popular img-fluid "
                        />
                      </div>
                      <div
                        className="col-md-7"
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                      >
                        <div className="card-body">
                          <h5 className="card-title">{props.populartitle}</h5>
                          <h6 className="card-subtitle">
                            {props.popularsubtitle}
                          </h6>
                          <hr />
                          <p className="card-text">
                           {props.populardesc}
                            <br />
                          </p>
                          <a href={props.popularlink} target="_blank" rel="noopener noreferrer" className="card-link">
                            Know more
                          </a>
                        </div>
                      </div>
                    </div>
                  </div> 
        </>
    )
}
export default PopularCards;