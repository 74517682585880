const Feedbackbookinfo = [
    {
        feedtext: "A beautiful and heart-warming book offering pragmatic advice and profound words of wisdom with prose that flows like poetry and poems that flow into colors. It's simultaneously contemporary and timeless! Must buy for a nugget of calm in a maddening life.",
        feedname: "Isha Gupta",
    },
    {
        feedtext: "The book encompassed a wide range of topics, and is a must-have self-help resource! The illustrations and colours of the book are absolutely marvelous. So glad to see young minds raising funds for such a noble cause.",
        feedname: "Rahul Gupta",
    },
    {
        feedtext: "Kudos to this young group of kids for such a fabulous collection of thoughts & stories! Its a good buy...",
        feedname: "Shalini",
        
    },
    {
        feedtext: "Beautiful content, feels like you lived it or living it. Even I loved the texture and colour of the book, feels soothing for eyes and skin :)",
        feedname: "Ash Jain",
        
    },
    {
        feedtext: "Lovely book... Wow, really enchanted and great read buy it without giving second thoughts..sums up how beautiful your life is in hues of pages....",
        feedname: "Reader",
    },  
    {
        feedtext: "Loved the poems and prose.So lovely and heartfelt.❤️ The Feel good kit section was an incredible idea and definitely a favourite! Also the design and aesthetic! Top notch!😍",
        feedname: "Anuja Pandey",
    },
    {
        feedtext: "Hues of You has become a companion to me. My mornings would only start after reading some pages with my favourite Chai. And this happens every day and night now. Flipping pages and reading parts of the book makes it better for me, always.",
        feedname: "Manan Kathuria",
    },
]
export default Feedbackbookinfo;