import React from "react";

const HuesofmembersCards = (props) => {
  return (
    <>
      <div className="card h-100 card_huemem_div">
        <div className="row">
          <div
            className="col-lg-5 col-md-12 mx-auto text-center"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <img
              src={props.huememimg}
              alt="..."
              className="card-img-huemem img-fluid "
            />
          </div>
          <div
            className="col-lg-7 col-md-12 mx-auto"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <div className="card-body">
              <p className="card-text-huemem">
                {props.huememtext}
              </p>
              <p className="card-text-huemem1">Love and Regards</p>
              <p className="card-text-huemem2">{props.huememname}</p>
              <p className="card-text-huemem3">
                {props.huememdesc}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HuesofmembersCards;
