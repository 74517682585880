import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Participantsinfo from './Participantsinfo';
import ParticipantsCards from './ParticipantsCards';
const Participants = () => {
  return (
    <>
      <div className="participants_div">
             <div className="container">
      <h1 className="participants_heading">What our participants have to say</h1>
          <Carousel infiniteLoop autoPlay interval="15000" showThumbs={false} showStatus={false}>
            {Participantsinfo.map((val, ind) => {
              return <ParticipantsCards key={ind} 
                                partext={val.partext}
                                parname={val.parname}
                                pardesc={val.pardesc} />;
            })}
          </Carousel>
        </div>
      </div>
    </>
  );
};
export default Participants;
