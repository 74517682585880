import expressive from "../../../src/images/ExpressiveArts.png";
import rainbow from "../../../src/images/Rainbow.png";
import easy from "../../../src/images/takeiteasy.png";
import soulful from "../../../src/images/soulful.png";
import empower from "../../../src/images/Empower2.png";
import heyme from "../../../src/images/heyme.png";
import baatein from "../../../src/images/baatein.png";
import pastels from "../../../src/images/pastels.png";
import panel from "../../../src/images/paneldiscussion.png";
import prideforall from "../../../src/images/prideforall.PNG";
import harekfriend from "../../../src/images/harekfriend.png";
import mhs from "../../../src/images/mhs.png";
import pursuit from "../../../src/images/pursuitofhappiness.png";
import dancetherapy from "../../../src/images/dancetherapy.png";
import mindfulminds from "../../../src/images/mindfulminds.png";
import spreadingsmiles from "../../../src/images/spreadingsmiles1.jpg";
import kind from "../../../src/images/kind.png";
import art from "../../../src/images/art.png";
import gala from "../../../src/images/gala.png";
import celebrateuncertainty from "../../../src/images/celebrateuncertainty.png";
import artofliving from "../../../src/images/artofliving.png";
import jab from "../../../src/images/jab.png";
import hug from "../../../src/images/hug.png";
import ea from "../../../src/images/ea.png";
import uncert from "../../../src/images/uncert.png";
import mentalhealthday from "../../../src/images/mentalhealthday.png";
import you2 from "../../../src/images/you2.png";
import stress from "../../../src/images/stress.png";
import twitter from "../../../src/images/twitter.png";
import work from "../../../src/images/work.png";
import evolve from "../../../src/images/Evolve.png";
const Eventsdetails = [
    {
        id: 1,
        eventcardimgsrc: expressive,
        eventcardtitle: "Expressive Arts Camp",
        eventcardsubtitle: "16th to 31st January 2021",
        eventcarddesc: "The camp comprised of 3 Workshops, four hours each, taken by certified coaches & therapists in the respective fields.⁣ 16 - 17 Jan: Dance and Movement Therapy⁣ by Aakriti Wadhwa 23 - 24 Jan: Expressive Arts - Storytelling, Poetry and Writing⁣, by Krthitika Umanath 30 - 31 Jan: Arts Therapy⁣ by Saanchi Saraff",
        eventlink: "https://www.instagram.com/p/CJGtUoCB1OA/?utm_source=ig_web_copy_link"
      },
      {
        id: 2,
        eventcardimgsrc: rainbow,
        eventcardtitle: "Rainbow Verse",
        eventcardsubtitle: "11th December 2020 ",
        eventcarddesc: "It was an event organised for the children of Ayuda NGO to help them understand the importance of mental health and wellbeing. We did activities like mindful meditation, journaling, deep breathing an d affirmations with the kids to teach them that there are a few tools/ activities that they can use to cope and feel better.",
        eventlink: "https://www.instagram.com/p/CQWCESRN3fi/?utm_source=ig_web_copy_link"
      },
      {
        id: 3,
        eventcardimgsrc: easy,
        eventcardtitle: "Taking It Easy",
        eventcardsubtitle: "6th December 2020",
        eventcarddesc: "This was an instagram live with Ansh Mehra where we had a very insightful discussion about ‘taking it easy’. We wanted to highlight how important it is to take a break and not be very hard on yourself.",
        eventlink: "https://www.instagram.com/tv/CIdXXZuCgT7/?utm_source=ig_web_copy_link"
      },
      {
        id: 4,
        eventcardimgsrc: soulful,
        eventcardtitle: "Soulful ‘Connecting with Yourself’",
        eventcardsubtitle: "28th November 2020",
        eventcarddesc: "Huesofthemind in association with Dr. Krithika & the community of Girlscript, presented an amazing workshop on self-expression, 'Soulful-connecting with yourself'.⁣ This event focused on the importance of storytelling/writing, prose and poetry in knowing & hence, expressing oneself better.⁣",
        eventlink: "https://www.instagram.com/p/CIBEb5NhdZ0/?utm_source=ig_web_copy_link"
      },
      {
        id: 5,
        eventcardimgsrc: empower,
        eventcardtitle: "Empower",
        eventcardsubtitle: "10th-11th October 2020",
        eventcarddesc: "EMPOWER- Where Empathy is your Power, was a two day conference comprising of a total of more than 10 events, spread across two days, on the occasion of World Mental Health Day.⁣⁣⁣ The event was based on the theme-Mental Health for all, all for Mental Health - a space for discussions, performances and activities for our mental health and well being.",
        eventlink: "https://www.instagram.com/p/CF7Q79hh04y/?utm_source=ig_web_copy_link"
      },
      {
        id: 6,
        eventcardimgsrc: mhs,
        eventcardtitle: "Mental Health Superheroes",
        eventcardsubtitle: "23rd February 2021",
        eventcarddesc: "In collaboration with the peer educators of the school, we curated and delivered a workshop to all the 4th and 5th graders of the school, impacting close to 1000 students. We curated a curriculum of multiple workshops, focussing on different & the most relevant aspects for each age group (for 3rd-12th grade).",
        eventlink: "https://www.instagram.com/p/CQWCESRN3fi/?utm_source=ig_web_copy_link"
      },
      {
        id: 7,
        eventcardimgsrc: heyme,
        eventcardtitle: "Hey Me, It’s Me",
        eventcardsubtitle: "9th December 2020 ",
        eventcarddesc: "This was an event we did for the freshers of DSC Bilaspur to bring their focus and attention to the importance of mental health and wellbeing. We did activities like gratitude journaling, mindful meditation, breathing techniques, affirmation writing, and making relationship cake with the students to make them practise these tools which they can use in their college life to cope through things and feel better.",
        eventlink: "https://www.instagram.com/p/CQF3w4pNGLn/?utm_source=ig_web_copy_link"
      },
      {
        id: 8,
        eventcardimgsrc: pursuit,
        eventcardtitle: "The Pursuit of Happiness",
        eventcardsubtitle: "25th May 2020",
        eventcarddesc: "⁣An interactive Google Meet regarding everyday struggles and finding stress coping mechanisms with Dr Noor Gill.",
        eventlink: "https://www.instagram.com/p/CAiz7Qynmg6/?utm_source=ig_web_copy_link"
      },
      {
        id: 9,
        eventcardimgsrc: dancetherapy,
        eventcardtitle: "Dance Therapy",
        eventcardsubtitle: "20th June 2020",
        eventcarddesc: "Huesofthemind together with Ms Aarohi, a clinical psychologist and Certified Creative Arts facilitator who has been teaching dance for 15 years, organized a wholesome therapeutic workshop using the idea of expressing your innermost emotions through dance and movement",
        eventlink: "https://www.instagram.com/p/CBhw1D-hx8Z/?utm_source=ig_web_copy_link"
      },
      {
        id: 10,
        eventcardimgsrc: mindfulminds,
        eventcardtitle: "Mindful Minds",
        eventcardsubtitle: "13th JUNE 2020",
        eventcarddesc: "A session with Ms Shalmali, a Mental Health Member at the International Youth Council by the United Nations, discussed various positive effects of Mindfulness, strategies to practice Mindfulness, Introspection, Self Affirmations, and how to stay Mindful during the Pandemic",
        eventlink: "https://www.instagram.com/p/CBQOTIGhMuY/?utm_source=ig_web_copy_link"
      },
      {
        id: 11,
        eventcardimgsrc: prideforall,
        eventcardtitle: "Pride for All",
        eventcardsubtitle: "21st - 28th June 2020",
        eventcarddesc: "In collaboration with VIT Visual Bloggers Club and Team UMEED of AIESEC, this event was created in the spirit of the Pride month of June, to share stories of the amalgamation of power and love. Various speakers talked about dealing with homophobia and how to be a better ally to the LGBTQ+ movement.",
        eventlink: "https://www.instagram.com/p/CBvy27wB0pq/?utm_source=ig_web_copy_link"
      },
      {
        id: 12,
        eventcardimgsrc: harekfriend,
        eventcardtitle: "Har ek Friend Zaroori Hota Hai",
        eventcardsubtitle: "2nd August 2020",
        eventcarddesc: "This wholesome session by Dr Noor explained how to support a friend when they reach out to us. This was followed by a virtual concert with Harsh. The next event was a Journaling activity with Pranavi and to finish was a surprise video titled “AREY, YAAR”.",
        eventlink: "https://www.instagram.com/p/CDRPtnQhboX/?utm_source=ig_web_copy_link"
      },
      {
        id: 13,
        eventcardimgsrc: baatein,
        eventcardtitle: "Baatein",
        eventcardsubtitle: "4th-5th of April 2020",
        eventcarddesc: "This wholesome and interactive event was the first event of Huesofthemind. In collaboration with Your Safe hour, we brought an online platform to have more conversations and provide people with a Safe space to talk, express and share.",
        eventlink: "https://www.instagram.com/p/B-XYP9ABn8v/?utm_source=ig_web_copy_link"
      },
      {
        id: 14,
        eventcardimgsrc: pastels,
        eventcardtitle: "Pastels",
        eventcardsubtitle: "24th April 2020",
        eventcarddesc: "It was an online art competition to help people experience the therapeutic quality of art during the uncertain times of COVID-19. The event was organised in collaboration with Dopamine Planet founded by Harshita.",
        eventlink: "https://www.instagram.com/p/B_XTNKKHQIZ/?utm_source=ig_web_copy_link"
      },
      {
        id: 15,
        eventcardimgsrc: panel,
        eventcardtitle: "Panel Discussion",
        eventcardsubtitle: "21st May 2020",
        eventcarddesc: "Theme: A panel discussion signifying the importance of Mental Health Awareness and Topics related to mental health and well being Partnering with IEEE-CAS, HOTM conducted Panel Discussion on tackling issues like stereotypes present in our media, lack of information on mental health issues in education, and stigmas surrounding the major issues.",
        eventlink: "https://www.instagram.com/p/CAZsUsbnGtc/?utm_source=ig_web_copy_link"
      },
      {
        id: 16,  
        eventcardimgsrc: spreadingsmiles,
        eventcardtitle: "Spreading Smiles",
        eventcardsubtitle: "7th October 2020",
        eventcarddesc: " This was an event organised for the children of Maher Ashram in collaboration with Juvenile Care. In this event, we did activities like meditation, gratitude journaling, affirmations and breathing exercises with the kids to help them understand the importance of mental health and well being. We wanted to teach them that these are some tools that they can use to feel better in their time of need.",
        eventlink: "https://www.instagram.com/p/CHX5nIxh6j6/?utm_source=ig_web_copy_link"
      },
      {
        id: 17,
        eventcardimgsrc: kind,
        eventcardtitle: "How to be kind to your mind",
        eventcardsubtitle: "26th July 2020",
        eventcarddesc: "In collaboration with Ms. Raashi Thakran, a Mental Health Advocate and a certified QPR Instructor, we at Huesofthemind, organised a session to focus on our mental well-being. The session included myths, thinking traps & suicide prevention resources.⁣",
        eventlinks: "https://www.instagram.com/p/CC76cu1hlMM/?utm_source=ig_web_copy_link"
      },
      {
        id:18,
        eventcardimgsrc: art,
        eventcardtitle: "Art and Mental Health",
        eventcardsubtitle: "November 2020",
        eventcarddesc: "In collaboration with Omisha (Omkaraa), we organised a month-long campaign on art & its impact on mental health with multiple events, a giveaway and informative posts.",
        eventlinks: "https://www.instagram.com/p/CHp3GQ2hpc7/?utm_source=ig_web_copy_link",
      },
      {
        id: 19,
        eventcardimgsrc: celebrateuncertainty,
        eventcardtitle: "Celebrate uncertainty with Under25",
        eventcardsubtitle: "May 2020",
        eventcarddesc: "In a week-long campaign with Under25, we had challenges, events and more to cope with the uncertainty of the pandemic together, with an open platform for one to express their experiences and find comfort.",
        eventlinks: "https://www.instagram.com/p/B_b3oh7nt4n/?utm_source=ig_web_copy_link",
      },
      {
        id: 20,
        eventcardimgsrc: gala,
        eventcardtitle: "Gratitude Gala, Meraki",
        eventcardsubtitle: "October 2020",
        eventcarddesc: "We stirred conversations about Gratitude & Self Love with multiple mental health advocates and encouraged our community to care for themselves more.",
        eventlinks: "https://www.instagram.com/tv/CGxUyQqicWD/?utm_source=ig_web_copy_link",
      },
      {
        id: 21,
        eventcardimgsrc: artofliving,
        eventcardtitle: "Coping with Uncertainty, Anxiety, and Stress",
        eventcardsubtitle: "April- June 2021",
        eventcarddesc: "We delivered 10+ workshops and webinars in collaboration with AIESEC, Rotaract, NLU and more, to raise awareness on the occasion of Mental Health Awareness month and to help people cope mentally with the worsening situation of the pandemic.",
        eventlinks: "https://www.instagram.com/p/CQF3w4pNGLn/?utm_source=ig_web_copy_link"
      },
      {
        id: 22,
        eventcardimgsrc: jab,
        eventcardtitle: "Jab we met (or not)",
        eventcardsubtitle: "30th May 2021",
        eventcarddesc: "A workshop facilitated by an expressive arts therapist & organized in collaboration with Raahe and Omkaraa to explore & understand dating patterns during the pandemic. It included multiple activities for visualization, introspection, and journaling.",
        eventlinks: "https://www.instagram.com/p/CPYBcKZt1Z4/?utm_source=ig_web_copy_link"
      },
      {
        id: 23,
        eventcardimgsrc: hug,
        eventcardtitle: "A virtual Hug",
        eventcardsubtitle: "May 2021",
        eventcarddesc: "To give people comfort and solace in the incredibly challenging times we organized 4 events throughout the month, ranging from a movie night, music & art sessions to live meditation. They were organized in collaboration with Omisha (Omkaara), Sarla Total, and multiple artists.",
        eventlinks: "https://www.instagram.com/tv/CPN-zlUC9Ud/?utm_source=ig_web_copy_link"
      },
      {
        id: 24,
        eventcardimgsrc: ea,
        eventcardtitle: "Expressive Arts",
        eventcardsubtitle: "22 May 2020",
        eventcarddesc: "With special guest Mauree Pahuja, a fun and nourishing Art Session was held encouraging the audience to take part in the process of Expressive Art Therapy as ''The process and journey of the creation is more important and emphasized than the final product.'' This event saw the use of color and nature in art with a therapeutic result.",
        eventlinks: "https://www.instagram.com/tv/CPN-zlUC9Ud/?utm_source=ig_web_copy_link"
      },
      {
        id: 25,
        eventcardimgsrc: uncert,
        eventcardtitle: "Coping with Uncertainty and Anxiety",
        eventcardsubtitle: "April 2022",
        eventcarddesc:"Along with Girl Up Sydney, we shared self-help resources with the students of SP Jain to cope with upcoming exam stress, anxiety and the uncertainty revolving around it all.",
        eventlinks:"https://www.linkedin.com/posts/girl-up-sydney_uncertainty-wellbeing-mentalhealth-activity-6918822345641975808-nPTP?utm_source=linkedin_share&utm_medium=ios_app",
      },
      {
        id: 26,
        eventcardimgsrc: work,
        eventcardtitle: "Mental health in the workspace",
        eventcardsubtitle: "March 2022",
        eventcarddesc: "In our debut workshop in Nepal, in collaboration with AIESEC in Nepal, we shared resources for young professionals to be more self-aware and better decision-makers in their workspace.",
        eventlinks: "https://www.instagram.com/aiesec_nepal/",
      },
      {
        id: 27,
        eventcardimgsrc: stress,
        eventcardtitle: "Expressing our Stress ",
        eventcardsubtitle: "February 2022",
        eventcarddesc: "In this global workshop with the University of Nairobi, Kenya, we had conversations about coping with anxiety, stress, and uncertainty using various Expressive Arts tools.",
        eventlinks:"https://www.instagram.com/p/CXGwRF-KHWo/?utm_source=ig_web_copy_link",
      },
      {
        id: 28,
        eventcardimgsrc: twitter,
        eventcardtitle: "Twitter Spaces",
        eventcardsubtitle: "2022",
        eventcarddesc:"We facilitated multiple Twitter spaces on topics ranging from coping with placement season, fear of missing out and nurturing relationships to empower one another in our journey of mental well-being.",
        eventlinks: "https://twitter.com/huesofthemind",
      },
      {
        id: 29,
        eventcardimgsrc: you2,
        eventcardtitle: "Creating Inclusive and Safe Spaces",
        eventcardsubtitle: "December 2021",
        eventcarddesc:"In collaboration with AIESEC in India, we encouraged leaders to create inclusive and safe spaces for sharing. We shared the key elements of these non-judgmental spaces",
        eventlinks:"https://www.instagram.com/p/CQv4iKmLHJp/?utm_source=ig_web_copy_link",
      },
      {
        id: 30,
        eventcardimgsrc: mentalhealthday,
        eventcardtitle: "University-specific Workshops",
        eventcardsubtitle: "September- December 2021",
        eventcarddesc:"We raised awareness in 10+ universities of India, including Indraprastha University, Delhi University, NIFT Mumbai, Manipal University (LoT), ISME-ATLAS, and different chapters of AIESEC, SIAM to encourage emotional regulation and share tools of positive psychology.",
        eventlinks: "https://youtu.be/iPFRfe0b-vg",
      },
      {
        id: 31,
        eventcardimgsrc: evolve,
        eventcardtitle: "EVOLVE Conclave",
        eventcardsubtitle: "22nd May 2022",
        eventcarddesc: "The three aspects of the conclave, i.e. Empower, Empathize, Elevate, are focussed on building self-awareness for leadership development, traversing through a path of reflection, expression and growth. The conclave sensitizes, educates and nurtures empathy in all levels of leadership by creating a safe space, encouraging everyone to become a better version of themselves.",
        eventlinks: "https://www.instagram.com/p/CdQo3wavdLJ/?utm_source=ig_web_copy_link"
      },
]
export default Eventsdetails;