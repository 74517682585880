import React from 'react';
import { Helmet } from "react-helmet-async";
const EventForm = () => {
  return (
    <>
      <Helmet>
        <title>Event in your Organisation | Huesofthemind </title>
        <meta name="description" content="Invite Huesofthemind to deliver a workshop!. There is still a huge stigma around mental illness and we need to banish it. We can only do this through the use of structured experiences, group discussions and interactions.

" />
        <link rel="canonical" href="https://huesofthemind.live/organizeworkshop" />
        <meta name="keywords" content="hues of the mind, mental health, mental wellbeing, mental wellness, self help, mental health advocate, theapy, therapists, counsellor, psychologist, safe space, breathing exercises, anxiety, depression, mental health stories, mental health newsletter, support group, stress management, cope with stress, mental disorder, mental health podcast, breathing methods, ocd, mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown" />
      </Helmet>
      <div className="forms-div">
        <iframe title="Event" src="https://docs.google.com/forms/d/e/1FAIpQLSegWhhWss-TkB53OB7Uxl5Hpk_hD_2Ay881XIUlIyQt8xQABA/viewform?embedded=true" width="100%" height="1500" className="forms-height" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
      </div>
    </>
  );
};

export default EventForm;
