import React from "react";
import { Helmet } from "react-helmet-async";
const Lgbtq = () => {
  return (
    <>
         <Helmet>
        <title>What is LGBTQ? Let's educate people more about it. | Huesofthemind</title>
        <meta name="description" content="LGBTQ - The acronym for “lesbian, gay, bisexual, transgender and queer.” Find out about more terms and definitions here. Let’s break the ice and talk about it." />
        <link rel="canonical" href="https://huesofthemind.live/helpyourself/learnwithhotm/lgbtq" />
        <meta name="keywords" content="lgbtq, gay, esbian, bisexual, transgender and queer,mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown" />
      </Helmet>
      <div className="lgbtq_div">
        <div className="row">
          <div className="col-11 col-sm-11 col-md-10 col-lg-10 text-center mx-auto">
            <h1 className="lgbtq_title">LGBTQ+</h1>
            <p className="lgbtq_para">
              We’ve seen members of the LGBTQ community face a lot of emotional
              trauma and backlash just because of our ignorance. <br />
              Let’s educate ourselves and let’s get started with the basics.⁣
              <br />⁣<br />
              LGBTQ: The acronym for “lesbian, gay, bisexual,
              transgender and queer.” Some people also use the Q to stand for
              "questioning," meaning people who are figuring out their sexual
              orientation or gender identity. You may also see LGBT+, LGBT*,
              LGBTx, or LGBTQIA. I stands for intersex and A for
              asexual/aromantic/agender. The "A" has also been used by some to
              refer to "ally.”⁣⁣
              <br />⁣<br />
              ⁣Speaking of intersex : Born with sex characteristics such as
              genitals or chromosomes that do not fit the typical definitions of
              male or female. About 1.7% of the population is intersex,
              according to the United Nations.⁣⁣
              <br />⁣<br />
              ⁣Sex: The biological differences between male and female. Sexual
              orientation: How a person characterizes their sexuality-⁣⁣
              <br />
              Gay: A sexual orientation that describes a person who is
              emotionally or sexually attracted to people of their gender;
              commonly used to describe men.⁣⁣
              <br />
              Lesbian: A woman who is emotionally or sexually attracted to other
              women.⁣⁣
              <br />
              Bisexual: A person who is emotionally or sexually attracted to
              more than one sex or gender.⁣⁣
              <br />
              Pansexual: A person who can be attracted to all different kinds of
              people, regardless of their biological sex or gender identity. ⁣⁣
              <br />
              Asexual: A person who experiences no sexual attraction to other
              people.⁣⁣
              <br />
              Demisexual: Someone who doesn't develop a sexual attraction to
              anyone until they have a strong emotional connection.⁣⁣
              <br />⁣<br />
              Gender: The societal constructions we assign to male and female.
              When you hear someone say "gender stereotypes," they're referring
              to the ways we expect men/boys and women/girls to act and behave.
              How we express our own gender identity is something very personal
              and varies from person to person. Here is something to reflect
              upon.⁣
              <br />
              Transgender: A person whose gender identity differs from the sex
              they were assigned at birth.⁣⁣
              <br />
              Cisgender: A person whose gender identity aligns with the sex they
              were assigned at birth.⁣⁣
              <br />
              ⁣<br />
              Binary: The concept of dividing sex or gender into two clear
              categories. Sex is male or female, gender is masculine or
              feminine.⁣⁣⁣
              <br />
              Non- binary: Someone who doesn't identify exclusively as
              female/male.⁣⁣⁣
              <br />
              Gender Queer: People who reject static, conventional categories of
              gender and embrace fluid ideas of gender (and often sexual
              orientation). They are people whose gender identity can be both
              male and female, neither male nor female, nor a combination of
              male and female.⁣⁣⁣
              <br />
              Agender: someone who doesn’t identity oneself as a particular
              gender.⁣⁣⁣
              <br />
              Gender - Expansive: An umbrella term used to refer to people,
              oftentimes youth, who don't identify with traditional gender
              roles.⁣⁣⁣
              <br />
              Gender - fluid: Not identifying with a single, fixed gender. A
              person whose gender identity may shift. ⁣ ⁣⁣
              <br />⁣<br />
              Let’s educate and remind ourselves, let’s do our part.⁣
              <br />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Lgbtq;
