import empower from "../../../src/images/Empower.png";
import evolve from "../../../src/images/Evolve.png";
import spreadingsmiles from "../../../src/images/spreadingsmiles.png";
import shell from "../../../src/images/shell.png";
import earthsake from "../../../src/images/earthsake.png";
import oneyoung from "../../../src/images/oneyoung.png";

const Popularinfo = [
  {
    popularimgsrc: oneyoung,
    populartitle: "One Young World, Manchester",
    popularsubtitle: "September 2022",
    populardesc:
      "We delivered a workshop to Battle the Stigma around Mental well-being at the One Young World Summit in Manchester, United Kingdom. The workshop was attended by delegates from all across the world.",
    popularlink:
      "https://www.instagram.com/reel/Cim3L0DIKKX/?igshid=YmMyMTA2M2Y=",
  },

  {
    popularimgsrc: evolve,
    populartitle: "EVOLVE Conclave",
    popularsubtitle: "22nd May 2022",
    populardesc:
      "The three aspects of the conclave, i.e. Empower, Empathize, Elevate, are focussed on building self-awareness for leadership development, traversing through a path of reflection, expression and growth. The conclave sensitizes, educates and nurtures empathy in all levels of leadership by creating a safe space, encouraging everyone to become a better version of themselves.",
    popularlink:
      "https://www.instagram.com/p/CdQo3wavdLJ/?utm_source=ig_web_copy_link",
  },
  {
    popularimgsrc: shell,
    populartitle: "Shell",
    popularsubtitle: "Oct 2022- Present",
    populardesc:
      "Throughout the course of the year, we focus on delivering workshops focused on mental well-being for advocacy and awareness on various topics such as creation of safe spaces",
    popularlink: "https://www.instagram.com/p/Cj90_osPv71/?igshid=YmMyMTA2M2Y=",
  },
  {
    popularimgsrc: earthsake,
    populartitle: "Exclusively Inclusive, For Earth’s Sake",
    popularsubtitle: "October 2022, March 2022",
    populardesc:
      "In collaboration with For Earth’s Sake Cafe, we presented, ‘Exclusively Inclusive’, a space to foster inclusivity & create Safe Spaces.",
    popularlink: "https://www.instagram.com/p/CjpwQDBvviZ/?igshid=YmMyMTA2M2Y=",
  },
  {
    popularimgsrc: empower,
    populartitle: "Empower",
    popularsubtitle: "10th-11th October 2020",
    populardesc:
      "EMPOWER- Where Empathy is your Power, was a two day conference comprising of a total of more than 10 events, spread across two days, on the occasion of World Mental Health Day.⁣⁣⁣ The event was based on the theme-Mental Health for all, all for Mental Health - a space for discussions, performances and activities for our mental health and well being.",
    popularlink:
      "https://www.instagram.com/p/CF7Q79hh04y/?utm_source=ig_web_copy_link",
  },
  {
    popularimgsrc: spreadingsmiles,
    populartitle: "Spreading Smiles",
    popularsubtitle: "7th October 2020",
    populardesc:
      " This was an event organised for the children of Maher Ashram in collaboration with Juvenile Care. In this event, we did activities like meditation, gratitude journaling, affirmations and breathing exercises with the kids to help them understand the importance of mental health and well being. We wanted to teach them that these are some tools that they can use to feel better in their time of need.",
    popularlink:
      "https://www.instagram.com/p/CHX5nIxh6j6/?utm_source=ig_web_copy_link",
  },
];

export default Popularinfo;
