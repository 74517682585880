import React from "react";
import Facts from "./Facts";
import Services from "./Services";
import Impact from "./Impact";
import Collaborators from "./Collaborators";
import Hues from "./Hues";
import Yourhelp from "./Yourhelp";
import Collaboratewithus from "./Collaboratewithus";
import Contactus from "./Contactus";
import Header from './Header';
import { Helmet } from "react-helmet-async";
const Home = () => {
  return (
    <>
      <Helmet>
        <title>Home | Huesofthemind</title>
        <meta name="description" content="Huesofthemind is a place to empower each other⁣⁣⁣⁣ by sharing, learning, coping & healing together⁣. We embrace and celebrate the hues of you." />
        <meta name="keywords" content="hues of the mind, mental health, mental wellbeing, mental wellness, self help, mental health advocate, theapy, therapists, counsellor, psychologist, safe space, breathing exercises, anxiety, depression, mental health stories, mental health newsletter, support group, stress management, cope with stress, mental disorder, mental health podcast, breathing methods, ocd, mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown"/>
        <link rel="canonical" href="https://huesofthemind.live" />
      </Helmet>
      <Header />
      <Facts />
      <Services />
      <Impact />
      <Collaborators />
      <Hues />
      <Yourhelp />
      <Collaboratewithus />
      <Contactus />
    </>
  );
};

export default Home;
