import React from "react";
import { SocialIcon} from "react-social-icons";
export const Editorscard = (props) => {
  return (
      <div className="col-lg-4 col-md-6 col-sm-8 col-12 mx-auto">
        <div className="card editors_card h-100 text-center">
          <img src={props.eimg} className="card-img-top" alt={props.eimg} />
          <div className="card-body d-flex flex-column">
            <h5 className="card-title-editors">{props.ename}</h5>
            <hr />
            <p className="card-text-editors">
              {props.etext}
            </p>
            <div className="mt-auto">
            <SocialIcon
                url={props.elink}
                className="social_media"
                style={{
                  height: 35,
                  width: 35,
                  marginRight: 8,
                }}
                bgColor="#12356b"
                fgColor="white"
              />
            </div>
          </div>
        </div>
      </div>
  );
};
export default Editorscard;