import soulful from "../../../src/images/soulful.png";
import ea from "../../../src/images/ea.png";
import pursuit from "../../../src/images/pursuitofhappiness.png";
import dancetherapy from "../../../src/images/dancetherapy.png";
import mindfulminds from "../../../src/images/mindfulminds.png";
import kind from "../../../src/images/kind.png";
import rainbow from "../../../src/images/Rainbow.png";
import heyme from "../../../src/images/heyme.png";
import mhs from "../../../src/images/mhs.png";
import artofliving from "../../../src/images/artofliving.png";
import jab from "../../../src/images/jab.png";
import hug from "../../../src/images/hug.png";
import uncert from "../../../src/images/uncert.png";
import mentalhealthday from "../../../src/images/mentalhealthday.png";
import you2 from "../../../src/images/you2.png";
import stress from "../../../src/images/stress.png";
import work from "../../../src/images/work.png";
const WorkshopsEventsinfo = [
  {
    eventcardimgsrc: uncert,
    eventcardtitle: "Coping with Uncertainty and Anxiety",
    eventcardsubtitle: "April 2022",
    eventcarddesc:
      "Along with Girl Up Sydney, we shared self-help resources with the students of SP Jain to cope with upcoming exam stress, anxiety and the uncertainty revolving around it all.",
    eventlinks:
      "https://www.linkedin.com/posts/girl-up-sydney_uncertainty-wellbeing-mentalhealth-activity-6918822345641975808-nPTP?utm_source=linkedin_share&utm_medium=ios_app",
  },
  {
    eventcardimgsrc: work,
    eventcardtitle: "Mental health in the workspace",
    eventcardsubtitle: "March 2022",
    eventcarddesc:
      "In our debut workshop in Nepal, in collaboration with AIESEC in Nepal, we shared resources for young professionals to be more self-aware and better decision-makers in their workspace.",
    eventlinks: "https://www.instagram.com/aiesec_nepal/",
  },
  {
    eventcardimgsrc: stress,
    eventcardtitle: "Expressing our Stress ",
    eventcardsubtitle: "February 2022",
    eventcarddesc:
      "In this global workshop with the University of Nairobi, Kenya, we had conversations about coping with anxiety, stress, and uncertainty using various Expressive Arts tools.",
    eventlinks:
      "https://www.instagram.com/p/CXGwRF-KHWo/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: you2,
    eventcardtitle: "Creating Inclusive and Safe Spaces",
    eventcardsubtitle: "December 2021",
    eventcarddesc:
      "In collaboration with AIESEC in India, we encouraged leaders to create inclusive and safe spaces for sharing. We shared the key elements of these non-judgmental spaces",
    eventlinks:
      "https://www.instagram.com/p/CQv4iKmLHJp/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: mentalhealthday,
    eventcardtitle: "University-specific Workshops",
    eventcardsubtitle: "September- December 2021",
    eventcarddesc:
      "We raised awareness in 10+ universities of India, including Indraprastha University, Delhi University, NIFT Mumbai, Manipal University (LoT), ISME-ATLAS, and different chapters of AIESEC, SIAM to encourage emotional regulation and share tools of positive psychology.",
    eventlinks: "https://youtu.be/iPFRfe0b-vg",
  },
  {
    eventcardimgsrc: artofliving,
    eventcardtitle: "Coping with Uncertainty, Anxiety, and Stress",
    eventcardsubtitle: "April - June 2021",
    eventcarddesc:
      "We delivered 10+ workshops and webinars in collaboration with AIESEC, Rotaract, NLU and more, to raise awareness on the occasion of Mental Health Awareness month and to help people cope mentally with the worsening situation of the pandemic.",
    eventlinks:
      "https://www.instagram.com/p/CQF3w4pNGLn/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: jab,
    eventcardtitle: "Jab we met (or not)",
    eventcardsubtitle: "30th May 2021",
    eventcarddesc:
      "A workshop facilitated by an expressive arts therapist & organized in collaboration with Raahe and Omkaraa to explore & understand dating patterns during the pandemic. It included multiple activities for visualization, introspection, and journaling.",
    eventlinks:
      "https://www.instagram.com/p/CPYBcKZt1Z4/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: hug,
    eventcardtitle: "A Virtual Hug",
    eventcardsubtitle: "May 2021",
    eventcarddesc:
      "To give people comfort and solace in the incredibly challenging times we organized 4 events throughout the month, ranging from a movie night, music & art sessions to live meditation. They were organized in collaboration with Omisha (Omkaara), Sarla Total, and multiple artists.",
    eventlinks:
      "https://www.instagram.com/tv/CPN-zlUC9Ud/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: soulful,
    eventcardtitle: "Soulful ‘Connecting with Yourself’",
    eventcardsubtitle: "28th November 2020",
    eventcarddesc:
      "Huesofthemind in association with Dr. Krithika & the community of Girlscript, presented an amazing workshop on self-expression, 'Soulful-connecting with yourself'.⁣ This event focused on the importance of storytelling/writing, prose and poetry in knowing & hence, expressing oneself better.⁣",
    eventlinks:
      "https://www.instagram.com/p/CIBEb5NhdZ0/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: kind,
    eventcardtitle: "How to be kind to your mind",
    eventcardsubtitle: "26th July 2020",
    eventcarddesc:
      "In collaboration with Ms. Raashi Thakran, a Mental Health Advocate and a certified QPR Instructor, we at Huesofthemind, organised a session to focus on our mental well-being. The session included myths, thinking traps & suicide prevention resources.⁣",
    eventlinks:
      "https://www.instagram.com/p/CC76cu1hlMM/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: dancetherapy,
    eventcardtitle: "Dance Therapy",
    eventcardsubtitle: "20th June 2020",
    eventcarddesc:
      "Huesofthemind together with Ms Aarohi, a clinical psychologist and Certified Creative Arts facilitator who has been teaching dance for 15 years, organized a wholesome therapeutic workshop using the idea of expressing your innermost emotions through dance and movement",
    eventlinks:
      "https://www.instagram.com/p/CBhw1D-hx8Z/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: mindfulminds,
    eventcardtitle: "Mindful Minds",
    eventcardsubtitle: "13th June 2020",
    eventcarddesc:
      "A session with Ms Shalmali, a Mental Health Member at the International Youth Council by the United Nations, discussed various positive effects of Mindfulness, strategies to practice Mindfulness, Introspection, Self Affirmations, and how to stay Mindful during the Pandemic",
    eventlinks:
      "https://www.instagram.com/p/CBQOTIGhMuY/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: pursuit,
    eventcardtitle: "The Pursuit of Happiness",
    eventcardsubtitle: "25th May 2020",
    eventcarddesc:
      "⁣An interactive Google Meet regarding everyday struggles and finding stress coping mechanisms with Dr Noor Gill.",
    eventlinks:
      "https://www.instagram.com/p/CAiz7Qynmg6/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: ea,
    eventcardtitle: "Expressive Arts",
    eventcardsubtitle: "22nd May 2020",
    eventcarddesc:
      "⁣With special guest Mauree Pahuja, a fun and nourishing Art Session was held encouraging the audience to take part in the process of Expressive Art Therapy as ''The process and journey of the creation is more important and emphasized than the final product.'' This event saw the use of color and nature in art with a therapeutic result.",
    eventlinks:
      "https://www.instagram.com/p/CJGtUoCB1OA/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: mhs,
    eventcardtitle: "Mental Health Superheroes",
    eventcardsubtitle: "23rd February 2021",
    eventcarddesc:
      "In collaboration with the peer educators of the school, we curated and delivered a workshop to all the 4th and 5th graders of the school, impacting close to 1000 students. We curated a curriculum of multiple workshops, focussing on different & the most relevant aspects for each age group (for 3rd-12th grade).",
    eventlinks:
      "https://www.instagram.com/p/CQWCESRN3fi/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: rainbow,
    eventcardtitle: "Rainbow Verse",
    eventcardsubtitle: "11th December 2020 ",
    eventcarddesc:
      "It was an event organised for the children of Ayuda NGO to help them understand the importance of mental health and wellbeing. We did activities like mindful meditation, journaling, deep breathing and affirmations with the kids to teach them that there are a few tools/ activities that they can use to cope and feel better.",
    eventlinks:
      "https://www.instagram.com/p/CQWCESRN3fi/?utm_source=ig_web_copy_link",
  },
  {
    eventcardimgsrc: heyme,
    eventcardtitle: "Hey Me, It’s Me",
    eventcardsubtitle: "9th December, 2020.",
    eventcarddesc:
      "This was an event we did for the freshers of DSC Bilaspur to bring their focus and attention to the importance of mental health and wellbeing. We did activities like gratitude journaling, mindful meditation, breathing techniques, affirmation writing, and making relationship cake with the students to make them practise these tools which they can use in their college life to cope through things and feel better.",
    eventlinks:
      "https://www.instagram.com/p/CQF3w4pNGLn/?utm_source=ig_web_copy_link",
  },
];

export default WorkshopsEventsinfo;
