import dei from "../../../src/images/deimental.png";
import herosinmotion from "../../../src/images/herosinmotion.png";
import copingwithburnout from "../../../src/images/copingwithburnout.png";
import zurichz from "../../../src/images/zurichz.png";
import buildinghope from "../../../src/images/buildinghope.png";
import menmental from "../../../src/images/menmental.png";
import ikigai from "../../../src/images/ikigai.png";
import issacinmorroco from "../../../src/images/issacinmorroco.png";
import expressionart from "../../../src/images/expression-art.png";
import examstress from "../../../src/images/examstress.png";
import adol from "../../../src/images/adol.jpg";
const Eventsinfo = [
  {
    eventcardimgsrc: dei,
    eventcardtitle: "Challenges in DEI and Mental Health",
    eventcardsubtitle: "March 2023",
    eventcarddesc:
      "A discussion by the Centre for Global Inclusion on challenges in DEI and Mental Health in the United States and South Asia.",
    eventlinks:
      "https://www.linkedin.com/posts/the-centre-for-global-inclusion_dei-mentalhealth-activity-7031813863385784320-gegE?utm_source=share&utm_medium=member_ios",
  },
  {
    eventcardimgsrc: herosinmotion,
    eventcardtitle: "Heroes in Motion, Jia wei",
    eventcardsubtitle: "February 2023",
    eventcarddesc:
      "In collaboration with Jia wei, a Malaysian SDG advocate and changemaker, this conversation focussed on reducing the stigma around mental health across the globe.",
    eventlinks: "https://www.instagram.com/p/CoK0sSSoGHc/?igshid=YmMyMTA2M2Y=",
  },
  {
    eventcardimgsrc: copingwithburnout,
    eventcardtitle: "Coping with Burnout",
    eventcardsubtitle: "December 2022",
    eventcarddesc:
      "Together with Innores International, we delivered a workshop to an international audience on Coping with Burnout.",
    eventlinks: "https://www.instagram.com/p/Cl6OmIxPVeT/?igshid=YmMyMTA2M2Y=",
  },
  {
    eventcardimgsrc: zurichz,
    eventcardtitle: "Z zurich Foundation Annual summit",
    eventcardsubtitle: "November 2023",
    eventcarddesc:
      "Our founder was invited to the Z Zurich Foundation Annual Summit alongside eminent speakers from UNICEF Vietnam and more.",
    eventlinks:
      "https://www.linkedin.com/feed/update/urn:li:activity:7006140658335846400?utm_source=share&utm_medium=member_ios",
  },
  {
    eventcardimgsrc: issacinmorroco,
    eventcardtitle: "AIESEC in Morocco, AIESEC in Bangladesh",
    eventcardsubtitle: "December 2022",
    eventcarddesc:
      "We collaborated with AIESEC in Morocco and AIESEC in Bangladesh to spread some holiday season cheer. ✨",
    eventlinks:
      "https://www.linkedin.com/feed/update/urn:li:activity:7011249095193366528?utm_source=share&utm_medium=member_ios",
  },
  {
    eventcardimgsrc: buildinghope,
    eventcardtitle: "Building Hope: Learning and Growing from our Challenges",
    eventcardsubtitle: "December 2022",
    eventcarddesc:
      "Along with iDixcover, a leading organisation in Africa, our team presented a workshop to reflect on this year, learn to be mindful and be more hopeful for next year.",
    eventlinks:
      "https://www.linkedin.com/feed/update/urn:li:activity:7011683995096641536?utm_source=share&utm_medium=member_ios",
  },
  {
    eventcardimgsrc: examstress,
    eventcardtitle: "Exam Stress",
    eventcardsubtitle: "November 2022",
    eventcarddesc:
      "In collaboration with Cittaa, VoiceIT, our team delivered a workshop on coping with Exam stress in VIT Chennai.",
    eventlinks: "https://www.instagram.com/p/CkyJjadPTOl/?igshid=YmMyMTA2M2Y=",
  },
  {
    eventcardimgsrc: menmental,
    eventcardtitle: "Men and their mental health",
    eventcardsubtitle: "November 2022",
    eventcarddesc:
      "In collaboration with Buhler group, we discussed the why, what and how of Men’s Day. We highlighted the challenges that men face with their mental health.",
    eventlinks: "https://www.instagram.com/p/ClWOrdDNBZC/?igshid=YmMyMTA2M2Y=",
  },
  {
    eventcardimgsrc: adol,
    eventcardtitle: "Innovations in Adolescent Mental Health",
    eventcardsubtitle: "October 2022",
    eventcarddesc:
      "In celebration of World Mental Health Day, the International Association for Adolescent Health - Young Professionals Network collaborated with various health professionals and advocates in a panel discussion to discuss innovations in adolescent mental health.",
    eventlinks:
      "https://twitter.com/huesofthemind/status/1579115305359781889?s=46&t=LoBnn93pX69kO8ha_MjTyQ",
  },
  {
    eventcardimgsrc: expressionart,
    eventcardtitle: "Stories of Expression: Global Unite",
    eventcardsubtitle: "September 2022",
    eventcarddesc:
      "In collaboration with organisations from across the globe including iMind (Malawi), Champions for SDGs Youth (Kenya), Fortune Alaoma (Malawi) and Esther (Nigeria)  collaborated to cohost an interactive workshop themed; “Global Collaboration: Expressive Art for Mental Health and Sustainable Development''.",
    eventlinks: "https://www.instagram.com/p/CjnC7o-vM07/?igshid=YmMyMTA2M2Y=",
  },
  {
    eventcardimgsrc: ikigai,
    eventcardtitle: "Ikigai: A Self Discovery workshop",
    eventcardsubtitle: "July 2022",
    eventcarddesc:
      "An event in collaboration with the Feminist Pen focussing on exploring a world of infinite discovery and finding newer ways of Self-Expression through art and journalling.",
    eventlinks: "https://www.instagram.com/p/CfoXFhFq0Ig/?igshid=YmMyMTA2M2Y=",
  },
];

export default Eventsinfo;
