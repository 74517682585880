import ImageGallery from 'react-image-gallery';
import React from 'react'
import "react-image-gallery/styles/css/image-gallery.css";
import harubi1 from '../../images/harubi1.jpeg';
import harubi2 from '../../images/harubi2.jpeg';
import harubi3 from '../../images/harubi3.jpeg';
import harubi4 from '../../images/harubi4.jpeg';
import harubi5 from '../../images/harubi5.jpeg';
const images = [
  {
    original: harubi1,
    thumbnail: harubi1,
  },
  {
    original: harubi2,
    thumbnail: harubi2,
  },
  {
    original: harubi3,
    thumbnail: harubi3,
  },
  {
    original: harubi4,
    thumbnail: harubi4,
  },
  {
    original: harubi5,
    thumbnail: harubi5,
  },
];
const Harubi = () => {
  return (
    <div>
      <ImageGallery showPlayButton={false} items={images} showNav={false} autoPlay={true}/>
    </div>
  )
}

export default Harubi;