import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Huesinfo from "./Huesinfo";
import HuesCards from "./HuesCards";
const Hues = () => {
  return (
    <>
      <div className="hues_div">
        <div className="container">
          <h1 className="hues_title">OUR HUES</h1>
          <Carousel
            infiniteLoop
            autoPlay interval="13000"
            transitionTime="1000"
            showThumbs={false}
            showStatus={false}
          >
            {Huesinfo.map((val, ind) => {
              return (
                <HuesCards
                  key={ind}
                  hueimg={val.hueimg}
                  huetext={val.huetext}
                  huename={val.huename}
                  huedesc={val.huedesc}
                />
              );
            })}
          </Carousel>
        </div>
      </div>
    </>
  );
};
export default Hues;
