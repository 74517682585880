import React from "react";
import { Helmet } from "react-helmet-async";
const Breathinginfo = () => {
  return (
    <>
      <Helmet>
        <title>Breathing Methods - Best and professionals suggested breathing methods | Huesofthemind</title>
        <meta name="description" content="Is your breathing method really helping your state of mind? Find out at Huesofthemind. Try out these different breathing methods 1. 4-7-8 Breathing Method 2. Box Breathing 3. Abdominal breathing 4. Somatic breathing" />
        <link rel="canonical" href="https://huesofthemind.live/helpyourself/cope/breathingmethod" />
        <meta name="keywords" content="breathing methods, breathing exercises, 4-7-8 Breathing Method, Box Breathing, Abdominal breathing, mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown" />
      </Helmet>
      <div className="breathinginfo">
        <h1 className="breathinginfo_title text-center">BREATHING METHODS</h1>
        <div className="container-fluid col-12 col-md-10 col-lg-10">
          <div className="row">
            <h1 className="title_br">4-7-8 Breathing Method</h1>
            <div className="col-6 col-sm-6 col-md-6 col-lg-3 mx-auto p-3">
              <div className="card h-100 breathing_card" style={{ backgroundColor: "#FFF3DB" }}>
                <div className="card-body">
                  <p className="card-text breathinginfo_desc">
                    1. First, let your lips part. Make a whooshing sound,
                    exhaling completely through your mouth.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-3 mx-auto p-3">
              <div className="card h-100 breathing_card" style={{ backgroundColor: "#002242" }}>
                <div className="card-body">
                  <p className="card-text breathinginfo_desc1">
                    2. Next, close your lips, silently through your nose as you
                    count to four in your head.⁣
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-3 mx-auto p-3">
              <div className="card h-100 breathing_card" style={{ backgroundColor: "#FFF3DB" }}>
                <div className="card-body">
                  <p className="card-text breathinginfo_desc">
                    3. Then, for seven seconds, hold your breath.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-3 mx-auto p-3">
              <div className="card h-100 breathing_card" style={{ backgroundColor: "#002242" }}>
                <div className="card-body">
                  <p className="card-text breathinginfo_desc1">
                    4. Make another whooshing exhale from your mouth for eight
                    seconds.⁣
                  </p>
                </div>
              </div>
            </div>
            <p className="breathing_info_p">
              The 4-7-8 breathing technique (touted by integrative medicine
              expert Andrew Weil, MD) is thought to help reduce nervousness and
              stress, calm anxiety, and help people drift off to sleep more
              quickly. <br />
              It can relieve pain, lower blood pressure, and quiet your mind,
              allowing ideas and answers to flow freely and easily. <br />
              ⁣Try this out and let us know how you feel.
            </p>
          </div>
          <div className="row">
            <h1 className="title_br">Box Breathing</h1>
            <div className="col-6 col-sm-6 col-md-6 col-lg-3 mx-auto p-3">
              <div className="card h-100 breathing_card" style={{ backgroundColor: "#FFF3DB" }}>
                <div className="card-body">
                  <p className="card-text breathinginfo_desc">
                    1. Inhale- Breathe in through your nose while counting to 4 slowly.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-3 mx-auto p-3">
              <div className="card h-100 breathing_card" style={{ backgroundColor: "#002242" }}>
                <div className="card-body">
                  <p className="card-text breathinginfo_desc1">
                    2. Hold- Hold your breath inside while counting slowly to 4.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-3 mx-auto p-3">
              <div className="card h-100 breathing_card" style={{ backgroundColor: "#FFF3DB" }}>
                <div className="card-body">
                  <p className="card-text breathinginfo_desc">
                    3. Exhale- Begin to slowly exhale for 4 seconds.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-3 mx-auto p-3">
              <div className="card h-100 breathing_card" style={{ backgroundColor: "#002242" }}>
                <div className="card-body">
                  <p className="card-text breathinginfo_desc1">
                    4. Hold- Hold your breath again for 4 seconds.
                  </p>
                </div>
              </div>
            </div>
            <p className="breathing_info_p">
              Box breathing, also referred to as square breathing, is a deep breathing technique that can help you slow down your breathing. It works by distracting your mind as you count to four, calming your nervous system, and decreasing stress in your body. Repeat this cycle for at least five minutes to get the full effect.
            </p>
          </div>
          <div className="row">
            <h1 className="title_br">Abdominal breathing</h1>
            <div className="col-6 col-sm-6 col-md-6 col-lg-3 mx-auto p-3">
              <div className="card h-100 breathing_card" style={{ backgroundColor: "#FFF3DB" }}>
                <div className="card-body">
                  <p className="card-text breathinginfo_desc">
                    1. Put one hand on your belly just below your ribs and the other hand on your chest.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-3 mx-auto p-3">
              <div className="card h-100 breathing_card" style={{ backgroundColor: "#002242" }}>
                <div className="card-body">
                  <p className="card-text breathinginfo_desc1">
                    2. Take a deep breath in through your nose, and let your belly push your hand out. Your chest should not move.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-3 mx-auto p-3">
              <div className="card h-100 breathing_card" style={{ backgroundColor: "#FFF3DB" }}>
                <div className="card-body">
                  <p className="card-text breathinginfo_desc">
                    3. Breathe out through pursed lips as if you were whistling.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-3 mx-auto p-3">
              <div className="card h-100 breathing_card" style={{ backgroundColor: "#002242" }}>
                <div className="card-body">
                  <p className="card-text breathinginfo_desc1">
                    4. Do this breathing 3 to 10 times. Take your time with each breath.
                  </p>
                </div>
              </div>
            </div>
            <p className="breathing_info_p">
              Belly Breathing or Abdominal breathing helps promote relaxation, stress reduction, increased efficiency in stretching, and better body awareness.
            </p>
          </div>
          <div className="row">
            <div className="col-12 mx-auto somatic_card_outer">
              <div className="card somatic_card h-100" style={{ backgroundColor: "#FFF3DB" }}>
                <div className="card-body">
                  <h1 className="somatic_title1">What is somatic therapy?</h1>
                  <p className="card-text somatic_desc1">
                    ⁣⁣It is a holistic therapy that
                    studies the relation between mind and body in regards to
                    psychological past. The theory behind this is that trauma
                    symptoms affect the instability of the Autonomic Nervous
                    System (ANS). The system gets disrupted. Past traumatic
                    situations reflect in changes in body language, hormonal
                    imbalances, digestive issues, depression, anxiety,
                    addiction, etc.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 mx-auto somatic_card_outer">
              <div className="card somatic_card h-100" style={{ backgroundColor: "#002242" }}>
                <div className="card-body">
                  <h1 className="somatic_title2">What are somatic techniques?</h1>
                  <p className="card-text somatic_desc2">
                    ⁣⁣Somatic techniques are used
                    to control and bring relief to the tensed up muscles. This
                    is the result of memories or traumatic experiences that have
                    been repressed by the conscious mind, although still present
                    in the subconscious mind. These techniques include
                    concentration on a specific muscle as well as on your breath
                    while stretching. It helps in gaining calmness in the mind
                    and releasing stress from the specific muscle. Once the
                    muscle is relaxed, the same technique is used on the next
                    muscle. Focusing on muscles while breathing and stretching
                    is repeated periodically to relax the body and mind.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 mx-auto somatic_card_outer">
              <div className="card somatic_card h-100" style={{ backgroundColor: "#FFF3DB" }}>
                <div className="card-body">
                  <h1 className="somatic_title1">Does somatic therapy really work?⁣⁣ </h1>
                  <p className="card-text somatic_desc1">
                    Somatic psychotherapy
                    has brought back ANS to homeostasis. It has been found
                    useful for patients who have been relieved from their
                    traumatic past. Somatic psychology confirms that the mind
                    and the body are deeply connected. Neuroscience has emerged
                    with evidence that supports somatic psychology and shows how
                    the mind and the body influence one another. ⁣⁣
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 mx-auto somatic_card_outer">
              <div className="card somatic_card h-100" style={{ backgroundColor: "#002242" }}>
                <div className="card-body">
                  <h1 className="somatic_title2">⁣What is somatic breathing?⁣⁣</h1>
                  <p className="card-text somatic_desc2">
                    Somatic Breath-work Healing
                    (SBH) utilizes two part pranayama (breathing exercises) to
                    bring the nervous system to a stable consciousness. It is
                    the flow of energy and emotions that is being controlled by
                    focusing on breathing
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br /><br />
        </div>
      </div>
    </>
  );
};
export default Breathinginfo;
