import React from "react";
const Impact = () => {
  return (
    <>
      <div className="container-fluid impact_div">
        <p className="heading_impact">SEE THE IMPACT</p>
        <p className="col-10 mx-auto sub_heading_impact text-center">
          The passion to make a difference manifests in marvellous ways. Our
          beneficiaries have called our space Life-changing, and that has made
          all the difference in our lives.
        </p>
        <div className="row">
          <div className="container flex-direction col-10 mx-auto d-flex flex-direction">
            <div className="col-lg-3 col-md-3 col-sm-3 mx-auto flex-direction">
              <h4 className="impact_headings">50000+</h4>
              <p className="impact_subheadings">Impacted</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 mx-auto flex-direction">
              <h4 className="impact_headings">250+</h4>
              <p className="impact_subheadings">Collaborations</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 mx-auto flex-direction">
              <h4 className="impact_headings">150+</h4>
              <p className="impact_subheadings">Events</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 mx-auto flex-direction">
              <h4 className="impact_headings">1500+</h4>
              <p className="impact_subheadings">HuesLetter Subscribers</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Impact;
