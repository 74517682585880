import React from "react";

const HuesCards = (props) => {
  return (
    <>
    <div className="hues_outer">
      <div className="card h-100 carousel_div">
        <div className="row">
          <div
            className="col-lg-4 col-md-12 text-center d-flex align-items-stretch"
           
          >
            <img
              src={props.hueimg}
              alt="our hues huesofthemind"
              className="card-img-hues img-fluid"
            />
          </div>
          <div
            className="col-lg-8 col-md-12"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <div className="card-body">
              <p className="card-text-hues">
                {props.huetext}
              </p>
              <p className="card-text-hues1">Love and Regards</p>
              <p className="card-text-hues2">{props.huename}</p>
              <p className="card-text-hues3">{props.huedesc}</p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};
export default HuesCards;
