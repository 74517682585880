import React from "react";
import { NavLink } from "react-router-dom";
import negative from "../../images/negative.png";
import okay from "../../images/okay.png";

const NegativeThoughts = () => {
  return (
    <>
      <div className="nt p-3">
        <h1 className="negative_title">Negative Thoughts</h1>
        <div className="row">
          <div className="col-12 col-lg-11 mx-auto d-flex flex-dir-nt">
            <div className="col-10 col-lg-5 col-md-5 col-sm-10 mx-auto text-center">
              <img src={negative} className="nt_img img-fluid" alt="negative thoughts" />
            </div>
            <div className="col-10 col-lg-5 col-md-5 col-sm-8 mx-auto">
              <p className="nt_desc">
                Learn about negative thoughts and how to break through them and
                find peace within.
              </p>
              <NavLink to="/helpyourself/cope/negativethoughts" className="btn btn-nt-getstarted">
                Get Started
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="happy_div">
        <div className="row">
          <div className="col-10 offset-1 happy">
            <div className="col-10 mx-auto">
              <h1 className="happy_title">
                Can one stay happy and positive all the time?
              </h1>
              <p className="happy_desc">
                No. Everyone has their downs. Sounds quite philosophical, but
                life’s nothing without a few ups and downs. Yin yang; both good
                and bad. The key is to find good in everything. Be it the
                smallest thing. The key is to know you have someone who will
                support you, even if it’s you yourself.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="okay_div">
        <div className="row">
          <div className="col-10 offset-1 okay_card">
            <div className="card okay_text">
              <div className="row">
                <div
                  className="col-lg-5 okay_img_div"
                  style={{ marginTop: "auto", marginBottom: "auto" }}
                >
                  <img
                    src={okay}
                    alt="..."
                    className="okay_img img-fluid "
                  />
                </div>
                <div
                  className="col-lg-7 p-3"
                  style={{ marginTop: "auto", marginBottom: "auto" }}
                >
                  <div className="card-body">
                    <h1 className="okay_title">
                      So is it okay if I become very negative sometimes?⁣
                    </h1>
                    <p className="okay_desc">
                      Yes. Just don’t let it overpower you.
                      <br />
                      You can try a technique, where you observe your thoughts
                      by thinking of them as clouds and don’t let them get the
                      best of you.⁣
                      <br />
                      If you think you’re losing control, talk.
                      <br />
                      Either to yourself, or to someone you trust.
                      <br />
                      Blind journaling: a method where one writes their thought
                      train, without thinking. Pouring it all out on paper. It
                      makes you truly feel free.
                      <br />
                      Paper won’t judge you, if that’s what you’re scared of.
                      <br />⁣ Try to rediscover the things which made you
                      happier as a kid, find your happy place. Your peace is
                      very important!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NegativeThoughts;
