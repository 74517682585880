import React from "react";
import { NavLink } from "react-router-dom";
import cope from "../../../src/images/cope.png";
import safespace from "../../../src/images/safespace.png";
import learn from "../../../src/images/learn.png";

const HelpYourselfHead = () => {
  return (
    <>
      <div className="helpyourselfhead_div">
        <div className="row">
          <div className="col-10 col-lg-10 mx-auto text-center">
            <h1 className="helpyourself_title">Help Yourself</h1>
            <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center p-3">
            <img src={cope} className="helpyourself_img img-fluid" alt="..."/>
            <p className="hy_subheading">#cope</p>
            <h1 className="hy_headings">Cope with HOTM</h1>
            <NavLink to="/helpyourself/cope" className="see_more"> See More </NavLink> 
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center p-3">
            <img src={safespace} className="helpyourself_img img-fluid" alt="..."/>
            <p className="hy_subheading">#sharingstories</p>
            <h1 className="hy_headings">HOTM Safespace</h1>
            <NavLink to="/helpyourself/safespace" className="see_more"> See More </NavLink> 
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center p-3">
            <img src={learn} className="helpyourself_img img-fluid" alt="..."/>
            <p className="hy_subheading">#learn</p>
            <h1 className="hy_headings">Learn with HOTM</h1>
            <NavLink to="/helpyourself/learnwithhotm" className="see_more"> See More </NavLink> 
            </div>
            </div>
            <NavLink to="/helpyourself/hotmrepository" className="list_link">List of Mental Health Professionals</NavLink>
            <br /><br /><br /><br /><br/>
          </div>
        </div>
      </div>
    </>
  );
};
export default HelpYourselfHead;
