import React from "react";
import ReadHueslettersCard from "./ReadHueslettersCard";
import ReadHueslettersinfo from "./ReadHueslettersinfo";
import shristiedi from "../../../src/images/shristiedi.png";
import HuesLetters from "./HuesLetters";
import HowHuesletters from "./HowHuesletters";
import { Helmet } from "react-helmet-async";
const ReadHuesletters = () => {
  return (
    <>
      <Helmet>
        <title>
          Huesletter: Conversations Via Newsletters | Huesofthemind{" "}
        </title>
        <meta
          name="description"
          content="Our HuesLetter has been created with awesome resources filled with tips, tricks, poetry, art, personal stories and so much more and has been baked with love."
        />
        <link rel="canonical" href="https://huesofthemind.live/huesletters" />
        <meta
          name="keywords"
          content="mental health stories, mental health newsletter, mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown"
        />
      </Helmet>
      <HuesLetters />
      <div className="read_huesletters_div">
        <div className="row">
          <div className="col-11 col-sm-11 col-md-10 col-lg-10 text-center mx-auto">
            <h1 className="read_huesletters_title">Our HuesLetters</h1>
          </div>
        </div>

        <div className="row hl_card_div">
          <div className="col-10 col-lg-10 col-md-10 col-sm-11 mx-auto">
            <div className="row gy-4">
              {ReadHueslettersinfo.map((val, ind) => {
                return (
                  <ReadHueslettersCard
                    key={ind}
                    hlimg={val.hlimg}
                    hltitle={val.hltitle}
                    hldesc={val.hldesc}
                    hllink={val.hllink}
                  />
                );
              })}
            </div>
            <div className="readall_link">
              <a
                href="https://linktr.ee/huesletters"
                target="_blank"
                rel="noopener noreferrer"
                className="readall"
              >
                Read All Huesletters
              </a>
            </div>
          </div>
        </div>
        <div className="row note_div">
          <h1 className="note_editor">NOTE FROM THE EDITOR</h1>
          <div className="col-12 col-lg-12 mx-auto d-flex flex-dir-top">
            <div className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto text-center">
              <img src={shristiedi} className="note_img" alt="..." />
            </div>
            <div className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto">
              <div
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  verticalAlign: "middle",
                }}
              >
                <p className="note_desc">
                  Moving the curtains away, you peep through the glass window.
                  Land, from a distance, looks so bright that snow could be
                  appreciated more for its good looks. The day is illuminated
                  with glistening, warm light. It is Winter yet again, but all
                  the month does is remind you of warmth. You watch how the sun
                  melts the snow on the bare branches of tall trees and little
                  sparrows make the backyard an interest of their daily visits.
                  <br />
                  <br />
                  This is exactly how our HuesLetters make you feel- like
                  heavens wrapped up in your arms, allowing just the perfect
                  amount of sunlight breaking in through the clouds and
                  comforting you with warmth.
                </p>
                <p className="note_name">
                  -Shristi Pandey
                  <br />
                  Editor, HuesLetter
                </p>
              </div>
            </div>
          </div>
        </div>
        <HowHuesletters />
      </div>
    </>
  );
};
export default ReadHuesletters;
