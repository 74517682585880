import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../Home/huesradioarrow.css";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import merch1 from "../../images/merch1.png";
import merch2 from "../../images/merch2.png";
import merch3 from "../../../src/images/merch3.png";
import merch4 from "../../../src/images/merch4.png";
class Huesmerchbts extends Component {
  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 992 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 992, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    const arrowStyle = {
      background: "transparent",
      border: 0,
      color: "#002242",
      opacity: 0.3,
      fontSize: "80px",
    };
    const CustomRight = ({ onClick }) => (
      <button className="arrow right" onClick={onClick} style={arrowStyle}>
        <KeyboardArrowRightIcon style={{ fontSize: "50px" }} />
      </button>
    );
    const CustomLeft = ({ onClick }) => (
      <button className="arrow left" onClick={onClick} style={arrowStyle}>
        <KeyboardArrowLeftIcon style={{ fontSize: "50px" }} />
      </button>
    );
    return (
      <>
        <div className="bts_bg">
          <div className="container">
            <h1 className="bts_title">Behind The Scenes</h1>
            <p className="bts_desc">
              Meet the hues who collectively worked on the HuesMerch
            </p>
          </div>
          <div className="bts_cont">
            <div
              id="main-slide"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner container">
                <Carousel
                  autoPlay={true}
                  responsive={responsive}
                  additionalTransfrom={0}
                  arrows
                  autoPlaySpeed={3000}
                  centerMode={false}
                  className=""
                  dotListClass=""
                  draggable
                  focusOnSelect={false}
                  renderDotsOutside
                  infinite
                  itemClass=""
                  keyBoardControl
                  showDots={false}
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable
                  customRightArrow={<CustomRight />}
                  customLeftArrow={<CustomLeft />}
                >
                  <div className="container d-flex align-items-stretch">
                    <div className="card card_merch">
                      <img className="img-fluid" alt="..." src={merch1} />
                    </div>
                  </div>

                  <div className="container d-flex align-items-stretch">
                    <div className="card card_merch">
                      <img className="img-fluid" alt="..." src={merch2} /> 
                    </div>
                  </div>

                  <div className="container">
                    <div className="card card_merch">
                      <img className="img-fluid" alt="..." src={merch3} /> 
                    </div>
                  </div>

                  <div className="container">
                    <div className="card card_merch">
                      <img className="img-fluid" alt="..." src={merch4} />
                    </div>
                  </div> 
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Huesmerchbts;
