import React from "react";
import { Helmet } from "react-helmet-async";
const Anxiety = () => {
  return (
    <>
      <Helmet>
        <title>What is Anxiety? - Definition, Types of Anxiety, Symptoms, Remedies | Huesofthemind</title>
        <meta name="description" content="Do you feel anxious and can’t figure out symptoms and behavioral changes during those periods?It’s all here that you need to know to solve mind disputes easily, happily." />
        <link rel="canonical" href="/helpyourself/learnwithhotm/anxiety" />
        <meta name="keywords" content="" />
      </Helmet>
      <div className="anxiety_div">
        <div className="row">
          <div className="col-11 col-sm-11 col-md-10 col-lg-10 mx-auto">
            <h1 className="anxiety_title">Anxiety</h1>
            <p className="anxiety_para">
              Anxiety is the body’s natural response to stress. Almost everyone
              is anxious. The building up of small stressful situations or a big
              event may trigger excessive anxiety. ⁣It’s normal for you to feel
              anxious. But extreme conditions can lead you to anxiety disorder,
              the feeling of fear being with you all the time.
            </p>
            <h1 className="anxiety_heading">Types of Anxiety:</h1>
            <p className="anxiety_para">
              1. Panic disorder: recurring panic attacks<br /> ⁣
              2. Phobia: excessive fear of a specific object/situation/activity<br /> ⁣
              3. Social anxiety disorder: fear of being judged in social situations<br /> ⁣
              4. OCD: recurring thoughts making you perform repeated activities <br />⁣
              5. Separation anxiety disorder: fear of being away from loved ones <br />⁣
              6. Illness anxiety disorder: cautious about your health<br /> ⁣
              7. PTSD: anxiety following a traumatic situation.<br />
            </p>
            <h1 className="anxiety_heading">Symptoms include:</h1>
            <p className="anxiety_para">
              Increased heart rate <br />
              Rapid breathing <br />
              Restlessness <br />
              Trouble concentrating <br />
            </p>
            <h1 className="anxiety_heading">How to approach if it is severe:</h1>
            <p className="anxiety_para">
              Anxiety diagnosis requires many lengthy processes of physical
              examinations, mental health evaluations and psychological
              questionnaires. ⁣ Treatments include psychotherapy and
              medications. Meeting a therapist works. But if that’s not the
              case, then prescribed psychiatrists’ medication does the same.
              Medications include antidepressants and sedatives. They balance
              brain chemistry and prevent episodes of anxiety.
            </p>
            <h1 className="anxiety_heading">Natural Remedies: </h1>
            <p className="anxiety_para">
              1. Medication
              <br />
              2. Healthy diet
              <br />
              3. Recreational activities like exercising and yoga, avoiding
              intoxication and getting enough sleep.
              <br />
            </p>
            <h1 className="anxiety_heading">How to behave with someone who has anxiety:</h1>
            <p className="anxiety_para">
              1. Be supportive. <br />
              2. Try to listen to them and help them participate in exciting
              activities. <br />
              3. Help them improve and be proud of their progress. <br />
              4. Reassure them of how good they’re doing. Don’t lose hope in
              helping them. <br />
              5. Anxiety is a treatable condition. Don’t guilt-trip them by
              saying it’s all in their head. <br />
              6. At last but not least, be patient. It’s not a fast process.
              <br />
            </p>
            <p className="anxiety_para">
              If you think you might have anxiety but it’s getting out of hand,
              you can reach out to professional therapists at these websites.
            </p>
            <div className="al_div">
              <a href="https://www.manastha.com/" target="_blank" rel="noopener noreferrer" className="anxiety_link">www.manastha.com</a>
              <br />
              <a href="https://www.juno.clinic/therapists/" target="_blank" rel="noopener noreferrer" className="anxiety_link">
                www.juno.clinic/therapists
              </a>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};
export default Anxiety;
