import React from 'react'
import Beleaf from './Beleaf';
import Harubi from './Harubi';
import Stickerstore from './Stckerstore';
import Talkingyarns from './Talkingyarns';
const Huesmerchproducts = () => {
    return (
        <>
            <div className="container prod_cont">
                <div className="row">
                    <div className="col-xs-12 ">
                        <nav>
                            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                <a
                                    className="nav-item nav-link events_buttons active"
                                    id="nav-events-tab"
                                    data-toggle="tab"
                                    href="#nav-events"
                                    role="tab"
                                    aria-controls="nav-events"
                                    aria-selected="true"
                                >
                                    Poppy Sticker Store
                                </a>
                                <a
                                    className="nav-item nav-link events_buttons"
                                    id="nav-workshops-tab"
                                    data-toggle="tab"
                                    href="#nav-workshops"
                                    role="tab"
                                    aria-controls="nav-workshops"
                                    aria-selected="false"
                                >
                                    BeLeaf
                                </a>
                                <a
                                    className="nav-item nav-link events_buttons"
                                    id="nav-campaigns-tab"
                                    data-toggle="tab"
                                    href="#nav-campaigns"
                                    role="tab"
                                    aria-controls="nav-campaigns"
                                    aria-selected="false"
                                >
                                    Harubi
                                </a>
                                <a
                                    className="nav-item nav-link events_buttons"
                                    id="nav-more-tab"
                                    data-toggle="tab"
                                    href="#nav-more"
                                    role="tab"
                                    aria-controls="nav-more"
                                    aria-selected="false"
                                >
                                    Talking Yarns
                                </a>
                            </div>
                        </nav>
                        <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="nav-events"
                                role="tabpanel"
                                aria-labelledby="nav-events-tab"
                            >
                                <div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6 col-md-12 col-sm-12 prod_div">
                                            <Stickerstore />
                                        </div>
                                        <div className="col-12 col-lg-6 col-md-12 col-sm-12 prod_div">
                                            <h1 className="prod_headings">
                                                Poppy Sticker Store
                                            </h1>
                                            <p className="prod_desc">
                                                The first range of products under HuesMerch is by the highly talented Poppystickerstore!
                                                Started by college students Srishti Hota and Nikhita Harne, Poppy Sticker Store is a queer-owned enterprise and your go-to for absolutely wholesome stickers in the best possible quality <span></span>💞
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="nav-workshops"
                                role="tabpanel"
                                aria-labelledby="nav-workshops-tab"
                            >
                                <div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6 col-md-12 col-sm-12 prod_div">
                                            <Beleaf />
                                        </div>
                                        <div className="col-12 col-lg-6 col-md-12 col-sm-12 prod_div">
                                            <h1 className="prod_headings">
                                                Beleaf
                                            </h1>
                                            <p className="prod_desc">
                                                Plants have been scientifically proven to reduce stress and anxiety in one's environment. Presenting our products, Bloom and Rainbow, to remind you to keep beleaf-ing in yourself, no matter how colourless life may seem!<br />

                                                Founded by 17-year-old Hetvi Majithia, Beleaf is a supple and sustainable small business that looks into the most adorable plants and unique planters. 🍃

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="nav-campaigns"
                                role="tabpanel"
                                aria-labelledby="nav-campaigns-tab"
                            >
                                <div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6 col-md-12 col-sm-12 prod_div">
                                            <Harubi />
                                        </div>
                                        <div className="col-12 col-lg-6 col-md-12 col-sm-12 prod_div">
                                            <h1 className="prod_headings">
                                                Harubi
                                            </h1>
                                            <p className="prod_desc">
                                                Our next launch involves an array of products, from stickers, memo pads, jewellery, keychains and resin articles, all brought to you by Harubi! <span></span> 🤎
                                                <br />
                                                They're a small business that is also venturing into various other categories of products such as wire jewellery and enamel pins.
                                            </p>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="nav-more"
                                role="tabpanel"
                                aria-labelledby="nav-more-tab"
                            >
                                <div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6 col-md-12 col-sm-12 prod_div">
                                            <Talkingyarns />
                                        </div>
                                        <div className="col-12 col-lg-6 col-md-12 col-sm-12 prod_div">
                                            <h1 className="prod_headings">Talking Yarns Crochet</h1>
                                            <p className="prod_desc">
                                                Our next launch is all about the yarn! <span></span>🧵<br />
                                                Talking Yarns Crochet is a small business that makes the most adorable crocheted products ethically sourced and produced by the very talented Urvashi.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Huesmerchproducts;