import s1e1 from "../../../src/images/s1e1.png";
import s1e2 from "../../../src/images/s1e2.png";
import s1e3 from "../../../src/images/s1e3.png";
import s1e4 from "../../../src/images/s1e4.png";
import s1e5 from "../../../src/images/s1e5.png";
import s1e6 from "../../../src/images/s1e6.png";
const Huesradioinfo = [
  {
    radiocardimgsrc: s1e1,
    radiocardtitle: "Mental Health 101",
    radiocarddesc: "“Like starting anything in our lives, we hit the basics of mental health.”",
    radiolinks: "https://anchor.fm/huesofthemind/episodes/Mental-Health-101-e15uvmb/a-a6boktf"
  },
  {
    radiocardimgsrc: s1e2,
    radiocardtitle: "Anxiety and its Allies",
    radiocarddesc: "“Even our demons seem to have the allies we think we do not.” ",
    radiolinks: "https://anchor.fm/huesofthemind/episodes/Anxiety-and-Its-Allies-e17d929"
  },
  {
    radiocardimgsrc: s1e3,
    radiocardtitle: "Overwhelming Emotions",
    radiocarddesc: "“Feelings in extremes can be extremely taxing.”",
    radiolinks: "https://anchor.fm/huesofthemind/episodes/Overwhelming-Emotions-and-Lockdown-e18qu33"
  },
  {
    radiocardimgsrc: s1e4,
    radiocardtitle: "Expressions",
    radiocarddesc: "“”",
    radiolinks: ""
  },
  {
    radiocardimgsrc: s1e5,
    radiocardtitle: "Grief, Death and Memories",
    radiocarddesc: "“” ",
    radiolinks: ""
  },
  {
    radiocardimgsrc: s1e6,
    radiocardtitle: "Friendships and Romance",
    radiocarddesc: "“”",
    radiolinks: ""
  },
];

export default Huesradioinfo;
