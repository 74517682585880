import React from "react";
import books from "../../images/books.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import book1 from "../../images/book1.png";
import book2 from "../../images/book2.png";
import book3 from "../../images/book3.png";
import book4 from "../../images/book4.png";
import book5 from "../../images/book5.png";
import dayoung from "../../images/Dayoung.png";
import Editorscard from "./Editorscard";
import Editorsinfo from "./Editorsinfo";
import Author from "./Author";
import Managers from "./Managers";
import Feedbackbook from "./Feedbackbook";
import { Helmet } from "react-helmet-async";
const HuesofYou = () => {
  return (
    <>
      <Helmet>
        <title>Hues Of You | Huesofthemind </title>
        <meta name="description" content="A book created with one idea: speaking our minds can be freeing, and can help us all understand the true meanings of our thoughts and consequent actions." />
        <link rel="canonical" href="https://huesofthemind.live/huesofyou" />
        <meta name="keywords" content="hues of you, book, conversations around mental health, mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown" />
      </Helmet>
      <div className="huesofyou_out">
        <div className="row hoy_div">
          <div className="col-12 col-lg-12 mx-auto d-flex flex-dir-bottom">
            <div
              className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto "
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <h1 className="huesofyou_title">HUES OF YOU</h1>
              <p className="huesofyou_desc2">
                A walk through the views, our hues, and the best avenues
              </p>
              <br />
              <p className="huesofyou_desc">
                Welcome to our minds, hearts and thoughts! This book was created
                with one goal: to put it out there in the world and help people
                realise that speaking our minds can be freeing, and can help us
                all understand the true meanings of our thoughts and consequent
                actions.
                <br />
                <br />
                Happy reading, lovely reader!
              </p>
            </div>
            <div
              className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto text-center"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <h1 className="huesofyou_title1">HUES OF YOU</h1>
              <p className="huesofyou_desc1">
                A walk through the views, our hues, and the best avenues
              </p>
              <img src={books} className="hoy_img img-fluid" alt="..." />
            </div>
          </div>
        </div>
      </div>
      <div className="preview">
        <div className="container">
          <h1 className="preview_title">PREVIEW OF THE BOOK</h1>
          <Carousel
            infiniteLoop
            autoPlay={true}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
          >
            <div>
              <img src={book1} alt="..." className="img-fluid " />
            </div>
            <div>
              <img src={book2} alt="..." className="img-fluid " />
            </div>
            <div>
              <img src={book3} alt="..." className="img-fluid " />
            </div>
            <div>
              <img src={book4} alt="..." className="img-fluid " />
            </div>
            <div>
              <img src={book5} alt="..." className="img-fluid " />
            </div>
          </Carousel>
          <div className="buy">
            <p className="buy_desc">All proceeds raise funds to make therapy more accessible and affordable.</p>
            <a href="https://www.amazon.in/dp/1639409459" target="_blank" rel="noopener noreferrer" className="btn btn-buy">
              Buy
            </a>
            <p></p>
          </div>
        </div>
      </div>
      <div className="designer">
        <div className="row">
          <div className="col-12 col-lg-12 mx-auto d-flex flex-dir-bottom">
            <div className="col-10 col-lg-7 col-md-10 col-sm-10 mx-auto">
              <div>
                <h2 className="des_title">Meet The illustrator & designer</h2>
                <p className="des_desc">
                  "I hope this book opens up conversations around mental health
                  in India, until the day when it is considered just as
                  significantly as other medical problems without being
                  considered as a stigma or a sign of ‘being weak’. We live in
                  our minds, as much as we live in our physical body, so it is
                  important to take care of it."
                </p>
                <blockquote className="des_name">-Dayoung</blockquote>
              </div>
            </div>
            <div
              className="col-10 col-lg-5 col-md-10 col-sm-10 mx-auto text-center"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <img src={dayoung} className="dayoung" alt="..." />
            </div>
          </div>
        </div>
      </div>
      <Managers />
      <div className="editors">
        <h1 className="editors_title">Meet The editors</h1>
        <div className="col-10 col-lg-9 mx-auto">
          <p className="editors_desc">
            This team has previously worked on various workshops, the HuesLetter
            and social media spheres. The book is centred around intrusive
            thoughts with positive thinking and impacts of social media but it
            also a book in which words come from a place of love and hope-a
            space carved out to reach out to the people who feel like they
            cannot connect.
          </p>
        </div>
        <div>
          <div className="mb-5">
            <div className="row">
              <div className="col-sm-10 col-11 mx-auto">
                <div className="row gy-4">
                  {Editorsinfo.map((val, ind) => {
                    return (
                      <Editorscard
                        key={ind}
                        eimg={val.eimg}
                        ename={val.ename}
                        etext={val.etext}
                        elink={val.elink}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Author />
      <Feedbackbook />
    </>
  );
};
export default HuesofYou;
