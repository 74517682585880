import React from "react";
import { NavLink } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import logo from "./images/logo.png";
const Footer = () => {
  return (
    <>
      <footer className="footer_div">
        <div className="footer_style">
          <div className="container p-4">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <h4 className="comp_name">
                  <img
                    className="img-fluid"
                    src={logo}
                    alt="..."
                    style={{ width: 35, height: 35 }}
                  />{" "}
                  HOTM
                </h4>
                <p className="comp_desc">
                  Huesofthemind Wellbeing Foundation is a non-profit organization under Section 8 of The Companies Act, 2013, India, run by progressive
                  youth, where we try to have more conversations related to
                  mental health to spread awareness and reduce the stigma around
                  it.
                </p>
                <SocialIcon
                  url="https://facebook.com/huesofthemind"
                  target="_blank" rel="noopener noreferrer"
                  className="social_media"
                  style={{ height: 30, width: 30, marginRight: 8 }}
                  bgColor="#12356b"
                  fgColor="white"
                />
                <SocialIcon
                  url="https://www.instagram.com/huesofthemind"
                  target="_blank" rel="noopener noreferrer"
                  className="social_media"
                  style={{ height: 30, width: 30, marginRight: 8 }}
                  bgColor="#12356b"
                  fgColor="white"
                />
                <SocialIcon
                  url="https://twitter.com/huesofthemind"
                  target="_blank" rel="noopener noreferrer"
                  className="social_media"
                  style={{ height: 30, width: 30, marginRight: 8 }}
                  bgColor="#12356b"
                  fgColor="white"
                />
                <SocialIcon
                  url="https://www.youtube.com/channel/UCuUluSdKP5fBFQ_u05YtN7A"
                  target="_blank" rel="noopener noreferrer"
                  className="social_media"
                  style={{ height: 30, width: 30, marginRight: 8 }}
                  bgColor="#12356b"
                  fgColor="white"
                />
                <SocialIcon
                  url="https://www.linkedin.com/company/huesofthemind"
                  target="_blank" rel="noopener noreferrer"
                  className="social_media"
                  style={{ height: 30, width: 30, marginRight: 8 }}
                  bgColor="#12356b"
                  fgColor="white"
                />
                <SocialIcon
                  url="https://open.spotify.com/user/h7ld4jj12xupyccypyhzchiws?si=G6K5uXJ9QUOoKmXuFT4-Hw&dl_branch=1" target="_blank" rel="noopener noreferrer"
                  className="social_media"
                  style={{ height: 30, width: 30, marginRight: 8 }}
                  bgColor="#12356b"
                  fgColor="white"
                />
              </div>

              <div className="col-lg-4 col-md-4 text-center">
                <h6 className="quick_links">QUICK LINKS</h6>
                <br />
                <ul className="list-unstyled">
                  <li className="p-1">
                    <NavLink className="footer_links" to="/">
                      Home
                    </NavLink>
                  </li>
                  <li className="p-1">
                    <NavLink className="footer_links" to="/events">
                      Events
                    </NavLink>
                  </li>
                  <li className="p-1">
                    <NavLink className="footer_links" to="/helpyourself">
                      Help Yourself
                    </NavLink>
                  </li>
                  <li className="p-1">
                    <NavLink className="footer_links" to="/helpyourself/hotmrepository">
                      HOTM Repository
                    </NavLink>
                  </li>
                  <li className="p-1">
                    <NavLink className="footer_links" to="/huesofyou">
                      Hues of You
                    </NavLink>
                  </li>
                  <li className="p-1">
                    <NavLink className="footer_links" to="/huesradio">
                      HuesRadio
                    </NavLink>
                  </li>
                  <li className="p-1">
                    <NavLink className="footer_links" to="/contact">
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-4">
                <h6 className="footer_huesletters">HUESLETTERS</h6>
                <br />
                <p className="footer_huesletter_p">
                  With the HuesLetter, we wish to stir more conversations about
                  Mental Health, providing a safe place to share & express.
                </p>
                <div className="subs">
                  <a href="https://live.us2.list-manage.com/subscribe?u=b3cd03e45a10a67bf4081b66f&id=0389f7a0b8" role="button" target="_blank" rel="noopener noreferrer" className="btn subs_btn">
                    Subscribe Now
                  </a>
                </div>
                <p className="footer_huesletter_p">
                  Subscribe to our very own fortnightly newsletter!
                </p>
              </div>
            </div>
          </div>
          <div className="footer_end text-center">
            <p>Created with 🤍 by Team Sahaara</p>
            <p>© Huesofthemind 2022. All Rights Reserved</p>
          </div>
        </div> 
      </footer>
    </>
  );
};

export default Footer;
