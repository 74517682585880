import huesletter1 from "../../images/huesletter1.png";
import huesletter2 from "../../images/huesletter2.png";
import huesletter3 from "../../images/huesletter3.png";
const ReadHueslettersinfo = [
    {
        hlimg: huesletter2,
        hltitle: "An insight into Art and Mental Wellness",
        hldesc: "It’s 2020 and Mental Health is finally getting the spotlight which was long due. Mental Health is not just limited to clinical disorders it is about keeping one’s mind healthy and maintaining its wellness. Its importance is equivalent to physical health. While talking to a mental health professional is the best way to deal with anxiety, there are so many things you could do for your mental peace with absolutely no professional help for all those mild stressors. Indulging in ART is one of the easiest ways to take a step back from your busy life and relax.." ,
        hllink: "https://mailchi.mp/dc664a54a416/huesletter-for-every-hue-of-you"
    },
    {
        hlimg: huesletter1,
        hltitle: "Love and Interpersonal Relationships",
        hldesc: "Love has been defined in several ways. From a deep feeling of affection, to the embodiment of virtues that always protects, trusts, hopes, and perseveres. And after centuries, now, with a better understanding of the smell of coffee and daffodils in the backyard of love, science has begun to recognize that there are health benefits of this feeling, as well. Science now provides us evidence that what is experienced, when we are associated in loving relationships involve various neurotransmitters and hormones in our bodies that affect us positively.",
        hllink: "https://mailchi.mp/443e75adf605/huesletter-love-interpersonal-relationships" 
    },
    {
        hlimg: huesletter3,
        hltitle: "Uncertainty and Mental Health.",
        hldesc: "There’s undeniably a charge. A charge that pulls things forwards. Pushes all of us deeper. Deeper into the future. A future in which there’s only going up. Up a ladder where there’s no going back. Going back can’t lead to anything. But anything from the future could always be considered a chance. A chance that can make us or break us. Break us into a million pieces. A million pieces that can actually be put back together by yourself. By yourself, when you explore deep seated fears, newer horizons, bigger avenues. Bigger avenues that help you piece yourself, to grow." ,
        hllink: "https://mailchi.mp/5201cfa2cb4c/uncertainty"
    }
]
export default ReadHueslettersinfo;