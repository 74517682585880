import React from 'react'
import award1 from '../../images/award1.png'
import Recognitions from './Recognitions'
export const Awards = () => {
    return (
        <div className="award_outer">
        <div className="row award_div">
          <div className="col-12 col-lg-12 mx-auto d-flex flex-dir-bottom">
            <div
              className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto "
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <h1 className="award_title">We won hearts <br/> and awards!</h1>
              <br />
              <p className="award_desc">
              Our team is the youngest team to be honoured with the Silver Award in COVID-19 Solution in the Mental Health category, which received over 3500 nominations from all across India. The award has been conferred by the IHW Council of India and was presented by Mr Lov Verma, the former Health Secretary of India. 
              </p>
            </div>
            <div
              className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto text-center"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <h1 className="award_title1">We won hearts and awards!</h1>
              <img src={award1} className="award_img img-fluid" alt="..." />
            </div>
          </div>
        </div>
        <Recognitions />
        </div>
    )
}
