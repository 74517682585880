import React from "react";
const SafeSpacecards = (props) => {
  return (
    <>
      <div className="col-lg-4 col-md-6 col-12 mx-auto">
        <div className="card h-100">
          <img src={props.ssimg} className="card_ssimg" alt={props.ssimg} />
          <div className="card-body d-flex flex-column card_safespace">
            <h5 className="card_sstitle">{props.sstitle}</h5>
            <hr />
            <p className="card_sssubtitle">
              {props.ssdesc}
            </p>
            <div className="mt-auto">
            <a href={props.sslink} target="_blank" rel="noopener noreferrer" className="btn-ss"> Read more </a>          
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SafeSpacecards;
