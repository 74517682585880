import ImageGallery from 'react-image-gallery';
import React from 'react'
import "react-image-gallery/styles/css/image-gallery.css";
import sticker1 from '../../images/sticker1.jpeg';
import sticker2 from '../../images/sticker2.jpeg';
import sticker3 from '../../images/sticker3.jpeg';
import sticker4 from '../../images/sticker4.jpeg';

const images = [
  {
    original: sticker1,
    thumbnail: sticker1,
  },
  {
    original: sticker2,
    thumbnail: sticker2,
  },
  {
    original: sticker3,
    thumbnail: sticker3,
  },
  {
    original: sticker4,
    thumbnail: sticker4,
  },
];
const Stickerstore = () => {
  return (
    <div>
      <ImageGallery showPlayButton={false} items={images} showNav={false} autoPlay={true}/>
    </div>
  )
}

export default Stickerstore;