import React from "react";
import SafeSpacecards from "./SafeSpacecards";
import SafeSpaceinfo from "./SafeSpaceinfo";
import Sharestory from "./Sharestory";
import { Helmet } from "react-helmet-async";
const SafeSpace = () => {
  return (
    <>
      <Helmet>
        <title>HOTM Safe Space | Huesofthemind </title>
        <meta name="description" content="Learn about negative thoughts, and possible tips and tricks to calm your mind. Understand how negativity can be driven away." />
        <link rel="canonical" href="https://huesofthemind.live/helpyourself/safespace" />
        <meta name="keywords" content="safepace, share your story, mental health stories, talkitout, sharing spaces, mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown" />
      </Helmet>
      <div className="safespace_div">
        <div className="row">
          <div className="col-10 col-md-10 col-lg-10 mx-auto text-center">
            <h1 className="safespace_title">SAFESPACE</h1>
            <h5 className="safespace_subtitle">
              Share your stories with us in our safespace curated just for you!
            </h5>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 mx-auto">
                  <div className="row gy-5">
                    {SafeSpaceinfo.map((val, ind) => {
                      return (
                        <SafeSpacecards
                          key={ind}
                          ssimg={val.ssimg}
                          sstitle={val.sstitle}
                          ssdesc={val.ssdesc}
                          sslink={val.sslink}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="safespace_para">Follow <a href="https://www.instagram.com/explore/tags/hotmsafespace/" target="_blank" rel="noopener noreferrer" className="hotmsafe">#hotmsafespace</a> to stay updated and read other intriguing stories!</p>
      </div>
      <Sharestory />
    </>
  );
};
export default SafeSpace;
