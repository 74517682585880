import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import sos from "./images/sos.png" 
const Helpline = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <>
      <button href="#helpline" className="helpline" onClick={onOpenModal}>
        <img src={sos} className="sos" alt="sos"/>
      </button>
      <Modal open={open} onClose={onCloseModal} center>
        <h4 className="helpline_heading">Mental Health Helpline</h4><hr />
        <p className="numbers">
          AASRA: +91 9820466726
          <br />
          iCALL Helpline: +91 9152987821
          <br />
          Kiran: 18005990019
          <br />
          Vandrela Foundation: +91 9999666555
        </p>
      </Modal>
    </>
  );
};
export default Helpline;
