import React from "react";
const Feedbackradio = () => {
  return (
    <>
      <div className="feedback_radio">
        <div className="selfcare_div">
          <div className="selfcare_card_div">
            <div className="card selfcare_card">
              <div className="card-body">
                <h5 className="card-title selfcare_title_card">FEEDBACK</h5>
                <hr />
                <p className="card-text selfcare_card_desc">
                  Share your views & stories, suggest topics or ask questions
                </p>
                <form
                  action="https://docs.google.com/forms/u/2/d/e/1FAIpQLSf9630DKZA9YDWrMlGeyT-vUxnlwZPhfE2v9373kKc-2ySjGQ/formResponse"
                  method="post"
                >
                  <div className="mb-3">
                    <textarea
                      type="text"
                      className="form-control"
                      name="entry.839337160"
                      rows="3"
                      required
                      placeholder="Message.."
                    ></textarea>
                  </div>
                  <div className="col-12 mb-3">
                    <button
                      className="btn btn-radioform"
                      type="submit"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedbackradio;
