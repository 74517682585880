import React from "react";
import { NavLink } from "react-router-dom";
import image1 from "../../../src/images/2.png";
import service4 from "../../../src/images/service4.png";
import workshops from "../../../src/images/Workshops.png";
import campaigns from "../../../src/images/Campaigns.png";
import sharing from "../../../src/images/Sharing.png";
import help from "../../../src/images/ProfessionalHelp.png";
import huesletter from "../../../src/images/HuesLetter.png";
import hueshare from "../../../src/images/hueshare.png";
import huecast from "../../../src/images/huecast.png";
import huesofu from "../../../src/images/huesofu.png";
import image5 from "../../../src/images/5.png";
import home3 from "../../../src/images/home3.png";
import huesmerch from "../../../src/images/huesmerch.png";
import research from "../../../src/images/research.jpeg";
import huesed from "../../../src/images/huesed.jpeg";
const Services = () => {
  return (
    <>
      <div className="helpbg">
        <div className="services_div p-3">
          <h1 className="services_title">How do we help?</h1>
          <div className="row">
            <div className="col-12 col-lg-12 mx-auto d-flex flex-dir-top">
              <div
                className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto text-center"
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                <img src={image1} className="help1 img-fluid" alt="workshops campaingns at hues of the mind" />
              </div>

              <div className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto titles">
                <div style={{ marginBottom: 50 }}>
                  <img
                    className="img-left1"
                    src={workshops}
                    width="40px"
                    height="40px"
                    alt="workshops huesofthemind"
                  />
                  <h2 className="headings1">Workshops & Camps</h2>
                  <p className="para1">
                    Through the use of structured experiences, group discussions
                    and interactions, we hold dynamic, engaging and interactive
                    sessions designed to provide participants with the
                    opportunity to increase their awareness of mental health.
                  </p>
                  <NavLink to="/events" className="know_more">
                    {" "}
                    Know more{" "}
                  </NavLink>
                </div>

                <div style={{ marginBottom: 50 }}>
                  <img
                    className="img-left1"
                    src={campaigns}
                    width="40px"
                    height="40px"
                    alt="campaigns at huesofthemind"
                  />
                  <h2 className="headings1">Campaigns</h2>
                  <p className="para1">
                    We aim to foster a world where mental health is for
                    everyone. In this venture, we collaborate with other
                    organisations which resonate with the same goal as ours, to
                    conduct live sessions and social media campaigns to spread
                    awareness.
                  </p>
                  <a
                    href="https://instagram.com/huesofthemind"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="know_more"
                  >
                    {" "}
                    Know more{" "}
                  </a>
                </div>

                <div style={{ marginBottom: 50 }}>
                  <img
                    className="img-left1"
                    src={sharing}
                    width="40px"
                    height="40px"
                    alt="safespace at huesofthemind"
                  />
                  <h2 className="headings1">Sharing Spaces</h2>
                  <p className="para1">
                    We provide people with a space to share their experiences by
                    fostering a safe space. This is cathartic for the one
                    sharing it and empowering for the community.
                  </p>
                  <NavLink to="/helpyourself/safespace" className="know_more">
                    Know more{" "}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="services_div p-3">
          <div className="row">
            <div className="col-12 col-lg-12 mx-auto d-flex flex-dir-bottom">
              <div className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto titles">
                <div style={{ marginBottom: 50 }}>
                  <img
                    className="img-left"
                    src={huesletter}
                    width="40px"
                    height="40px"
                    alt="huesletter huesofthemind"
                  />
                  <h2 className="headings1">HuesLetter</h2>
                  <p className="para2">
                    With the HuesLetter, we wish to stir more conversations
                    about mental health, provide a place to share & express what
                    we feel, cultivate self-love, compassion & empathy and most
                    importantly, remind you to take a break.
                  </p>
                  <NavLink to="/huesletters" className="know_more1">
                    {" "}
                    Know more{" "}
                  </NavLink>
                </div>
                <div style={{ marginBottom: 50 }}>
                  <img
                    className="img-left"
                    src={hueshare}
                    width="40px"
                    height="40px"
                    alt="hueshare huesofthemind"
                  />
                  <h2 className="headings1">HueShare</h2>
                  <p className="para2">
                    Through HueShare, our online support group, we aim to
                    provide an all inclusive safe space where you can cope
                    virtually, especially if you are someone who cannot access
                    or afford individual therapy. Facilitated by a counsellor,
                    HueShare includes online sessions and access to self-help
                    resources curated by mental health professionals.
                  </p>
                  <a
                    href="https://rzp.io/l/hueshare"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="know_more1"
                  >
                    {" "}
                    Know more{" "}
                  </a>
                </div>
                <div style={{ marginBottom: 50 }}>
                  <img
                    className="img-left"
                    src={huecast}
                    width="40px"
                    height="40px"
                    alt="huesreadio huesofthemind"
                  />
                  <h2 className="headings1">HuesRadio</h2>
                  <p className="para2">
                    An initiative where we explore the spectrum of mental health
                    to spread awareness, creating a space to have more
                    conversations through the medium of podcasts.
                  </p>
                  <NavLink to="/huesradio" className="know_more1">
                    {" "}
                    Know more{" "}
                  </NavLink>
                </div>
                
              </div>
              <div
                className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto text-center img-size "
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                <img src={image5} className="help2 img-fluid" alt="huesradio huesmerch huesletters" />
              </div>
            </div>
          </div>
        </div>

        <div className="services_div p-3">
          <div className="row">
            <div className="col-12 col-lg-12 mx-auto d-flex flex-dir-top">
              <div
                className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto text-center"
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                <img src={home3} width="" height="" className="help3 img-fluid" alt="therapist huesmerch hueshare" />
              </div>

              <div className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto titles">
              <div style={{ marginBottom: 50 }}>
                  <img
                    className="img-left1"
                    src={help}
                    width="40px"
                    height="40px"
                    alt="Professional Help"
                  />
                  <h2 className="headings1">Professional Help</h2>
                  <p className="para1">
                    We created a mental health repository with details of mental
                    health professionals specializing in different domains. We
                    raise funds through our events to make therapy more
                    accessible and affordable for all.
                  </p>
                  <NavLink
                    to="/helpyourself/hotmrepository"
                    className="know_more"
                  >
                    {" "}
                    Know more{" "}
                  </NavLink>
                </div>
                <div style={{ marginBottom: 50 }}>
                  <img
                    className="img-left1"
                    src={huesofu}
                    width="40px"
                    height="40px"
                    alt="Hues of You"
                  />
                  <h2 className="headings1">Hues of You</h2>
                  <p className="para1">
                  An illustrated book, written, composed and designed by our team with self- help resources, research- backed articles, our journeys in poetry & prose. All proceeds raised funds.
                  </p>
                  <NavLink to="/huesofyou" className="know_more">
                    Know more{" "}
                  </NavLink>
                </div>

                <div style={{ marginBottom: 50 }}>
                  <img
                    className="img-left1"
                    src={huesmerch}
                    width="40px"
                    height="40px"
                    alt="HuesMerch"
                  />
                  <h2 className="headings1">HuesMerch</h2>
                  <p className="para1">
                  Huesmerch is an initiative to sell Huesofthemind’s merchandise in collaboration with small businesses to support them and raise funds to make therapy accessible to those in need.
                  </p>
                  <NavLink to="/huesmerch" className="know_more">
                    Know more{" "}
                  </NavLink>
                </div>
                
              </div>
            </div>
          </div>
        </div>

        <div className="services_div p-3">
          <div className="row">
            <div className="col-12 col-lg-12 mx-auto d-flex flex-dir-bottom">
              <div className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto titles">
                <div style={{ marginBottom: 50 }}>
                  <img
                    className="img-left"
                    src={huesed}
                    width="40px"
                    height="40px"
                    alt="huesletter huesofthemind"
                  />
                  <h2 className="headings1">HuesEd</h2>
                  <p className="para2">
                  A unique platform to advocate mental health using curated educational tools and interactive modules.
                  </p>
                  {/* <NavLink to="/" className="know_more1">
                    {" "}
                    Know more{" "}
                  </NavLink> */}
                </div>
                <div style={{ marginBottom: 50 }}>
                  <img
                    className="img-left"
                    src={research}
                    width="40px"
                    height="40px"
                    alt="hueshare huesofthemind"
                  />
                  <h2 className="headings1">Research</h2>
                  <p className="para2">
                  We perform research and analysis to encourage budding psychologists and facilitate developments in psychology and mental well-being.
                  </p>
                  {/* <a
                    href=""
                    target="_blank"
                    rel="noopener noreferrer"
                    className="know_more1"
                  >
                    {" "}
                    Know more{" "}
                  </a> */}
                </div>
                {/* <div style={{ marginBottom: 50 }}>
                  <img
                    className="img-left"
                    src={huecast}
                    width="40px"
                    height="40px"
                    alt="huesreadio huesofthemind"
                  />
                  <h2 className="headings1">HuesRadio</h2>
                  <p className="para2">
                    An initiative where we explore the spectrum of mental health
                    to spread awareness, creating a space to have more
                    conversations through the medium of podcasts.
                  </p>
                  <NavLink to="/huesradio" className="know_more1">
                    {" "}
                    Know more{" "}
                  </NavLink>
                </div> */}
                
              </div>
              <div
                className="col-10 col-lg-6 col-md-10 col-sm-10 mx-auto text-center img-size "
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                <img src={service4} className=" img-fluid" alt="huesradio huesmerch huesletters" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Services;
