import React from 'react'
import Breathing from './Breathing'
import NegativeThoughts from './NegativeThoughts'
import SelfCare from './SelfCare'
import Selftalk from './Selftalk'
import { Helmet } from "react-helmet-async";
const Cope = () => {
    return (
        <>
            <Helmet>
                <title>Cope With HOTM | Huesofthemind </title>
                <meta name="description" content="Learn about negative thoughts, and possible tips and tricks to calm your mind. Understand how negativity can be driven away." />
                <link rel="canonical" href="https://huesofthemind.live/helpyourself/cope" />
                <meta name="keywords" content="cope with hotm, self talk, negative thoughts, self care, breathing methods,mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown" />
            </Helmet>
            <Selftalk />
            <NegativeThoughts />
            <Breathing />
            <SelfCare />
        </>
    )
}
export default Cope;