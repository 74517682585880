import ss1 from "../../images/ss1.png";
import ss2 from "../../images/ss2.png";
import ss3 from "../../images/ss3.png";
const SafeSpaceinfo = [
    {
        ssimg: ss3,
        sstitle: "UTSAV GUPTA",
        ssdesc: "Count your feelings. ⁣⁣Hey there, it's me again. " ,
        sslink: "https://www.instagram.com/p/B1MFO6SFbhz/?utm_source=ig_web_copy_link"
    },
    {
        ssimg: ss1,
        sstitle: "SAI KRISHNA SHIVAKUMAR ",
        ssdesc: "Since the 6th grade, my studies got worse after each passing year.",
        sslink: "https://www.instagram.com/p/B0SIcr-lYI4/?utm_source=ig_web_copy_link"
    },
    {
        ssimg: ss2,
        sstitle: "DEVANSHU SARAN",
        ssdesc: "Half Fried Eggs (Namak Pyaar Anusaar) The title might be funny. But the story " ,
        sslink: "https://www.instagram.com/p/BzDvSjcFFbK/?utm_source=ig_web_copy_link"
    }
]
export default SafeSpaceinfo;