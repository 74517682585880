import React from "react";
import Huesmerchbts from "./Huesmerchbts";
import Huesmerchproducts from "./Huesmerchproducts";
import { Helmet } from "react-helmet-async";
const HuesMerch = () => {
  return (
    <>
      <Helmet>
        <title>HuesMerch | Huesofthemind </title>
        <meta
          name="description"
          content="Limited Edition Merch to support small businesses and promote causes such as Sustainability and LGBTQIA+ Rights, and to make therapy more accessible. "
        />
        <link rel="canonical" href="https://huesofthemind.live/huesmerch" />
        <meta
          name="keywords"
          content="smallbusiness,supportsmallbusiness,handmade,startup, mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown"
        />
      </Helmet>
      <div className="huesmerch_outer">
        <div className="row huesmerch_div">
          <div className="col-12 col-lg-12 mx-auto d-flex flex-dir-bottom">
            <div
              className="col-10 col-lg-6 col-md-6 col-sm-10 mx-auto "
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <h1 className="huesmerch_title">Huesmerch</h1>
              <br />
              <p className="huesmerch_desc">
                Huesmerch is an initiative to help support and collaborate with
                small businesses that support various causes such as
                sustainability, LGBTQIA+ rights, etc. and raise funds to make
                therapy accessible through limited-edition merchandise.
              </p>

              <div className="huesmerch_btns">
                <a
                  href="https://pages.razorpay.com/huesmerch"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-buymerch "
                >
                  Buy Now
                </a>

                <div></div>
              </div>
            </div>
            <div
              className="col-10 col-md-6 col-sm-10 col-lg-6 mx-auto text-center"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <h1 className="huesmerch_title1">HUESMERCH</h1>
            </div>
          </div>
        </div>
        <div>
          <h1 className="products_title">OUR MERCHANDISE</h1>
          <Huesmerchproducts />
        </div>
        <Huesmerchbts />
      </div>
    </>
  );
};

export default HuesMerch;
