import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Home from "../src/Components/Home/Home";
import Events from "../src/Components/Events/Events";
import HelpYourself from "./Components/HelpYourself/HelpYourself";
import Cope from "../src/Components/HelpYourself/Cope";
import Navbar from "./Navbar";
import Footer from "./Footer"
import { Switch, Route, Redirect } from "react-router-dom";
import SafeSpace from "./Components/HelpYourself/SafeSpace";
import SelfCareinfo from "./Components/HelpYourself/SelfCareinfo";
import Breathinginfo from "./Components/HelpYourself/Breathinginfo";
import Negativethoughtsinfo from "./Components/HelpYourself/Negativethoughtsinfo";
import Learn from "./Components/HelpYourself/Learn";
import Lgbtq from "./Components/HelpYourself/Lgbtq";
import Anxiety from "./Components/HelpYourself/Anxiety";
import BreakingStigma from "./Components/HelpYourself/BreakingStigma";
import Ocd from "./Components/HelpYourself/Ocd";
import Adhd from "./Components/HelpYourself/Adhd";
import HotmRepository from "./Components/HelpYourself/HotmRepository";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import ReadHuesletters from "./Components/HelpYourself/ReadHuesletters";
import Specialized from "./Components/HelpYourself/Specialized";
import HuesofYou from "./Components/Home/HuesofYou";
import Huesradiopage from "./Components/Home/Huesradiopage";
import HuesMerch from "./Components/Home/HuesMerch";
import Collaborateform from "./Components/Forms/Collaborateform";
import EventForm from "./Components/Forms/EventForm";
const App = () => {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/huesofyou" component={HuesofYou} />
        <Route exact path="/huesradio" component={Huesradiopage} /> 
        <Route exact path="/events" component={Events} />
        <Route exact path="/about" component={About} />  
        <Route exact path="/helpyourself" component={HelpYourself} />
        <Route exact path="/contact" component={Contact} /> 
        <Route exact path="/helpyourself/cope" component={Cope} />
        <Route exact path="/huesletters" component={ReadHuesletters} />
        <Route exact path="/huesmerch" component={HuesMerch} />
        <Route exact path="/collaborate" component={Collaborateform} />
        <Route exact path="/organizeworkshop" component={EventForm} />
        <Route exact path="/helpyourself/learnwithhotm" component={Learn} />       
        <Route exact path="/helpyourself/cope/breathingmethod" component={Breathinginfo} />
        <Route exact path="/helpyourself/cope/negativethoughts" component={Negativethoughtsinfo} />
        <Route exact path="/helpyourself/cope/selfcare" component={SelfCareinfo} />
        <Route exact path="/helpyourself/safespace" component={SafeSpace} />
        <Route exact path="/helpyourself/learnwithhotm/lgbtq" component={Lgbtq} />
        <Route exact path="/helpyourself/learnwithhotm/anxiety" component={Anxiety} />
        <Route exact path="/helpyourself/learnwithhotm/breakingstigma" component={BreakingStigma} />
        <Route exact path="/helpyourself/learnwithhotm/ocd" component={Ocd} />
        <Route exact path="/helpyourself/learnwithhotm/adhd" component={Adhd} />
        <Route exact path="/helpyourself/hotmrepository" component={HotmRepository} />
        <Route exact path="/helpyourself/hotmrepository/specialized" component={Specialized} />
        <Redirect to="/" />
      </Switch>
      <Footer />
    </>
  );
};

export default App;
