import React from 'react';
import { Helmet } from "react-helmet-async";
const Collaborateform = () => {
  return (
    <>
      <Helmet>
        <title>Collaborate with Huesofthemind | Huesofthemind </title>
        <meta name="description" content="If you are committed to eliminating the stigma surrounding mental illnesses and want to encourage mental wellbeing, we would be more than happy to collaborate with your organisation." />
        <link rel="canonical" href="https://huesofthemind.live/collaborate" />
        <meta name="keywords" content="hues of the mind, mental health, mental wellbeing, mental wellness, self help, mental health advocate, theapy, therapists, counsellor, psychologist, safe space, breathing exercises, anxiety, depression, mental health stories, mental health newsletter, support group, stress management, cope with stress, mental disorder, mental health podcast, breathing methods, ocd, mental, mental health day, mental health meaning, what is mental health, world mental health day,mental health care,mental health awareness,mental health definition,mental breakdown,mental stress,mental doctor,importance of mental health,mental health services,mental trauma,definition of mental health,how to improve mental health,mental therapist,mental stability,mental breakdown" />
      </Helmet>
      <div className="forms-div">
        <iframe title="Collaborate" src="https://docs.google.com/forms/d/e/1FAIpQLSc808wTiRQ_429h5ykDMJm0GbFNWwrOtHjuKYQZSjH_jUVjBA/viewform?embedded=true" className="forms-col" width="100%" height="1550" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe></div>
    </>
  );
};

export default Collaborateform;
