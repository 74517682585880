import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import vitlogo from "../../../src/images/vitlogo.png";
import dpslogo from "../../../src/images/dpslogo.png";
import mahelogo from "../../../src/images/mahelogo.png";
import thaperlogo from "../../../src/images/thaperlogo.png";
import global from "../../../src/images/global.png";
import nlulogo from "../../../src/images/nlulogo.png";
import dulogo from "../../../src/images/dulogo.png";
import spalogo from "../../../src/images/spalogo.png";

const Deliveredworkshops = () => {
  return (
    <>
      <div className="container dw">
        <p className="dw_title">DELIVERED WORKSHOPS IN</p>
        <div className="container-fluid col-12 col-lg-12 text-center">
          <div className="container">
            <Carousel
              infiniteLoop
              autoPlay={true}
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
            >
              <div className="row">
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img src={vitlogo} className="logodw" alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img src={dpslogo} className="logodw" alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img src={thaperlogo} className="logodw" alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img src={mahelogo} className="logodw" alt="..." />
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img className="img-fluid" src={global} alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img src={nlulogo} className="logodw" alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img src={dulogo} className="logodw" alt="..." />
                </div>
                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 p-3">
                  <img src={spalogo} className="logodw" alt="..." />
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};
export default Deliveredworkshops;
