import React from "react";
const SearchedCard = ({person}) => {
  return (
    <>
      <div className="col-lg-4 col-md-6 col-12 mx-auto">
        <div className="card events_cards h-100 text-center">
          <img src={person.eventcardimgsrc} className="card-img-top" alt={person.eventcardimgsrc} />
          <div className="card-body d-flex flex-column">
            <h5 className="card-title-events">{person.eventcardtitle}</h5>
            <h5 className="card-subtitle-events">{person.eventcardsubtitle}</h5>
            <hr />
            <p className="card-text-events">
              {person.eventcarddesc}
            </p>
            <div className="mt-auto">
            <a href={person.eventlink} className="card-link-events"> Know more </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchedCard;
