import React from "react";
import { NavLink } from "react-router-dom";
import Helpline from "./Helpline";
import logo from "./images/logo.png";
import "./newcss.css";
const handleCollapse = () => {
  var nav = document.getElementById("navbarSupportedContent");
  var btn = document.getElementById("navbarBtn");
  nav.classList.remove("show");
  btn.classList.add("collapsed");
};
const Navbar = () => {
  return (
    <>
      <div className="fixed-top">
        <div className="col-12 mx-auto">
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark py-3">
            <div className="navbar-brand">
              <img
                className="img-fluid"
                src={logo}
                alt="..."
                style={{ width: 30, height: 30, marginRight: 10 }}
              />
              HOTM
            </div>
            <button
              className="navbar-toggler hamburger"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              id="navbarBtn"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    activeClassName="nav-active"
                    exact
                    className="nav-link links active"
                    onClick={() => handleCollapse()}
                    aria-current="page"
                    to="/"
                  >
                    HOME
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    activeClassName="nav-active"
                    className="nav-link links"
                    onClick={() => handleCollapse()}
                    to="/about"
                  >
                    ABOUT US
                  </NavLink>
                </li>

                <div className="nav-item dropdown">
                  <a
                    className="nav-link links  dropdown-toggle"
                    href="#initiatives"
                    data-bs-toggle="dropdown"
                  >
                    {" "}
                    OUR INITIATIVES{" "}
                  </a>
                  <div className="dropdown-menu">
                    <br />
                    <li>
                      <NavLink
                        className="dropdown-item links"
                        to="/events"
                        onClick={() => handleCollapse()}
                      >
                        {" "}
                        Events
                      </NavLink>
                    </li>
                    <br />
                    <li>
                      <NavLink
                        className="dropdown-item links"
                        to="/huesletters"
                        onClick={() => handleCollapse()}
                      >
                        HuesLetters
                      </NavLink>
                    </li>
                    <br />
                    <li>
                      <NavLink
                        className="dropdown-item links"
                        to="/huesofyou"
                        onClick={() => handleCollapse()}
                      >
                        Hues Of You{" "}
                      </NavLink>
                    </li>
                    <br />
                    <li>
                      <NavLink
                        className="dropdown-item links"
                        to="/huesradio"
                        onClick={() => handleCollapse()}
                      >
                        {" "}
                        HuesRadio
                      </NavLink>
                    </li>
                    <br />
                    <li>
                      <NavLink
                        className="dropdown-item links"
                        to="/huesmerch"
                        onClick={() => handleCollapse()}
                      >
                        HuesMerch
                      </NavLink>
                    </li>
                    <br />
                  </div>
                </div>

                <div className="nav-item dropdown">
                  <a
                    className="nav-link links  dropdown-toggle"
                    href="#helpyourself"
                    data-bs-toggle="dropdown"
                  >
                    {" "}
                    HELP YOURSELF{" "}
                  </a>
                  <div className="dropdown-menu">
                    <br />
                    <li>
                      <NavLink
                        className="dropdown-item links"
                        to="/helpyourself"
                        onClick={() => handleCollapse()}
                      >
                        {" "}
                        Help Yourself
                      </NavLink>
                    </li>
                    <br />
                    <li>
                      <NavLink
                        className="dropdown-item links"
                        to="/helpyourself/cope"
                        onClick={() => handleCollapse()}
                      >
                        Cope With HOTM{" "}
                      </NavLink>
                    </li>
                    <br />
                    <li>
                      <NavLink
                        className="dropdown-item links"
                        to="/helpyourself/safespace"
                        onClick={() => handleCollapse()}
                      >
                        {" "}
                        HOTM Safespace
                      </NavLink>
                    </li>
                    <br />
                    <li>
                      <NavLink
                        className="dropdown-item links"
                        to="/helpyourself/learnwithhotm"
                        onClick={() => handleCollapse()}
                      >
                        Learn With HOTM
                      </NavLink>
                    </li>
                    <br />
                    <li>
                      <NavLink
                        className="dropdown-item links"
                        to="/helpyourself/hotmrepository"
                        onClick={() => handleCollapse()}
                      >
                        Connect with a Therapist
                      </NavLink>
                    </li>
                    <br />
                  </div>
                </div>
                <li className="nav-item">
                  <NavLink
                    activeClassName="nav-active"
                    className="nav-link links"
                    onClick={() => handleCollapse()}
                    to="/contact"
                  >
                    CONTACT US
                  </NavLink>
                </li>
                <li>
                  <a
                    href="https://www.amazon.in/dp/1639409459"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="buy_btn"
                    role="button"
                  >
                    Buy Hues of You
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <Helpline />
    </>
  );
};

export default Navbar;
